import React from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { MenuDasFuncionalidades } from "../Menu/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { CardCrud } from '../Conteudo/Cards/index';
import { convertToSlug } from '../Basicos/funcoes';

function Local({locais, }) {
    
    return (
        <Row noGutters={true}>
            <MenuDasFuncionalidades
                funcionalidade={"Locais"}
                classNavBar="navbar-consultas"
                titulo="Locais de Atividades"
            />
            <Col sm={12} md={12} className="col-conteudo">
                <Switch>
                    {locais.map((subFuncionalidade, indice) => {
                        switch (convertToSlug(subFuncionalidade.descricao)) {
                            case "auxiliares":
                                return null;
                            default:
                                return (
                                    <Route
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => 
                                            <CardCrud 
                                                {...props}
                                                subFuncionalidade={subFuncionalidade}
                                                indiceSubFuncionalidade={indice}
                                                funcionalidade={"locais-de-atividades"} 
                                            />
                                        }
                                    />
                                );
                        }
                    })}
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    return {
        locais: state.subFuncionalidades["locais-de-atividades"],
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Local);
