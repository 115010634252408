import React from 'react'
import { Button, Col, Modal } from 'reactstrap'
import { HeaderModal } from '../Basicos'

const ModalAtualizarAula = ({ isOpen, atualizarAula }) => {

    return (
        <Modal isOpen={isOpen} className="modal-atualizar-local">
            <HeaderModal
                icon={false}
                titulo={'Salvar'}
                sm={12}
                colClassName="modalConsulta__menu atualizar-aula"
            />
            <Col sm={12} style={{ fontSize: '0.9rem', padding: '8px', color: 'var(--cor-text-secundario)', textAlign: 'center' }}>
                Deseja atualizar as aulas de hoje?
            </Col>
            <Col sm={12} style={{ padding: '8px' }}>
                <Button outline color="primary" onClick={() => atualizarAula(false)} className={"float-left btnHeader btnHeader-danger m-0"}>
                    NÃO
                </Button>
                <Button outline color="danger" onClick={() => atualizarAula(true)} className={"float-right btnHeader m-0"}>
                    SIM
                </Button>
            </Col>
        </Modal>
    )
}

export default ModalAtualizarAula