import * as tiposActions from '../Actions/tiposActions'
import { setStateRedux } from '../setStateRedux'

const initialState = {
    loader: undefined, // Estado do loader, se está ativo ou não
    estruturaModal: undefined, // Estrutura do modal;
    estadoModal: undefined, // Se é para abrir o modal ou não
    tipoAcao: undefined, // Informa qual tipo de modal está ativo.. Editar ou Adicionar
    indiceSubModal: undefined, //Indice da subfuncionalidade do Modal que está ativo
    subfuncionalidadeRef: undefined, //ref da subfuncionalidade ativa no modal...
    paramsConsulta: undefined, //Parametros da consulta, usado por exemplo para filtro em modal de relatorio
    adicionar: undefined, // Referencia da Função de adicionar com os parametros correspondendo ao da subfuncionalidade do modal
    editar: undefined // Referencia da Função de editar com os parametros correspondendo ao da subfuncionalidade do modal
}

const atualizarSubFuncionalidade = (state, action) => {
    let aux = state[action.funcionalidade].slice();
    aux[action.indice] = Object.assign({}, aux[action.indice], action.dados);
    return setStateRedux(state, { [action.funcionalidade]: aux });
}

const atualizarModal = (state, action) => {
    return setStateRedux(state, { ...action.modal });
}

const toggleModal = (state, action) => {
    return setStateRedux(state, action.dados);
}

const toggleLoader = (state, action) => {
    return setStateRedux(state, action.dados);
}

const inicializarSubFuncionalidade = (state, action) => {
    return setStateRedux(state, action.dados);
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case tiposActions.ATUALIZAR_SUBFUNCIONALIDADE:
            return atualizarSubFuncionalidade(state, action)
        case tiposActions.TOGGLE_MODAL_SUBFUNCIONALIDADE:
            return toggleLoader(state, action)
        case tiposActions.TOGGLE_LOADER_SUBFUNCIONALIDADE:
            return toggleModal(state, action)
        case tiposActions.ATUALIZAR_MODAL_SUBFUNCIONALIDADE:
            return atualizarModal(state, action)
        case tiposActions.INCIALIZAR_SUBFUNCIONALIDADE:
            return inicializarSubFuncionalidade(state, action)
        default:
            return state;
    }
}

export default reducer