import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button, Form, Alert, Row } from "reactstrap";
import { TabelaWithFilter, Loader, InputForm, SelectForm, Tabela } from "../../Basicos/index";
import CardHoc from "../../Hoc/cardHoc";
import MenuTable from "../../Menu/menuTable";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import { axiosApi } from '../../Basicos/axiosInstances';
import ListaDragDropT from '../../Basicos/Listas/listaDragDropT';
import ItemGenerico from '../../Basicos/Itens/itemGenerico';

function CardCrud({
    atualizarDadosRemotamente,
    ativar,
    subFuncionalidade: {
        url,
        cursos,
        tipoatividade,
        loader,
        descricao,
        periodosLetivos,
        campos,
        acoes
    },
}) {

    const [dadosForm, setDadosForm] = useState(false);

    const [temasOptions, setTemasOptions] = useState();
    const [semestreOptions, setSemestreOptions] = useState();
    const [cursoOptions, setCursoOptions] = useState();
    const [tipoAtividadeOptions, setTipoAtividadeOptions] = useState();
    const [periodoLetivoOptions, setPeriodoLetivoOptions] = useState();
    const [turmasOptions, setTurmasOptions] = useState();
    const [temaID, setTemaID] = useState();

    const [valorSemestre, setValorSemestre] = useState();
    const [valorCurso, setValorCurso] = useState();
    const [valorTema, setValorTema] = useState();
    const [valorTipoAtividade, setValorTipoAtividade] = useState();
    const [valorTurma, setValorTurma] = useState();
    const [valorPeriodoLetivo, setValorPeriodoLetivo] = useState();

    const [disponiveis, setDisponiveis] = useState([]);

    const loaderStatus = !cursos || loader;

    const loadTemasOptions = async (semestre) => {
        const response = await axiosApi.get(semestre ? `temas?${semestre}&periodo_letivo=${valorPeriodoLetivo.value}` : 'temas');
        setTemasOptions(response.data.dados.map(element => ({ label: element.nome, value: element.id })))
    }

    const loadTurmasOptions = async (semestre) => {
        const response = await axiosApi.get(`turmas-semestre?semestre_id=${semestre.value}&periodo_letivo_id=${valorPeriodoLetivo.value}`);
        setTurmasOptions(response.data.dados)
    }

    const loadSemestreOptions = async (curso) => {
        const response = await axiosApi.get(curso ? `aulas/semestre-options?${curso}` : 'aulas/semestre-options');
        setSemestreOptions(response.data.dados.map(element => ({ label: element.descricao, value: element.id })))
    }

    const gerarSelectOptions = () => {
        setCursoOptions(cursos && cursos.map(element => ({ label: element.nome, value: element.id })));
        setTipoAtividadeOptions(tipoatividade && tipoatividade.map(element => ({ label: element.nome, value: element.id })));
        setPeriodoLetivoOptions(periodosLetivos && periodosLetivos.map(element => ({ label: element.descricao, value: element.id })));
    }
    useEffect(() => {
        gerarSelectOptions()
    }, [cursos, tipoatividade]);

    useEffect(() => (
        atualizarDadosRemotamente()
    ), []);

    const getDadosFiltrados = async () => {
        setDisponiveis([]);
        const response = await axiosApi.get(`${url}?turma_id=${valorTurma.value}&tema_id=${valorTema.value}&semestre_id=${valorSemestre.value}&periodo_letivo_id=${valorPeriodoLetivo.value}&tipo_de_atividade_id=${valorTipoAtividade.value}`)
        setDisponiveis(response.data.dados)
    }

    const onChangeSelect = (nome, valor) => {
        switch (nome) {
            case 'curso_id':
                let curso = cursoOptions.find((element) => element.value === valor);
                setValorCurso(curso)
                loadSemestreOptions(nome + '=' + valor)
                break;
            case 'periodo_letivo_id':
                let periodoLetivo = periodoLetivoOptions.find((element) => element.value == valor);
                setValorPeriodoLetivo(periodoLetivo);
                setValorSemestre({ value: 'selecione', label: 'Selecione' })
                break;
            case 'semestre_id':
                let semestre = semestreOptions.find((element) => element.value === valor);
                setValorSemestre(semestre)
                loadTemasOptions(nome + '=' + valor)
                loadTurmasOptions(semestre);
                setValorTema({ value: 'selecione', label: 'Selecione' })
                break;
            case 'tema_id':
                let tema = temasOptions.find((element) => element.value === valor);
                setValorTema(tema)
                setTemaID(tema.value);
                setValorTipoAtividade({ value: 'selecione', label: 'Selecione' });
                atualizarDadosRemotamente({ [nome]: valor })
                setValorTurma({ value: 'selecione', label: 'Selecione' });
                break;
            case 'tipo_de_atividade_id':
                let tipo = tipoAtividadeOptions.find((element) => element.value === valor);
                setValorTipoAtividade(tipo);
                break;
            case 'turma_id':
                let turma = turmasOptions.find((element) => element.value == valor);
                setValorTurma(turma);
            // getDadosFiltrados(temaID ? { [nome]: valor, tema_id: temaID, semestre_id: valorSemestre.value, periodo_letivo_id: valorPeriodoLetivo.value } : { [nome]: valor })
            default:
                break;
        }
    }

    const onChangeLista = (dados) => {
        setDisponiveis(dados.disponiveis.map((disponivel, index) => ({ ...disponivel, sequencia_aula: index + 1 })))
        setDadosForm(true)
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (dadosForm) {
            try {
                if (dadosForm) {
                    const response = await axiosApi.put(`${url}/777?semestre_id=${valorSemestre.value}&periodo_letivo_id=${valorPeriodoLetivo.value}&turma_id=${valorTurma.value}`, disponiveis);
                    ativar(201, 'Sequências modificadas com sucesso!')
                } else {
                    ativar(400, 'Modifique a sequência antes de salvar.')
                }
                setDadosForm(false)
            } catch (error) {
                ativar(400, 'Algo de errado aconteceu.')
                console.log(error)
            }
        } else {
            ativar(400, 'Modifique a sequência antes de salvar.')
        }
    }

    return (
        <Fragment>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao.toUpperCase()} </strong></h3>
            </Col>
            {!loaderStatus &&
                <Col sm={12} md={12}>
                    <Row className="px-3">
                        <SelectForm
                            label="Curso"
                            name="curso_id"
                            placeholder="Curso"
                            options={cursoOptions}
                            onChange={onChangeSelect}
                            value={valorCurso ?? ''}
                            style={
                                {
                                    col: {
                                        xs: 2,
                                        md: 2,
                                        sm: 2,
                                        lg: 2,
                                        xl: 2
                                    }

                                }}
                        />
                        <SelectForm
                            label="Período Letivo"
                            name="periodo_letivo_id"
                            placeholder="Período Letivo"
                            options={periodoLetivoOptions}
                            onChange={onChangeSelect}
                            value={valorPeriodoLetivo ?? ''}
                            style={
                                {
                                    col: {
                                        xs: 2,
                                        md: 2,
                                        sm: 2,
                                        lg: 2,
                                        xl: 2
                                    }

                                }}
                        />
                        <SelectForm
                            label="Semestre"
                            name="semestre_id"
                            placeholder="Semestre"
                            options={semestreOptions}
                            onChange={onChangeSelect}
                            readOnly={!valorSemestre}
                            value={valorSemestre ?? ''}
                            style={
                                {
                                    col: {
                                        xs: 1,
                                        md: 1,
                                        sm: 1,
                                        lg: 1,
                                        xl: 1
                                    }

                                }}
                        />
                        <SelectForm
                            label="Tema"
                            name="tema_id"
                            placeholder="Tema"
                            options={temasOptions}
                            onChange={onChangeSelect}
                            readOnly={!valorTema}
                            value={valorTema ?? ''}
                            style={
                                {
                                    col: {
                                        xs: 2,
                                        md: 2,
                                        sm: 2,
                                        lg: 2,
                                        xl: 2
                                    }

                                }}
                        />
                        <SelectForm
                            label="Tipo de Atividade"
                            name="tipo_de_atividade_id"
                            placeholder="Atividade"
                            options={tipoAtividadeOptions}
                            onChange={onChangeSelect}
                            readOnly={!valorTipoAtividade}
                            value={valorTipoAtividade ?? ''}
                            style={
                                {
                                    col: {
                                        xs: 2,
                                        md: 2,
                                        sm: 2,
                                        lg: 2,
                                        xl: 2
                                    }

                                }}
                        />
                        <SelectForm
                            label="Turma"
                            name="turma_id"
                            placeholder="Turma"
                            options={turmasOptions}
                            onChange={onChangeSelect}
                            readOnly={!valorTurma}
                            value={valorTurma ?? ''}
                            style={
                                {
                                    col: {
                                        xs: 1,
                                        md: 1,
                                        sm: 1,
                                        lg: 1,
                                        xl: 1
                                    }

                                }}
                        />
                        <Col className="btn-limpar-filtro">
                            <Button className="mx-3 py-2"
                                onClick={() => (
                                    setValorCurso(),
                                    setValorSemestre(),
                                    setValorTema(),
                                    setValorTipoAtividade(),
                                    setValorTurma(),
                                    setTemaID(),
                                    setValorPeriodoLetivo(),
                                    setDisponiveis([]),
                                    setDadosForm(false)
                                )}>Limpar</Button>
                        </Col>
                        <Col className="btn-limpar-filtro">

                            <Button
                                className="mx-3 py-2"
                                onClick={getDadosFiltrados}
                            >Pesquisar</Button>
                        </Col>
                        {/* <Col className="btn-limpar-filtro">

                            <Button
                                className="mx-3 py-2"
                                onClick={onSubmit}
                            >Salvar</Button>
                        </Col> */}
                    </Row>
                    <ListaDragDropT
                        noGutters
                        campoID={"id"}
                        sequenciaAula={true}
                        itemComponent={<ItemGenerico />}
                        disponiveis={disponiveis.length > 0 ? disponiveis : []}
                        onChangeLista={onChangeLista}
                        divListClassName={"listas-drag-drop-rota"}
                        // Props Itens
                        itensProps={{
                            wrapClassName: "border-bottom-lista-lg texto-secundario",
                            campos: [
                                {
                                    label: "Aula",
                                    name: "titulo",
                                    col: {
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 10,
                                        xl: 10,
                                        className: "campo-generico"
                                    }
                                },
                                {
                                    label: "Sequencia",
                                    name: "sequencia_aula",
                                    col: {
                                        xs: 12,
                                        sm: 12,
                                        md: 6,
                                        lg: 2,
                                        xl: 2,
                                        className: "campo-generico"
                                    }
                                }]
                        }}

                    />
                    <Col className="btn-limpar-filtro">

                        <Button
                            className="mx-3 py-2"
                            onClick={onSubmit}
                        >Salvar</Button>
                    </Col>
                </Col>
            }
            {loaderStatus && <Loader /> /* USAR como child*/}

        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        atualizarDadosRemotamente: (params) => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, params)),
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHoc(CardCrud))
