import React from 'react'
import { ModalConsulta } from '.';
import {Form} from "reactstrap";
import { Tabela } from '../Basicos';

function ModalAlunoGrupo(props) {
    const {estrutura} = props;
    const {alunos} = estrutura?.modal;
    
    const columns = React.useMemo(
        () => [{
            Header: "Grupo de Alunos",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Matrícula',
                    accessor: 'matricula',
                },
                {
                    Header: 'Nome',
                    accessor: 'nome',
                },
              
                {
                    Header: 'Grupo 1',
                    accessor: 'grupo_1',
                },
                {
                    Header: 'Grupo 2',
                    accessor: 'grupo_2',
                }
            ],
        }],
        []
      )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map2"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <Form>
            <Tabela
                columns={columns} 
                data={alunos} 
                tabelaClassName="tabelaConsulta"
            />
            </Form>
        </ModalConsulta>
    );
}

export default ModalAlunoGrupo;
