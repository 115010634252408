import React from 'react'
import { Col, Row, Button } from 'reactstrap';
import { HeaderModal } from "../Basicos/index"
import ModalHoc from "../Hoc/modalHoc"
import { connect } from 'react-redux'
import * as actionsRedux from '../Store/Actions/index'

const Modal = ({ toggle, mensagem, titulo, callback }) => {
    const onClickSim = () => {
        callback();
        toggle();
    }

    return ( 
        <Row noGutters={true}>
            <HeaderModal sm={12} toggle={toggle} titulo={titulo.toUpperCase()}/>
            <Col sm={12} style={{fontSize: '0.9rem', padding: '8px', color:'var(--cor-text-secundario)', textAlign: 'center'}}>
                {mensagem}
            </Col>
            <Col sm={12} style={{padding: '8px'}}>
                <Button outline color="primary" onClick={toggle} className={"float-left btnHeader btnHeader-danger m-0"}>
                    NÃO
                </Button>
                <Button outline color="danger" onClick={onClickSim} className={"float-right btnHeader m-0"}>
                    SIM
                </Button>
            </Col>
        </Row>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        toggle: () => dispatch(actionsRedux.desativarModalConfirm())
    }
}

const mapStateToProps = (state) => {
    return {
        callback: state.alert.callback,
        titulo: state.alert.titulo,
        mensagem: state.alert.mensagem,
        boolModal: state.alert.boolModalConfirm
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalHoc(Modal));