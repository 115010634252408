import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col } from "reactstrap";
import CardHoc from "../../Hoc/cardHoc";
import { Tabela } from '../../Basicos';
import { axiosApi } from '../../Basicos/axiosInstances';
import MenuTable from '../../Menu/menuTable';
import ModalGruposAlunoServico from '../../Basicos/Modal/GruposAlunoServico/ModalGruposAlunoServico';
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';

function montarColunas({ editar, deletar }, removerOnClick, editarOnClick, openModal, confirmaChamaDeleta) {
    let campos2 = [{ label: 'Semestre', name: 'semestre.descricao' }, { label: 'Turma', name: 'turma.descricao' }, { label: 'Qtd Alunos', name: 'qtd_alunos' }]
    const colunas = campos2.map((elemento) => {
        switch (elemento.name) {
            case "cor":
                return {
                    Header: elemento.label,
                    Cell: ({ row }) => (
                        <div style={{ background: row.original.cor, width: -1, height: 32, borderRadius: 100 }} />)
                };

            default:
                return {
                    Header: elemento.label,
                    accessor: elemento.name,
                }
        }
    });

    if (editar || deletar) {
        colunas.push({
            Cell: ({ row }) => (
                <MenuTable
                    iconEdit={editar}
                    iconRemover={deletar}
                    removerOnClick={() => confirmaChamaDeleta(row.original.id)}
                    editarOnClick={() => openModal(row.original.id)}
                />
            ),
            resizable: false,
            accessor: "acoes",
            style: { width: 64 }
        });
    }
    return colunas;
}

function CardGruposAluno({
    ativarAlert,
    subFuncionalidade: {
        descricao,
        campos,
        acoes
    },
    ativarCofirmacao,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [gruposAlunoServico, setGruposAlunoServico] = useState([]);
    const [grupoAlunoId, setGrupoAlunoId] = useState();
    const [dadosForm, setDadosForm] = useState({
        descricao: '',
        data: ''
    });
    const [modal, setModal] = useState(false);

    useEffect(() => {
        getGruposAlunoServico();
    }, []);

    const getGruposAlunoServico = async () => {
        setIsLoading(true)
        try {
            const { data } = await axiosApi.get('/auxiliares/grupos-aluno')
            setGruposAlunoServico(data.dados)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            if (error.response.status === 403) {
                ativarAlert(400, error?.response?.data?.mensagem);
            }

        }
    }

    const removerOnClick = async (id) => {
        setIsLoading(true)
        try {
            await axiosApi.delete(`/auxiliares/feriado/${id}`);
            setGruposAlunoServico(prev => prev.filter(el => el.id !== id));
            ativarAlert('remover', 'sucesso')
        } catch (error) {
            ativarAlert('remover', 'error')
            console.log(error)
        }
        setIsLoading(false)
    }

    const alterarOnClick = (id) => {
        const elemento = gruposAlunoServico.find((element) => element.id === id);
        setDadosForm(elemento);
    }

    const openModal = (id = null) => {
        setGrupoAlunoId(id)
        setModal(true);
    }

    const closeModal = () => {
        setGrupoAlunoId(null)
        setModal(false);
    }

    const chamaDeleta = async (id) => {
        setIsLoading(true)
        try {
            await axiosApi.delete(`/auxiliares/grupos-aluno/${id}`);
            setGruposAlunoServico(prev => prev.filter(el => el.id !== id));
            ativarAlert('remover', 'sucesso')
        } catch (error) {
            ativarAlert('remover', 'error')
            console.log(error)
        }
        setIsLoading(false)
    }

    const confirmaChamaDeleta = (id) =>
        ativarCofirmacao({
            titulo: 'Deletar',
            mensagem: 'Deseja deletar?',
            callback: () => chamaDeleta(id)
        })

    const colunas = React.useMemo(() => montarColunas(acoes, chamaDeleta, alterarOnClick, openModal, confirmaChamaDeleta), [gruposAlunoServico])
    return (
        <Fragment>
            <Col xs={12} sm={12} md={5} lg={6} xl={7} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao?.toUpperCase()} </strong></h3>
            </Col>
            <Col xs={12} sm={12} md={7} lg={6} xl={5} className="header-acoes mb-3">
                <div id="pesquisar-wrap"></div>
                <Button className="mx-3" onClick={() => openModal()}>Novo</Button>
            </Col>
            <Tabela
                pesquisarGlobal
                columns={colunas}
                data={gruposAlunoServico}
            />
            <ModalGruposAlunoServico
                isOpen={modal}
                setIsOpen={closeModal}
                setGruposAlunoServico={setGruposAlunoServico}
                grupoAlunoId={grupoAlunoId}
            />
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        atualizarDadosRemotamente: () => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
    }
}

export default connect(null, mapDispatchToProps)(CardHoc(CardGruposAluno));
