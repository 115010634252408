import React, { useState, useEffect, Fragment } from 'react'
import { Col, Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import { SelectForm } from '../Basicos';

const ModalPeriodoCurso = ({ isOpen, setIsOpen, periodosLetivos, cursos, setPeriodoLetivoId, periodoLetivoId, setCursoId, cursoId, submit = undefined }) => {

    const [form, setForm] = useState({ periodoLetivoId: periodoLetivoId, cursoId: cursoId });

    const periodosLetivosOptions = () => periodosLetivos.sort(function (a, b) {
        return b.id - a.id;
    }).map(periodoLetivo => ({ value: periodoLetivo.id, label: periodoLetivo.descricao }));
    const periodosLetivosValue = () => periodosLetivosOptions().find(elemento => elemento.value === form.periodoLetivoId);

    const cursosOptions = () => cursos.map(curso => ({ value: curso.id, label: curso.nome }));
    const cursosValue = () => cursosOptions().find(elemento => elemento.value === form.cursoId);

    const handleSubmit = (e) => {
        setPeriodoLetivoId(form.periodoLetivoId)
        setCursoId(form.cursoId)
        if (submit) {
            submit();
        }
        setIsOpen(false);
    }

    useEffect(() => {
        if (!cursoId || !periodoLetivoId)
            setIsOpen(true)
    }, [cursoId, periodoLetivoId])

    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <FormGroup row>
                    <Col sm={12}>
                        <SelectForm
                            name="periodoLetivoSelect"
                            label="Período Letivo"
                            value={periodosLetivosValue()}
                            options={periodosLetivosOptions()}
                            onChange={(input, value) => setForm(prev => ({ ...prev, periodoLetivoId: value }))}
                        />
                        <SelectForm
                            name="cursoSelect"
                            label="Curso"
                            value={cursosValue()}
                            options={cursosOptions()}
                            onChange={(input, value) => setForm(prev => ({ ...prev, cursoId: value }))}
                        />
                    </Col>
                </FormGroup>
                <Button onClick={() => setIsOpen(false)} className="float-left btn-transparente">Cancelar</Button>
                <Button onClick={handleSubmit} color="primary" className="float-right"> Procurar </Button>
            </ModalBody>
        </Modal>
    )
}

export default ModalPeriodoCurso