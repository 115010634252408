import React from 'react';
import { ButtonGroup } from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { primeiraImagemDeUmaNoticia } from '../../services/noticias';

const CarouselNews = (props) => {
    const { items, withButtonEdit = false, withButtonDelete = false, editarOnClick, ativarCofirmacao, removerOnClick } = props;

    const { innerWidth: width, innerHeight: height } = window;

    const NoticiaBloco = (props) => {
        const { noticia, withButtonEdit = false, withButtonDelete = false } = props;

        const ellipse = (noticia?.titulo)?.length > 30 ? "..." : "";
        return (
            <div style={{
                border: '1px',
                backgroundColor: 'white',
                padding: '1rem',
                boxShadow: '0px 0px 4px 0px #00000040',
                borderRadius: '16px',
                width: '200px',
                position: 'relative',
                height: '240px'
            }} >
                <Link to={`/eventos-e-noticias/${noticia.id}`} key={noticia.id}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <img
                        src={primeiraImagemDeUmaNoticia(noticia)}
                        className="shadow-sm"
                        style={{ width: '160px', height: '160px', borderRadius: '16px' }}
                        alt=""
                    />
                    <span
                        className="font-weight-bold azul-principal"
                        style={{
                            fontSize: "14px",
                            paddingTop: '0.5rem',
                        }}
                    >
                        {noticia?.titulo ? (noticia?.titulo).substring(0, 30) + ellipse : ''}
                    </span>
                </div>
                </Link>
                {withButtonDelete || withButtonEdit ? <div style={{ position: 'absolute', right: '0px', top: '0px', backgroundColor: '#19385d', color: "white", padding: '5px', fontSize: '1rem', borderTopRightRadius: '16px', borderBottomLeftRadius: '16px' }}>
                    <ButtonGroup
                        style={{
                            gap: '0.5rem',
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            padding: '0.2rem'
                        }}
                    >
                        {withButtonEdit ? <FiEdit onClick={() => editarOnClick(noticia?.id)} style={{ cursor: "pointer", marginLeft: '5px' }} /> : ''}
                        {withButtonDelete ? <FiTrash2 onClick={() => ativarCofirmacao({ titulo: 'deletar', mensagem: 'Deseja remover este item ?', callback: () => removerOnClick(noticia?.id) })} style={{ cursor: "pointer", marginRight: '5px' }} /> : ''}
                    </ButtonGroup>
                </div> : ''}
            </div>

        )
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1920 },
            items: 7,
            slidesToSlide: 1 // optional, default to 1.
        },
        notebook: {
            breakpoint: { max: 1919, min: 1440 },
            items: 6,
            slidesToSlide: 1 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1439, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1023, min: 768 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        items && (<div className={width >= 1920 ? 'careousel-div' : width <= 1280 ? 'careousel-small-div' : 'careousel-middle-div'} style={{ flexWrap: 'wrap' }}>
            <Carousel
                responsive={responsive}
                autoPlay={true}
                // swipeable={false}
                draggable={true}
                // showDots={false}
                infinite={true}
                arrows={false}
            // partialVisible={false}
            // dotListClass="custom-dot-list-style"
            >
                {items.map((item, index) => {
                    return (
                        <div className='slider' key={index}>
                            <NoticiaBloco
                                noticia={item}
                                withButtonEdit={withButtonEdit}
                                withButtonDelete={withButtonDelete}
                            />
                        </div>
                    );
                })}
            </Carousel>
        </div>)
    );
}

export default CarouselNews;