import React from 'react'
import { NavItem, Nav } from 'reactstrap';
import { NavLink } from "react-router-dom";
const ParteInferior = (props) => {
    return (
        <Nav >
            <NavItem>
                <NavLink exact to={props.match.path} >EMPRESAS</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={props.match.path + "/criar"}>CRIAR EMPRESAS</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={props.match.path + "/funcionalidades"}>FUNCIONALIDADES</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={props.match.path + "/subfuncionalidades"}>SUBFUNCIONALIDADES</NavLink>
            </NavItem>
        </Nav>
    )
}
export default ParteInferior