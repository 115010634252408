import React, { Fragment, useState } from 'react';
import { Row, Col, Button } from "reactstrap";
import CardHoc from "../../Hoc/cardHoc";
import { error as JoinMsgError, pt_br, transformarArrayEmObjeto } from '../../Basicos/funcoes';
import PopOverCriticas from '../../Basicos/PopOverCriticas';
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import { mesesDaSemanaFormatado, diaDaSemanaDaDataString } from '../../../services/calendarioOferta';
import { corDoTexto } from '../../../services/coresNoBlocoPadrao';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ModalTrocarAula from '../../Basicos/Modal/ModalTrocarAula';
import CardAulasDnd from './CardAulasDND';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { axiosApi } from '../../Basicos/axiosInstances';
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import PopOverObs from '../../Basicos/PopOverObs';
import { GrDocumentPdf } from "react-icons/gr";
import PopOverLogAula from '../../Basicos/PopOverLogAula';

function pegarDiaDaSemana(horario, diaDaSemana, estruturaDoGrid) {
    return estruturaDoGrid[horario.id] && estruturaDoGrid[horario.id][diaDaSemana]
        ? estruturaDoGrid[horario.id][diaDaSemana]
        : [];
}

function blocoSelecionado(horario, diaDaSemana, { horario_do_curso_id, dia_da_semana }) {
    return horario.id == horario_do_curso_id && dia_da_semana == diaDaSemana;
}

function CardCalendarioPadrao({
    //0-domingo, 6-sábado.
    inicioDaSemana = 1,
    finalDaSemana = 5,
    colClassname = "calendario-padrao",
    horariosCurso,
    horariosDefinidos = [],
    tipoAtividades,
    horariosAulas,
    selecionarHorario = () => { },
    datasInstitucionais = [],
    horarioSelecionado = {},
    deletarHorario = () => { },
    temMenu = true,
    abrirModalAssociar,
    turma,
    abrirModalVisualizar = () => { },
    deletarHorarioModal = () => { },
    aulas = [],
    éTipoOferta = false,
    éConsulta = false,
    tipoOferta: {
        mudarSemana = () => { },
        mudarMes = () => { },
        semana,
    } = false,
    horariosOfertas,
    setHorariosOfertas,
    grupos,
    ativar,
    url,
    exportSemanaPadraoPDF,
    periodoLetivo,
    temas,
    criticaDnD,
    semestreId,
    setDadosCritica,
    setModalCritica,
    dadosCritica
}) {
    const [modalTrocarAula, setModalTrocarAula] = useState({
        isOpen: false,
        HorarioAulas: []
    });
    const estruturaDoGrid = {};
    const [dados, setDados] = useState();

    //Questao de perfomance é transformado em obj, para ser mais facil acessar;
    const tipoAtividadesObj = transformarArrayEmObjeto(tipoAtividades);

    //Tem que ser feito aqui a estrutura. Se tiver alguma mudança atraves dos blocos possivelmente vire state.
    horariosDefinidos.forEach((elemento, indice) => {
        const estruturaHorarioRow = estruturaDoGrid[elemento.horario_do_curso_id] ?? {};
        estruturaHorarioRow[elemento.dia_da_semana] = estruturaHorarioRow[elemento.dia_da_semana] ?? [];
        estruturaHorarioRow[elemento.dia_da_semana].push({ ...elemento, indiceArray: indice });
        estruturaDoGrid[elemento.horario_do_curso_id] = estruturaHorarioRow;
    });

    const estruturaGridDatasEspeciais = {};
    datasInstitucionais
        .filter(dataInstitucional => dataInstitucional.tipo_de_data_id === 3 || dataInstitucional.tipo_de_data_id === 5 || dataInstitucional.tipo_de_data_id === 6) //Apenas feriados
        .forEach(dataInstitucional => {
            const diaDaSemana = diaDaSemanaDaDataString(dataInstitucional.data);

            horariosCurso.forEach(horarioCurso => {
                const estruturaHorarioRow =
                    estruturaGridDatasEspeciais[horarioCurso.id] ? estruturaGridDatasEspeciais[horarioCurso.id] : {};
                estruturaHorarioRow[diaDaSemana] =
                    estruturaHorarioRow[diaDaSemana] ? estruturaHorarioRow[diaDaSemana] : [];
                estruturaHorarioRow[diaDaSemana].push(dataInstitucional);

                estruturaGridDatasEspeciais[horarioCurso.id] = estruturaHorarioRow;
            });
        });

    const estruturaGridDatasProvas = {};

    function LegendasDiaDaSemanaComDomingoESabadoCol() {
        const dias = [];

        if (semana.length === 0)
            return dias;

        //Domingo com botão
        dias.push(
            <DiaDaSemanaLabel
                className={"calendario-legenda-dias--bordaInferior"}
                key={pt_br.weekdaysLong[0]}
            >
                <BotãoRetroceder />
                {`${pt_br.weekdaysLong[0]}/${semana[0].getDate()}`}
            </DiaDaSemanaLabel>
        );

        //Segunda - Sexta
        for (let indice = 1; indice <= 5; indice++) {
            const diaDaSemana = pt_br.weekdaysLong[indice];
            dias.push(
                <DiaDaSemanaLabel
                    className={"calendario-legenda-dias--bordaInferior"}
                    key={diaDaSemana}
                >
                    {`${diaDaSemana}/${semana[indice].getDate()}`}
                </DiaDaSemanaLabel>
            );
        }

        //Sábado com botão
        dias.push(
            <DiaDaSemanaLabel
                key={pt_br.weekdaysLong[6]}
                className={"calendario-legenda-dias--bordaInferior"}
            >
                {`${pt_br.weekdaysLong[6]}/${semana[6].getDate()}`}
                <BotãoAvancar />
            </DiaDaSemanaLabel>
        );

        return dias;
    };

    function BotãoRetroceder() {
        if (éConsulta)
            return null;

        return (
            <span
                className="mr-2 calendario-legenda-dias--flecha cursor-ponteiro"
                onClick={() => mudarSemana("retroceder")}
            >
                {"<"}
            </span>
        );
    }

    function BotãoAvancar() {
        if (éConsulta)
            return null;

        return (
            <span
                className="ml-2 calendario-legenda-dias--flecha cursor-ponteiro"
                onClick={() => mudarSemana("avancar")}
            >
                {">"}
            </span>
        );
    }

    function LegendasDiaDaSemanaCol() {
        const dias = [];

        for (let indice = inicioDaSemana; indice <= finalDaSemana; indice++) {
            const diaDaSemana = pt_br.weekdaysLong[indice];
            dias.push(
                <DiaDaSemanaLabel key={diaDaSemana}>
                    {diaDaSemana}
                </DiaDaSemanaLabel>
            );
        }

        return dias;
    };

    function DiasDaSemana() {
        if (éTipoOferta)
            return (
                <Fragment>
                    <div />
                    <LegendasDiaDaSemanaComDomingoESabadoCol />
                </Fragment>
            );

        return (
            <Fragment>
                <div className="calendario-padrao-borda-inicial" />
                <LegendasDiaDaSemanaCol />
            </Fragment>
        );
    }

    function MesesDaSemanaCol() {
        if (!éTipoOferta)
            return null;

        if (éConsulta)
            return (
                <div className="calendario-padrao__mesesDaSemana">
                    <h1 className="d-inline">{mesesDaSemanaFormatado(semana)}</h1>
                </div>
            );

        return (
            <div className="calendario-padrao__mesesDaSemana">
                <div className='informacoes-semestre-semana-padrao'>
                    <span className='infos-periodo-tema'>{periodoLetivo?.descricao} - S{temas?.semestre?.descricao.split("")[0]} {turma != null ? ` - ${turma?.descricao} -` : '-'} {temas.nome}</span>
                </div>
                <span className="mr-3 cursor-ponteiro" onClick={() => mudarMes("retroceder")} style={{ fontSize: "2.5rem" }}>{"<"}</span>
                <h1 className="d-inline">{mesesDaSemanaFormatado(semana)}</h1>
                <span className="ml-3 cursor-ponteiro" onClick={() => mudarMes("avancar")} style={{ fontSize: "2.5rem" }}>{">"}</span>
                <div className={`${Object.keys(estruturaDoGrid).length == 0 ? 'icon-pdf-semana-padrao-bloqueado' : 'icon-pdf-semana-padrao'}`}>
                    <GrDocumentPdf onClick={() => Object.keys(estruturaDoGrid).length == 0 ? '' : exportSemanaPadraoPDF()} color='red' size="2rem" />
                </div>
            </div>
        );
    }
    const horariosRow = (horario) => {
        const dias = [];
        for (let diaDaSemana = inicioDaSemana; diaDaSemana <= finalDaSemana; diaDaSemana++) {
            dias.push(
                <BlocoPadrao
                    key={diaDaSemana}
                    grupos={grupos}
                    horario={horario}
                    diaDaSemana={diaDaSemana}
                    estruturaDoGrid={estruturaDoGrid}
                    estruturaGridDatasEspeciais={estruturaGridDatasEspeciais}
                    estruturaGridDatasProvas={estruturaGridDatasProvas}
                    selecionarHorario={selecionarHorario}
                    tipoAtividadesObj={tipoAtividadesObj}
                    horarioSelecionado={horarioSelecionado}
                    deletarHorario={deletarHorario}
                    éTipoOferta={éTipoOferta}
                    horarioAulas={horariosAulas}
                    abrirModalAssociar={abrirModalAssociar}
                    abrirModalVisualizar={abrirModalVisualizar}
                    deletarHorarioModal={deletarHorarioModal}
                    aulas={aulas}
                    temMenu={temMenu}
                    éConsulta={éConsulta}
                    modalTrocarAula={modalTrocarAula}
                    setModalTrocarAula={setModalTrocarAula}
                />
            );
        };

        return dias;
    }

    const onDragEnd = (el) => {
        const { draggableId, destination } = el
        const aulaId = draggableId.replace('aula', '')
        const horarioId = destination?.droppableId.replace('bloco', '')

        const horario = horariosDefinidos.find(horario => horario.id == horarioId)
        const aula = aulas.find(aula => aula?.id == aulaId)

        const professoresIds = aula?.professores.map(prof => prof.id)

        if (!!aula && !!horario) {
            if (horario.tipo_atividade_id != aula?.tipo_de_atividade_id) {
                ativar(400, 'Tipo de atividade devem ser correspondentes');
            } else if (!(horario.data <= horario.data_fim) || !(horario.data >= horario.data_inicio)) {
                ativar(400, 'Data deve estar dentro do período do tema');
            } else {
                criticaDnD(horario, aula, professoresIds);
                // submitAulaDnD(horario, aula, professoresIds)
            }
        }
    }

    return (
        <Fragment>
            <MesesDaSemanaCol />
            <ModalTrocarAula
                modalTrocarAula={modalTrocarAula}
                setModalTrocarAula={setModalTrocarAula}
                HorarioAulas={modalTrocarAula?.HorarioAulas}
                horariosOfertas={horariosOfertas}
                setHorariosOfertas={setHorariosOfertas}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                {
                    éTipoOferta &&
                    <CardAulasDnd aulas={aulas} tiposAtividade={tipoAtividades} horarioSelecionado={horarioSelecionado} horariosOfertas={horariosOfertas} />
                }
                <Col className={colClassname}>
                    <DiasDaSemana />
                    {/* aqui é onde exibi as aulas */}
                    {
                        horariosCurso.map((horario, indice) =>
                            <Fragment key={indice}>
                                <LegendaHorarioRow {...horario} />
                                {horariosRow(horario)}
                            </Fragment>
                        )
                    }
                </Col>
            </DragDropContext>
        </Fragment >
    );
}

function LegendaHorarioRow({ horario, fim, inicio }) {
    const [codHorario, turno] = horario.descricao.split(' ');
    const descricao = `${codHorario} (${inicio.slice(0, -3)} às ${fim.slice(0, -3)})`
    return (
        <div className="calendario-horario-aula" style={{ display: "flex" }}>
            <div style={{ background: "var(--cor-text-secundario)", color: "white", flexGrow: "1", minWidth: "50%" }}>
                <span className="calendario-padrao-legenda-horario calendario-padrao-legenda">{turno.toUpperCase()}</span>
            </div>
            <div style={{ background: "var(--cor-horario-calendario)", color: "black", flexGrow: "1", minWidth: "50%" }}>
                <span className="calendario-padrao-legenda-horario calendario-padrao-legenda">{descricao}</span>
            </div>
        </div>
    );
};

function DiaDaSemanaLabel({ children, className }) {
    return (
        <div
            className={`
                align-content-middle calendario-padrao-legenda texto-secundario 
                ${className ? className : "calendario-legenda-dias"}
            `}
        >
            {children}
        </div>
    );
}

function BlocoPadrao({
    grupos,
    horario,
    éTipoOferta,
    diaDaSemana,
    estruturaDoGrid,
    estruturaGridDatasEspeciais,
    estruturaGridDatasProvas,
    selecionarHorario,
    tipoAtividadesObj,
    horarioSelecionado,
    deletarHorario,
    horarioAulas,
    abrirModalAssociar,
    abrirModalVisualizar,
    deletarHorarioModal,
    aulas,
    temMenu,
    éConsulta,
    modalTrocarAula,
    setModalTrocarAula
}) {
    const diaDaSemanaEstrutura = pegarDiaDaSemana(horario, diaDaSemana, estruturaDoGrid);
    const classSelecionado = blocoSelecionado(horario, diaDaSemana, horarioSelecionado)
        ? "calendario-padrao-selecionado"
        : "";
    let atividadeExisteNobloco = false;

    const tipoDeAtividadesDoDia = diaDaSemanaEstrutura.map((elemento, indice) => {
        const tipoAtividade = tipoAtividadesObj[elemento.tipo_atividade_id];

        const classSelecionadoAtividade = horarioSelecionado.tipo_atividade_id === elemento.tipo_atividade_id
            ? classSelecionado
            : "";

        atividadeExisteNobloco = atividadeExisteNobloco
            ? atividadeExisteNobloco
            : horarioSelecionado?.tipo_atividade_id === elemento?.tipo_atividade_id;

        let borderMaisDeDuasHrs = "";
        elemento?.horarios_aulas && elemento.horarios_aulas.forEach(horario_aula => {
            if (horario_aula?.aula?.duracao_minima > 2 || horario_aula?.rodizio?.duracao_minima > 2 || horario_aula?.servico?.tipo_atividade_id == 72 || horario_aula?.servico?.tipo_atividade_id == 14) {
                borderMaisDeDuasHrs = `.5rem ridge ${tipoAtividade.cor}`;
                const horarios_ids = Object.keys(estruturaDoGrid);
                const findIndex = horarios_ids.findIndex(horario => elemento.horario_do_curso_id == horario)
                const horarioOfertaEstrutura = estruturaDoGrid[horarios_ids[findIndex + 1]]?.[elemento.dia_da_semana]?.find(horario_aula => horario_aula?.tipo_atividade_id == elemento.tipo_atividade_id)

                if (horarioOfertaEstrutura && elemento.horario_do_curso_id == horario_aula?.horario_periodo.horario_do_curso_id) {
                    const horarioAula = horarioOfertaEstrutura.horarios_aulas.find(horario => horario.aulas_id == horario_aula?.aulas_id && horario_aula?.grupos_por_aula === horario.grupos_por_aula);
                    if (!horarioAula) {
                        horarioOfertaEstrutura.horarios_aulas.push(horario_aula);
                    }
                }
            }
            if (horario_aula?.servico_id != null) {
                let configuracoesRodizio = horario_aula?.servico?.servico_ciclo?.[0]?.ciclo?.configuracao_rodizio;
                if (configuracoesRodizio?.length == 0)
                    configuracoesRodizio = horario_aula?.servico?.servico_ciclo?.[1]?.ciclo?.configuracao_rodizio;
                if (Array.isArray(configuracoesRodizio)) {
                    configuracoesRodizio.map((configuracao) => {
                        if (horario_aula.local_id == configuracao.local_id) {
                            configuracao.horarios_rodizio.forEach(horario_rodizio => {
                                if ((horario_rodizio.horario_id == horario_aula.horario_periodo.horario_curso.horario.id && horario_rodizio.duracao_minima > 2) || horario_aula?.servico?.tipo_atividade_id == 72) {
                                    borderMaisDeDuasHrs = `.5rem ridge ${tipoAtividade.cor}`;
                                    const horarios_ids = Object.keys(estruturaDoGrid);
                                    const findIndex = horarios_ids.findIndex(horario => elemento.horario_do_curso_id == horario)
                                    const horarioOfertaEstrutura = estruturaDoGrid[horarios_ids[findIndex + 1]]?.[elemento.dia_da_semana]?.find(horario_aula => horario_aula?.tipo_atividade_id == elemento.tipo_atividade_id)

                                    if (horarioOfertaEstrutura && elemento.horario_do_curso_id == horario_aula?.horario_periodo.horario_do_curso_id) {
                                        const horarioAula = horarioOfertaEstrutura.horarios_aulas.find(horario => horario.aulas_id == horario_aula?.aulas_id && horario_aula?.grupos_por_aula === horario.grupos_por_aula);
                                        if (!horarioAula) {
                                            horarioOfertaEstrutura.horarios_aulas.push(horario_aula);
                                        }
                                    }
                                }
                            })
                        }
                    });
                }
            }
        })
        const attributes = {
            className: `align-content-middle ${classSelecionadoAtividade}`,
            style: { color: "white", flex: "1 1 0px", backgroundColor: tipoAtividade.cor, height: '100%', borderLeft: borderMaisDeDuasHrs },
            onClick: () => selecionarHorario({ ...elemento, estruturasNoMesmoDia: diaDaSemanaEstrutura })
        };

        function BotaoDeletar() {
            return (<Button onClick={() => deletarHorario(elemento)}>Excluir</Button>);
        }
        function MenuComAula(prova) {
            return (
                <Fragment>
                    <Button className="mb-3" onClick={() => { abrirModalAssociar(elemento) }}>Nova Aula</Button>
                    <Button className="mb-3" onClick={() => { abrirModalVisualizar(elemento) }}>Visualizar</Button>
                    <Button onClick={() => setModalTrocarAula({ HorarioAulas: elemento, isOpen: true })}> Trocar Aula </Button>
                </Fragment>
            );
        }

        function MenuAssociarAula(params) {
            return (
                <Fragment>
                    <Button className="mb-3" onClick={() => { abrirModalAssociar(elemento) }}>Associar Aula</Button>
                    <BotaoDeletar />
                </Fragment>
            );
        }

        function ConteudoNoBloco() {
            if (éTipoOferta && elemento.horarios_aulas && elemento.horarios_aulas.length !== 0) {
                return elemento.horarios_aulas.map((horarioAulas, indice) => (
                    <>
                        <Col sm={6} key={indice}>
                            <TextoNoBlocoPadraoCasoTenhaAulaAssociada
                                aula={horarioAulas}
                                tipoAtividade={tipoAtividade}
                                horario={horario}
                                indice={indice}
                            />
                        </Col>
                    </>
                ))
            } else {
                const gruposSelecionados = grupos?.filter(el => elemento.grupos_ids?.includes(el.id))
                return (
                    <Col>
                        {tipoAtividade.nome.toUpperCase()}
                        <br />
                        {gruposSelecionados?.map(el => `${el.descricao} `)}
                    </Col>
                )
            }
        }
        function Bloco({ provided }) {
            return (
                <div className='align-content-middle'
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <span
                        className="calendario-padrao-legenda"
                        style={{
                            color: corDoTexto(tipoAtividade.cor),
                        }}
                    >
                        {
                            éTipoOferta && elemento.horarios_aulas && elemento.horarios_aulas.length !== 0 ?
                                tipoAtividade.abreviatura : ''}
                        < ConteudoNoBloco />
                    </span>
                </div>
            );
        }

        if (!temMenu) {
            return (
                <div
                    key={indice}
                    className={attributes.className}
                    style={attributes.style}
                >
                    <Bloco />
                </div>
            );
        }

        return (
            <Fragment key={indice}>
                <ContextMenuTrigger
                    id={`teste${elemento.id}`}
                    attributes={attributes}
                >
                    <Droppable droppableId={`bloco${elemento.id}`} key={elemento.id} >
                        {(provided) => (<Bloco provided={provided} />)}
                    </Droppable>
                </ContextMenuTrigger>

                <ContextMenu
                    id={`teste${elemento.id}`}
                    className="horarioCalendario__contextMenu zindex-tooltip shadow-lg"
                >
                    {
                        éTipoOferta
                            ? elemento?.horarios_aulas?.length !== 0
                                ? <MenuComAula prova={elemento?.horarios_aulas?.[0]} />
                                : <MenuAssociarAula />
                            : <BotaoDeletar />
                    }
                </ContextMenu>
            </Fragment>
        )
    });
    //para provas
    const diaDaSemanaEstruturaProvas = pegarDiaDaSemana(horario, diaDaSemana, estruturaGridDatasProvas)
    const diasDeProvas = diaDaSemanaEstruturaProvas.map((elemento, indice) => {
        return (
            <div
                key={indice}
                style={{ color: "white", flex: "1 1 0px", backgroundColor: elemento.tipo_data.cor, height: '100%' }}
                className="align-content-middle"
            >
                <span
                    className="calendario-padrao-legenda"
                    style={{ color: corDoTexto(elemento.tipo_data.cor) }}
                >
                    {elemento.tipo_data.descricao.toUpperCase()}
                </span>
            </div>
        );
    });

    //para Feriados e FDS Letivo
    const diaDaSemanaEstruturaEspecial = pegarDiaDaSemana(horario, diaDaSemana, estruturaGridDatasEspeciais)
    const estruturaFeriados = diaDaSemanaEstruturaEspecial.filter(elemento => (elemento.tipo_de_data_id === 3));

    const feriadoNoDia = estruturaFeriados.map((elemento, indice) => {
        return (
            <div
                key={indice}
                style={{ color: "white", flex: "1 1 0px", backgroundColor: elemento.tipo_data.cor, height: '100%' }}
                className="align-content-middle"
            >
                <span
                    className="calendario-padrao-legenda"
                    style={{ color: corDoTexto(elemento.tipo_data.cor) }}
                >
                    {elemento.tipo_data.descricao.toUpperCase()}
                </span>
            </div>
        );
    });

    //Se não tiver atividade, o onclick deve ficar no parente
    const onClickEstruturaVazia = diaDaSemanaEstrutura.length === 0
        ? () => selecionarHorario(
            { horario_do_curso_id: horario.id, dia_da_semana: diaDaSemana, estruturasNoMesmoDia: diaDaSemanaEstrutura }
        )
        : undefined;
    const classNameEstruturaVazia = !atividadeExisteNobloco ? classSelecionado : "";

    return (
        <div
            className={`align-content-middle calendario-padrao-conteudo ${éConsulta ? "" : "cursor-ponteiro"} ${classNameEstruturaVazia}`}
            style={{ display: "flex" }}
            onClick={onClickEstruturaVazia}
        >
            {tipoDeAtividadesDoDia}
            {feriadoNoDia}
            {diasDeProvas}
        </div>
    );
};

const nomeAgregadorGrupo = (aula) => {
    return (
        <div className="mb-1" >
            {aula?.grupos_por_aula?.map((grupo, index) =>
                <span key={index}>{grupo?.grupo?.descricao}{index + 1 == aula?.grupos_por_aula?.length ? '' : ', '}</span>
            )}
        </div>
    )
}

function TextoNoBlocoPadraoCasoTenhaAulaAssociada({ aula, horario, indice }) {
    const divergenciaVazia = [{
        divergenciaDisponibilidade: false,
        divergenciaServico: false,
        semLocal: false,
        semProfessor: true,
        divergenciaQtdAulas: false,
        divergenciaQtdLocal: false,
        divergenciaQtdOfertas: false,
        horasDisponiveis: false,
        professor: ''
    }]

    let exibirAlertaDivergencias = false;
    const local = aula?.local; //critica se local está alocado, liberar quando autorizado
    const semProfessor = !aula?.professores.length;
    const tipoAtividadeAula = aula?.aula?.tipo_de_atividade.abreviatura;
    exibirAlertaDivergencias = semProfessor;
    const divergencias = semProfessor ?
        divergenciaVazia :
        aula?.professores.map(professor => {
            const { disponibilidade } = professor;
            const hora = disponibilidade && disponibilidade.find(hora => (hora.dia_da_semana == aula?.horario_periodo.dia_da_semana && hora.horario_id == horario.horario_id))
            const divergenciaDisponibilidade = hora?.tipo_de_disponibilidade_id == 3 || hora?.tipo_de_disponibilidade_id == undefined;
            const semLocal = local === null ? true : false;
            const divergenciaQtdLocal = false;
            const divergenciaQtdAulas = false;
            const divergenciaQtdOfertas = false;
            const horasDisponiveis = aula?.aula?.duracao_minima === 3;
            const semProfessor = false;
            const divergenciaServico = (tipoAtividadeAula == 'IPRA' || tipoAtividadeAula == 'VIV') ? !aula?.servico_id : false;
            exibirAlertaDivergencias = exibirAlertaDivergencias ? exibirAlertaDivergencias : divergenciaDisponibilidade || divergenciaQtdLocal || divergenciaQtdAulas || divergenciaQtdOfertas || divergenciaServico;

            return {
                divergenciaDisponibilidade,
                divergenciaServico,
                semLocal,
                semProfessor,
                divergenciaQtdLocal,
                divergenciaQtdAulas,
                divergenciaQtdOfertas,
                horasDisponiveis,
                professor
            }
        });

    return (
        <div className="d-flex flex-column align-items-start blocoPadraoComAula pl-1">
            <Row noGutters className="mb-1 hotrarioAulas" style={{ width: '100%' }}>
                <Col>{aula?.inicio.substring(0, 5)}</Col>
                <Col>{aula?.local?.abreviacao != null ? aula?.local.abreviacao.toUpperCase() : aula?.local?.nome.toUpperCase()}</Col>
            </Row>
            <span className="mb-1 local-el ">{aula.aulas_id != null ? aula?.aula?.titulo.toUpperCase() : aula?.servico?.descricao.toUpperCase()}</span>
            <span className="mb-1 blocoPadraoComAula__texto--comOverflow blocoPadraoComAula__texto--leve">{aula?.professores?.map(element => element.nome_social.toUpperCase()).join(',')}</span>
            <span className="mb-1 local-el">{nomeAgregadorGrupo(aula)}</span>
            {/* <span className="mb-1 blocoPadraoComAula__texto--comOverflow blocoPadraoComAula__texto--leve">{aula?.servico?.descricao}</span> */}
            {/* {console.log(aula)} */}
            <div className='icons-cadastro-oferta'>
                {
                    aula?.log_critica?.length > 0 ?
                        <>
                            <div className='iconAlert' id={`pop${aula?.id}`}></div>
                            <PopOverCriticas id={aula?.id} logCriticas={aula?.log_critica} />
                        </>
                        : ''
                }
                {
                    aula?.observacao != null &&
                    <>
                        <div className='iconObs' id={`pop2${aula?.id}`}></div>
                        <PopOverObs id={aula?.id} observacao={aula?.observacao} />
                    </>
                }
                {
                    aula?.log_mudanca?.length > 0 ?
                        <>
                            <div className='iconLog' id={`pop3${aula?.id}`}></div>
                            <PopOverLogAula id={aula?.id} aula={aula} logs={aula?.log_mudanca} />
                        </> : ''
                }
                {aula?.cancelada &&
                    <div title='Cancelada'>
                        <AiOutlineCloseCircle color='red' size="1rem" />
                    </div>
                }
                {/* <AiFillWarning id={`pop${aula?.id}`} color="yellow" size="1.2rem" /> */}
            </div>

        </div>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

export default React.memo(connect(null, mapDispatchToProps)(CardHoc(CardCalendarioPadrao)));
