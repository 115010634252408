import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap';
import { ModalFooter } from '../Basicos';

const ModalHoc = (ComponentWrap) => (props) => (
    <Modal 
        isOpen={props.boolModal} 
        toggle={props.toggle} 
        size={props.size} 
        centered={props.modalCentered} 
        className={props.modalClass} 
        backdrop="static"
    >
        <ModalBody className={props.modalBodyClass} >
            <ComponentWrap {...props} />
        </ModalBody>
       <ModalFooter {...props} />
    </Modal>
)

ModalHoc.propTypes = {
    boolModal: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string
}
export default ModalHoc;