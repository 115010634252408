import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, ButtonGroup, TabContent, TabPane, FormGroup } from 'reactstrap';
import { MenuDasFuncionalidades } from "../Menu/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import ChaveamentoDeModal from '../Modal/ChaveamentoDeModal';
import { axiosApi } from '../Basicos/axiosInstances';
import CarouselBase from '../Basicos/CarouselBase';
import { Link } from 'react-router-dom';
import CardNoticiaMaisLida from '../Conteudo/Cards/CardNoticiaMaisLida';
import { primeiraImagemDeUmaNoticia } from '../../services/noticias';
import { NewLoader } from '../Basicos';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import CarouselNews from '../Basicos/CarouselNews';

function NoticiasEEventos({
    noticias,
    estadoModal,
    desativarModal,
    adicionar,
    editarFunc,
    estruturaModal,
    ativarAlert,
    ativarModalEditarNoticiasEEventos,
    ativarCofirmacao
}) {
    const [noticiasData, setNoticiasData] = useState();
    const [loader, setLoader] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [filterNoticias, setFilterNoticias] = useState(undefined);
    const { innerWidth: width } = window;

    const getEventosNoticias = async () => {
        setLoader(true);
        axiosApi.get('/eventos-noticias')
            .then(resp => {
                setNoticiasData(resp.data)
                // window.location.reload();
            }).catch(error => {
                if (error.response.status === 403) {
                    ativarAlert(400, error?.response?.data?.mensagem)
                }
            }).finally(() => {
                setLoader(false)
            });
    };

    const removerOnClick = async (id) => {
        setLoader(true);
        axiosApi.delete(`/eventos-noticias/${id}`)
            .then(resp => {
                ativarAlert(201, resp.data.sucesso);
                axiosApi.get('/eventos-noticias').then(response => {
                    setNoticiasData(response.data);
                })
            }).catch(error => {
                if (error.response.status === 403) {
                    ativarAlert(400, error?.response?.data?.mensagem)
                } else {
                    ativarAlert("remover");
                }
            }).finally(() => {
                setLoader(false)
                // window.location.reload();
            });
    };

    useEffect(() => {
        getEventosNoticias();
    }, []);


    useEffect(() => {
        if (noticias[0].dados !== undefined) {
            const novaNoticia = noticias[0].dados[0];

            setNoticiasData(novaNoticia);
        }
    }, [noticias[0].dados]);

    const NoticiaBloco = (props) => {
        const { noticia, withButtonEdit = false, withButtonDelete = false, ativarModalEditarNoticiasEEventos, editarFuncionalidade, removerOnClick, key } = props;

        const ellipse = (noticia?.titulo)?.length > 30 ? "..." : "";
        return (
            <Link to={`/eventos-e-noticias/${noticia.id}`}>
                <div style={{
                    border: '1px',
                    backgroundColor: 'white',
                    padding: '1rem',
                    boxShadow: '0px 0px 4px 0px #00000040',
                    borderRadius: '16px',
                    width: '200px',
                    position: 'relative',
                    height: '240px'
                }} key={key}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <img
                            src={primeiraImagemDeUmaNoticia(noticia)}
                            className="shadow-sm"
                            style={{ width: '160px', height: '160px', borderRadius: '16px' }}
                            alt=""
                        />
                        <span
                            className="font-weight-bold azul-principal"
                            style={{
                                fontSize: "14px",
                                paddingTop: '0.5rem',
                            }}
                        >
                            {(noticia.titulo).substring(0, 30) + ellipse}
                        </span>
                    </div>
                    {withButtonDelete || withButtonEdit ? <div style={{ position: 'absolute', right: '0px', top: '0px', backgroundColor: '#19385d', color: "white", padding: '5px', fontSize: '1rem', borderTopRightRadius: '16px', borderBottomLeftRadius: '16px' }}>
                        <ButtonGroup
                            style={{
                                gap: '0.5rem',
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                padding: '0.2rem'
                            }}
                        >
                            {withButtonEdit ? <FiEdit onClick={() => ativarModalEditarNoticiasEEventos(noticia?.id)} style={{ cursor: "pointer", marginLeft: '5px' }} /> : ''}
                            {withButtonDelete ? <FiTrash2 onClick={() => ({})} style={{ cursor: "pointer", marginRight: '5px' }} /> : ''}
                        </ButtonGroup>
                    </div> : ''}
                </div>
            </Link>
        )
    }

    const ColunaNoticia = (props) => {
        const { noticias, className, withButtonDelete, withButtonEdit, editarOnClick, ativarCofirmacao, removerOnClick, titulo } = props;
        return (
            noticias?.length > 0 && <div>
                <h3 className="font-weight-bold azul-principal mb-2">{titulo}</h3>
                <div style={{ height: "auto", gap: '2rem', display: 'flex', flexWrap: 'wrap' }} className={className}>
                    {
                        noticias.map((noticia, key) => {
                            const ellipse = (noticia?.titulo)?.length > 150 ? "..." : "";
                            return (
                                <div style={{
                                    width: '100%',
                                    border: '1px',
                                    backgroundColor: 'white',
                                    padding: '1rem',
                                    boxShadow: '0px 0px 4px 0px #00000040',
                                    borderRadius: '16px',
                                    position: 'relative',
                                }} key={key}>
                                    <Link
                                        key={noticia.id}
                                        to={`/eventos-e-noticias/${noticia.id}`}
                                        className=""
                                    >
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: '1rem'
                                        }}>
                                            <div style={{ width: '20%', height: '100%', display: 'flex' }}>
                                                <img
                                                    src={primeiraImagemDeUmaNoticia(noticia)}
                                                    className="shadow-sm"
                                                    style={{ borderRadius: '16px', width: '150px', height: '150px' }}
                                                    alt=""
                                                />
                                            </div>
                                            <div style={{ width: '80%', display: 'flex', flexDirection: 'column', padding: '0.5rem' }}>
                                                <span className="align-middle font-weight-bold azul-principal">
                                                    {(noticia.titulo).substring(0, 150) + ellipse}
                                                </span>
                                                <span
                                                    className="align-middle azul-principal"
                                                    style={{
                                                        fontSize: "11px",
                                                    }}
                                                >
                                                    {(noticia.subtitulo).substring(0, 100)}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                    {withButtonDelete || withButtonEdit ? <div style={{ position: 'absolute', right: '0px', top: '0px', backgroundColor: '#19385d', color: "white", padding: '5px', fontSize: '1rem', borderTopRightRadius: '16px', borderBottomLeftRadius: '16px' }}>
                                        <ButtonGroup
                                            style={{
                                                gap: '0.5rem',
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                padding: '0.2rem'
                                            }}
                                        >
                                            {withButtonEdit ? <FiEdit onClick={() => editarOnClick(noticia?.id)} style={{ cursor: "pointer", marginLeft: '5px' }} /> : ''}
                                            {withButtonDelete ? <FiTrash2 onClick={() => ativarCofirmacao({ titulo: 'deletar', mensagem: 'Deseja remover este item ?', callback: () => removerOnClick(noticia?.id) })} style={{ cursor: "pointer", marginRight: '5px' }} /> : ''}
                                        </ButtonGroup>
                                    </div> : ''}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    const LinhaDeNoticia = (props) => {
        return (props?.noticias?.length > 0 ? (<div xs="1" className={props.className} style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            {props.titulo !== undefined ? <Col>
                <h3 className="azul-principal font-weight-bold mb-3">{props.titulo}</h3>
            </Col> : null}
            <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%', padding: '2px' }}>
                {
                    props.withCarousel ? preencheItems(props?.noticias, true, props?.withButtonEdit, props?.withButtonDelete) : props.noticias.map((noticia, key) => <NoticiaBloco
                        noticia={noticia}
                        key={key}
                        withButtonEdit={props?.withButtonEdit ?? false}
                        withButtonDelete={props?.withButtonDelete ?? false}
                    />)
                }
            </div>
        </div>) : null);
    }

    function filtrarNoticias(noticias, filtro) {
        if (!noticias || !filtro) {
            return noticias || [];
        }

        return noticias?.filter((noticia) => {
            const titulo = noticia?.titulo?.toLowerCase();
            const filtroLowerCase = filtro.toLowerCase();

            return titulo && titulo.indexOf(filtroLowerCase) !== -1;
        }) || [];
    }

    const blocoNoticias = (noticias, indice) => {
        const noticiasGeralFilter = indice === 0 ? filtrarNoticias(noticias?.maisRecentes, filterNoticias) : filtrarNoticias(noticias?.geral, filterNoticias);
        const metade = Math.ceil(noticiasGeralFilter.length / 2);

        const array1 = noticiasGeralFilter.slice(0, metade);
        const array2 = noticiasGeralFilter.slice(metade);

        {
            return (noticias && <Fragment>
                <div style={{ paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem', paddingTop: '1rem', display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {filterNoticias === undefined && noticias?.maisRecentes?.length > 0 ? <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ width: '70%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <h3 className="font-weight-bold azul-principal mb-3">Mais Recentes</h3>
                                {preencheItems(noticias.maisRecentes.slice(0, 3))}
                            </div>
                            <div style={{ width: '100%', display: 'flex', overflow: 'hidden', height: '100%' }}>
                                <LinhaDeNoticia
                                    noticias={noticias.maisRecentes.slice(0, width >= 1920 ? 5 : width <= 1280 ? 3 : 4)}
                                />
                            </div>
                        </div>
                        <div style={{ width: '30%' }}>
                            {noticias?.maisLidas ? <CardNoticiaMaisLida noticias={noticias.maisLidas} /> : null}
                        </div>
                    </div> : null}
                    {filterNoticias === undefined ? <LinhaDeNoticia
                        className="mt-4"
                        titulo="Avisos"
                        withButtonEdit={true}
                        withButtonDelete={true}
                        noticias={noticias.avisos}
                        withCarousel={true}
                    /> : null}
                    {filterNoticias === undefined ? <LinhaDeNoticia
                        className="mt-4"
                        titulo="Eventos"
                        withButtonEdit={true}
                        withButtonDelete={true}
                        noticias={noticias.eventos}
                        withCarousel={true}
                    /> : null}
                    {indice === 0 && filterNoticias === undefined ? <Row xs="1" md="2" className="mt-4">
                        <Col>
                            <ColunaNoticia
                                titulo={"Informações"}
                                noticias={noticias?.informacoes?.slice(0, 5)}
                                className="mb-4 mb-sm-0"
                                withButtonEdit={true}
                                withButtonDelete={true}
                                editarOnClick={ativarModalEditarNoticiasEEventos}
                                ativarCofirmacao={ativarCofirmacao}
                                removerOnClick={removerOnClick}
                            />
                        </Col>
                        <Col>
                            <ColunaNoticia
                                titulo={"Dicas"}
                                noticias={noticias?.dicas?.slice(0, 5)}
                                className="mb-4 mb-sm-0"
                                withButtonEdit={true}
                                withButtonDelete={true}
                                editarOnClick={ativarModalEditarNoticiasEEventos}
                                ativarCofirmacao={ativarCofirmacao}
                                removerOnClick={removerOnClick}
                            />
                        </Col>
                    </Row> : null}

                    {indice !== 0 || filterNoticias !== undefined ? <Row xs="1" md="2" className="mt-4">
                        <Col>
                            <ColunaNoticia
                                noticias={array1}
                                className="mb-4 mb-sm-0"
                                withButtonEdit={true}
                                withButtonDelete={true}
                                editarOnClick={ativarModalEditarNoticiasEEventos}
                                ativarCofirmacao={ativarCofirmacao}
                                removerOnClick={removerOnClick}
                            />
                        </Col>
                        <Col>
                            <ColunaNoticia
                                noticias={array2}
                                className="mb-4 mb-sm-0"
                                withButtonEdit={true}
                                withButtonDelete={true}
                                editarOnClick={ativarModalEditarNoticiasEEventos}
                                ativarCofirmacao={ativarCofirmacao}
                                removerOnClick={removerOnClick}
                            />
                        </Col>
                    </Row> : null}
                </div>
            </Fragment>);
        }
    }


    const preencheItems = (noticias, carouselRow = false, withButtonEdit = false, withButtonDelete = false) => {

        if (carouselRow) {
            return <CarouselNews
                items={noticias}
                withButtonEdit={withButtonEdit}
                withButtonDelete={withButtonDelete}
                editarOnClick={ativarModalEditarNoticiasEEventos}
                ativarCofirmacao={ativarCofirmacao}
                removerOnClick={removerOnClick}
            />
        } else {
            const items = [];
            noticias.forEach(noticia => {
                items.push({
                    src: primeiraImagemDeUmaNoticia(noticia),
                    altText: noticia.titulo,
                    caption: noticia.subtitulo,
                    id: noticia.id
                });
            });

            return <CarouselBase items={items} />;
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <MenuDasFuncionalidades
                funcionalidade={"noticias-e-eventos"}
                classNavBar="navbar-consultas"
                titulo="Notícias e Eventos"
                navs={noticiasData?.navs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setFilter={setFilterNoticias}
                filterNoticias={filterNoticias}
            />

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <NewLoader isActive={loader}>
                    {noticiasData?.navs && (
                        <Fragment>
                            {noticiasData?.navs.map((elemento, indice) => {
                                return <TabContent activeTab={activeTab} key={indice}>
                                    <TabPane tabId={indice}>
                                        <FormGroup row className="p-4">
                                            {blocoNoticias(noticiasData?.navs[indice]?.blocos, indice)}
                                        </FormGroup>
                                    </TabPane>
                                </TabContent>
                            })}
                        </Fragment>
                    )}

                </NewLoader>
            </div>
            <ChaveamentoDeModal
                boolModal={estadoModal}
                toggle={desativarModal}
                adicionar={adicionar}
                editar={editarFunc}
                estrutura={estruturaModal}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarModalEditarNoticiasEEventos: (id) => dispatch(actionsRedux.ativarModalSubFuncionalidade('eventos-e-noticias', 0, 'editar', id)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
    }
}

const mapStateToProps = (state) => {
    return {
        noticias: state.subFuncionalidades["eventos-e-noticias"],
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticiasEEventos)