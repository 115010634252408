import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ativarModalConfirm, ativarAlert, setPeriodoLetivo } from '../Store/Actions';
import { axiosApi } from '../Basicos/axiosInstances';
import { CardCalendarioPadrao, CardAddAtividadePadrao, CardInfoCalendarioOfertas } from './Cards';
import { Col, Row } from 'reactstrap';
import pick from 'lodash/pick';
import { NewLoader } from '../Basicos';
import { transformaDataEmString, dataDeUmaSemanaEmString, semanaInicialDoPeriodo, avancarMes, retrocederMes, avancarSemana, retrocederSemana, diaDaSemanaDaDataString } from '../../services/calendarioOferta';
import { ModalAssociar, ModalVisualizar, ModalCancelarAula } from '../Modal';
import { error as JoinMsgError } from '../Basicos/funcoes';
import PDFSemanaPadrao from '../Basicos/PDFSemanaPadrao';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import ModalCritica from '../Modal/ModalCritica';
import ModalConteudo from '../Modal/ModalConteudo';

function CalendarioOfertaPeriodoLetivo({
    match: {
        params: {
            semestre_id,
            periodo_letivo_id,
            turma_id,
            tema_id
        }
    },
    subFuncionalidade: {
        url,
    },
    ativarCofirmacao,
    ativar
}) {
    const [temas, setTemas] = useState([]);

    const [turma, setTurma] = useState([]);

    const [ofertasPeriodos, setOfertasPeriodos] = useState({});

    const [infoHorarioSelecionado, setInfoHorarioSelecionado] = useState({});

    const [horariosDefinidos, setHorariosDefinidos] = useState([]);

    const [datasInstitucionais, setDatasInstitucionais] = useState([]);

    const [periodoLetivo, setPeriodoletivo] = useState({});

    const [tiposDeAtividades, setTiposDeAtividades] = useState([]);

    const [horariosOfertas, setHorariosOfertas] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [semana, setSemana] = useState([]);

    const [aulas, setAulas] = useState([]);

    const [aula, setAula] = useState({});

    const [horariosAula, setHorariosAula] = useState([]);

    const [modalAssociarEstaAberto, setModalAssociarEstaAberto] = useState(false);

    const [modalVisualizarEstaAberto, setModalVisualizarEstaAberto] = useState(false);

    const [horarioModal, setHorarioModal] = useState({});

    const [grupos, setGrupos] = useState([]);

    const [observacao, setObservacao] = useState();

    // const [professores, setProfessores] = useState([]);

    const [professoresDaAula, setProfessoresDaAula] = useState([]);

    const [inicio, setInicio] = useState({});

    const [locais, setLocais] = useState([]);

    const [local, setLocal] = useState({});

    const [grupoIds, setGrupoIds] = useState([]);

    const [agregadorGrupo, setAgregadorGrupo] = useState(0);

    const [servico, setServico] = useState();

    const [aulasPDF, setAulasPDF] = useState([]);

    const [semanasPDF, setSemanasPDF] = useState([]);

    const [mesesPDF, setMesesPDF] = useState([]);

    const [datasInstitucionaisPDF, setDatasInstitucionaisPDF] = useState([]);

    const [dataUltimaAtualizacao, setDataUltimaAtualizacao] = useState();

    const [modalCritica, setModalCritica] = useState(false);

    const [dadosCritica, setDadosCritica] = useState([]);

    const [dados, setDados] = useState([]);

    const [bloquearBotao, setBloquearBotao] = useState(false);

    const [modalConteudo, setModalConteudo] = useState(false);

    const [provaId, setProvaId] = useState();

    const [modalCancelar, setModalCancelar] = useState({
        isOpen: false,
        horarioAula: undefined,
        motivo: undefined
    });

    const [pdfPronto, setPdfPronto] = useState(false); //arranjo tecnico para nao atualizar calendario do PDF

    const [horarioAulaId, setHorarioAulaId] = useState();

    const pegaDataSelecionada = infoHorario =>
        dataDeUmaSemanaEmString({ semana, diaDaSemana: parseInt(infoHorario.dia_da_semana) });

    const selecionarHorario = infoHorario => {
        const horario_curso = getHorariosDoCurso().filter(horario => horario.id === infoHorario.horario_do_curso_id)[0];

        if (infoHorario.data_selecionada)
            setInfoHorarioSelecionado({
                ...infoHorario,
                dia_da_semana: diaDaSemanaDaDataString(infoHorario.data_selecionada),
                horario_curso,
            });
        else
            setInfoHorarioSelecionado({
                ...infoHorario,
                data_selecionada: pegaDataSelecionada(infoHorario),
                horario_curso,
            });
    }

    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];

    const temaDoHorarioSelecionado = () => (temas.id);

    const tiposDeAtividadesDoTema = () => {
        return tiposDeAtividades;
    }

    //TODO: Alterar para receber do endpoint
    const getHorariosDoCurso = () =>
        temas && temas.curso && temas.curso.horarios
            ? temas.curso.horarios
            : [];

    useEffect(() => {
        let horarios = [];
        let datas_institucionais = [];

        if (!pdfPronto && semana.length > 0 && horariosOfertas.length > 0) {
            let semanas = [];
            let semanaAtual = semana;
            let mesesDaSemana = [];
            let institucionais = [];
            //loop para alimentar o PDF
            while (true) {
                const proximaSemana = avancarSemana({ semana: semanaAtual, periodoLetivo: temas?.semanapadrao });

                let semanaAux = '';

                semanas.push(semanaAtual);

                //semanas disponiveis
                semanaAtual.map((dataString, indice) => {
                    const data = new Date(dataString);
                    let semanaArray = semanaAux.split(" ");
                    if (!(semanaArray.includes(meses[data.getMonth()])))
                        semanaAux = semanaAux == '' ? meses[data.getMonth()] : semanaAux + " " + meses[data.getMonth()]
                });

                //datas institucionais
                institucionais.push(semanaAtual.flatMap(diaDaSemana =>
                    ofertasPeriodos.calendarioInstitucional.filter(data_institucional =>
                        data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                    )
                ));

                mesesDaSemana.push(semanaAux);

                // Verifica se a próxima semana é igual a semana atual
                if (proximaSemana === semanaAtual) {
                    break; // Se forem iguais, sai do loop
                }

                semanaAtual = proximaSemana;
            }
            setMesesPDF(mesesDaSemana)

            let horarios2 = []

            for (let i = 0; i < semanas.length; i++) {
                const semanaAtual = semanas[i];

                const horariosSemana = semanaAtual.flatMap(diaDaSemana =>
                    horariosOfertas.filter(horarioOferta =>
                        horarioOferta.data.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
                    )
                );

                horarios2.push(horariosSemana); // Adiciona os horários desta semana ao array de semanas
            }

            setSemanasPDF(semanas);
            setAulasPDF(horarios2);
            setDatasInstitucionaisPDF(institucionais);
            setPdfPronto(true);
        }

        horarios = semana.flatMap(diaDaSemana =>
            horariosOfertas.filter(horarioOferta =>
                horarioOferta.data.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
            )
        );

        datas_institucionais = semana.flatMap(diaDaSemana =>
            ofertasPeriodos.calendarioInstitucional.filter(data_institucional =>
                data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
            )
        );

        setInfoHorarioSelecionado({});
        setDatasInstitucionais(datas_institucionais);
        setHorariosDefinidos(horarios);
    }, [semana, horariosOfertas]);

    const salvarHorario = async () => {
        const infoHorario = { ...infoHorarioSelecionado };
        const horariosDefinidosAux = horariosDefinidos.slice();

        try {
            setIsLoading(true);
            const resp = await axiosApi({
                method: "patch",
                url: `${url}/${temas.id}`,
                data: { ...infoHorario, periodo_letivo_id: parseInt(periodo_letivo_id), estruturasNoMesmoDia: undefined, turma_id: turma_id }
            });

            if (infoHorario.indiceArray !== undefined) {
                horariosDefinidosAux[infoHorario.indiceArray] = infoHorario;
            } else {
                infoHorario.indiceArray = horariosDefinidos.length;
                infoHorario.estruturasNoMesmoDia.push(infoHorario);
                horariosDefinidosAux.push(infoHorario);
            }

            const horario_curso = getHorariosDoCurso().filter(horario => horario.id === infoHorario.horario_do_curso_id)[0];

            //para adicionar no horarios ofertass
            let infoHorarioAux = {
                ...infoHorario,
                id: resp.data.horario_oferta_id,
                data_inicio: resp.data.data_inicio,
                data_fim: resp.data.data_fim,
                horarios_aulas: [],
                grupo_por_aulas: [],
                data: infoHorario.data_selecionada,
                horario_curso,
                calendario_institucional_id: null,
                tema_id: resp.data.tema_id,
                dia_da_semana: diaDaSemanaDaDataString(infoHorario.data_selecionada),
            };

            let horariosOfertasAux = horariosOfertas.slice();
            let horarioOfertaIndex = horariosOfertasAux.findIndex(horario => horario.id === infoHorarioAux.id);

            horarioOfertaIndex === -1
                ? horariosOfertasAux.push(infoHorarioAux)
                : horariosOfertasAux[horarioOfertaIndex] = infoHorarioAux;

            horariosDefinidosAux[infoHorarioAux.indiceArray] = infoHorarioAux;

            setHorariosDefinidos(horariosDefinidosAux);
            setHorariosOfertas(horariosOfertasAux);
            selecionarHorario(infoHorarioAux);

        } catch (error) {
            if (error.response.status == 400) {
                ativar(400, error.response.data.erro);
            } else {
                ativar("adicionar", "error");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const confirmaChamaDeleta = (infoHorario) =>
        ativarCofirmacao({
            titulo: 'Deletar',
            mensagem: 'Deseja deletar?',
            callback: () => chamaDeleta(infoHorario)
        })

    const chamaDeleta = (infoHorario) => {
        if (infoHorario.id === undefined)
            return;
        setIsLoading(true);
        axiosApi.delete(`${url}/${infoHorario.id}`)
            .then(resp => {
                setHorariosDefinidos(horariosDefinidos.filter(horario => horario.id !== infoHorario.id));
                setHorariosOfertas(horariosOfertas.filter(horario => horario.id !== infoHorario.id));
                ativar("remover", "sucesso");
            }).finally(
                setIsLoading(false)
            );

    };

    const procurarHorarioComMesmaAtividadeNoDia = ({ tipo_atividade_id, estruturasNoMesmoDia }) => {
        if (tipo_atividade_id && estruturasNoMesmoDia)
            return estruturasNoMesmoDia.find(elemento => elemento.tipo_atividade_id === tipo_atividade_id);
        return undefined;
    };

    const procurarHorarioComMesmoDiaEHorario = ({ dia_da_semana, horario_do_curso_id, tipo_atividade_id }) => {
        let horario = { estruturasNoMesmoDia: [] };

        horariosDefinidos.forEach((elemento, indice) => {
            if (elemento.dia_da_semana === dia_da_semana && elemento.horario_do_curso_id === horario_do_curso_id) {
                if (elemento.tipo_atividade_id === tipo_atividade_id) {
                    horario = { ...elemento, ...horario };
                }
                horario.estruturasNoMesmoDia.push(elemento);
            }
        });

        return horario;
    };

    const verificarMudancaEstado = (novo, antigo) => novo && novo !== antigo;

    const verificarMudancaTipoAtividade = (obj1, obj2) => {
        return verificarMudancaEstado(obj1.tipo_atividade_id, obj2.tipo_atividade_id);
    };

    const verificarMudancaHorarioEDiaSemana = (obj1, obj2) => {
        return verificarMudancaEstado(obj1.horario_do_curso_id, obj2.horario_do_curso_id) || verificarMudancaEstado(obj1.data_selecionada, obj2.data_selecionada);
    };

    const onChangeHorario = (valores) => {
        const infoHorario = { ...infoHorarioSelecionado, ...valores };
        if (verificarMudancaHorarioEDiaSemana(valores, infoHorarioSelecionado)) {
            selecionarHorario({
                ...procurarHorarioComMesmoDiaEHorario(infoHorario),
                ...pick(infoHorario, ["dia_da_semana", "data_inicio", "data_fim", "horario_do_curso_id", "horario_curso", "tipo_atividade_id", "data_selecionada", "tema_id", "data"])
            });
        } else if (verificarMudancaTipoAtividade(valores, infoHorarioSelecionado)) {
            selecionarHorario({
                ...procurarHorarioComMesmaAtividadeNoDia(infoHorario),
                ...pick(infoHorario, ["dia_da_semana", "data_inicio", "data_fim", "horario_do_curso_id", "horario_curso", "tipo_atividade_id", "data_selecionada", "tema_id", "data", "estruturasNoMesmoDia"])
            });
        } else {
            selecionarHorario(infoHorario);
        }
    };

    //Requisição inicial
    useEffect(() => {
        axiosApi({
            method: "GET",
            url: `${url}/${semestre_id}`,
            params: { periodo_letivo_id, turma_id, tema_id }
        }).then(resp => {
            const {
                tema,
                aulas,
                local,
                professores,
                grupoalunos,
                periodoLetivo,
                ofertaPeriodo,
                horariosofertas,
                tiposDeAtividades,
                calendarioInstitucional,
                ultima_atualizacao,
                turma
            } = resp.data;

            ofertaPeriodo.calendarioInstitucional = calendarioInstitucional;
            setTemas(tema);
            setTurma(turma);
            setAulas(aulas);
            setLocais(local);
            setGrupos(grupoalunos);
            // setProfessores(professores);
            setPeriodoletivo(periodoLetivo);
            setOfertasPeriodos(ofertaPeriodo);
            setHorariosOfertas(horariosofertas);
            setTiposDeAtividades(tiposDeAtividades);
            setDataUltimaAtualizacao(ultima_atualizacao)
            // setInfoHorarioSelecionado((prevState) => ({ ...prevState, datainicial }));
        }).finally(
            setIsLoading(false)
        )
    }, [url, semestre_id, periodo_letivo_id, turma_id]);

    useEffect(() => {
        //AQUI SETA A SEMANA QUE EXIBIRÁ NO CALENDARIO
        const periodoLetivo = temas.semanapadrao
        setSemana(semanaInicialDoPeriodo({ periodoLetivo }))
    }, [periodoLetivo]);

    const mudarMes = (flag) => {
        if (flag === "avancar")
            setSemana(avancarMes({ semana, periodoLetivo }));
        if (flag === "retroceder") {
            var semanaPadrao = {
                ...periodoLetivo,
                inicio: temas?.semanapadrao.inicio
            }
            setSemana(retrocederMes({ semana, periodoLetivo: semanaPadrao }));
        }
    }

    const mudarSemana = (flag) => {
        if (flag === "avancar")
            setSemana(avancarSemana({ semana, periodoLetivo }));
        if (flag === "retroceder") {
            var semanaPadrao = {
                ...periodoLetivo,
                inicio: temas?.semanapadrao.inicio
            }
            setSemana(retrocederSemana({ semana, periodoLetivo: semanaPadrao }));
        }
    }

    const profDoHorario = ({ horarioAula }) => horarioAula?.professores?.map(professor => professor.id);

    const localDoHorario = ({ horarioAula }) => locais.find(local => local.id === horarioAula?.local_id);

    const abrirCriarModalAssociar = (horarioAula) => {
        setInicio("")
        setHorariosAula(null);
        setAula(undefined);
        setProfessoresDaAula([]);
        setLocal(undefined);
        setHorarioModal(horarioAula);
        setModalAssociarEstaAberto(true);
        setHorarioAulaId(null)
    }

    const abrirEditarModalAssociar = (horarioAula) => {
        const prof = profDoHorario({ horarioAula });
        const local = localDoHorario({ horarioAula });

        setInicio(horarioAula?.inicio);
        setHorariosAula(horarioAula);

        setObservacao(horarioAula?.observacao)
        setAula(horarioAula?.aula);
        setProfessoresDaAula(prof);
        setLocal(local);
        setModalAssociarEstaAberto(true);
        setHorarioAulaId(horarioAula.id);
    }

    const abrirModalVisualizar = horario => {
        setHorarioModal(horario);
        setModalVisualizarEstaAberto(true);
    }

    const abrirModalConteudo = (provaId) => {
        setModalConteudo(true);
        setProvaId(provaId);
    }

    const modalCancelarAula = horarioAula => {
        setModalCancelar({
            ...modalCancelar,
            isOpen: true,
            horarioAula: horarioAula
        })
    }

    const submitCancelarAula = async () => {
        try {
            const { motivo, horarioAula } = modalCancelar;
            await axiosApi.put(`${url}/aulas-por-atividades/${horarioAula.id}`, { motivo, horarioAula });
            const horarioPrev = horariosOfertas.slice();
            const ofertaIndex = horarioPrev.findIndex(h => h.id === horarioAula.horario_oferta_perido_id)
            const horarioAulaIndex = horarioPrev[ofertaIndex].horarios_aulas.findIndex(h => h.id === horarioAula.id)
            horarioPrev[ofertaIndex].horarios_aulas[horarioAulaIndex] = { ...horarioAula, cancelada: !horarioAula.cancelada }
            setHorariosDefinidos(prev => horarioPrev);
            setHorariosOfertas(prev => horarioPrev);
            setModalCancelar({
                ...modalCancelar,
                isOpen: false,
                horarioAula: undefined,
                motivo: undefined
            })
        } catch (error) {
            ativar("remover", "error");
            console.log(error)
        }
    }

    const criticaDnD = async (horarioBloco, aula, professoresIds) => {
        var turmaId = turma != null ? turma.id : null
        var dados = {
            horario_oferta_perido_id: horarioBloco.id,
            aulas_id: aula?.id,
            grupo_alunos: [],
            professores_id: professoresIds,
            local_id: aula.local?.id,
            inicio: horarioBloco.horario_curso.inicio,
            semestre_id: semestre_id,
            turma_id: turmaId,
            dnd: true
        }
        setDados(dados)
        const response = await axiosApi.post(`/criticar-aula`, dados);
        setBloquearBotao(true)
        if (!bloquearBotao)
            if (response.data.criticado) {
                setDadosCritica(response.data.dados)
                setModalCritica(true)
            } else {
                submitAulaDnD(dados);
            }
    }

    const submitAulaDnD = async (infoExtra) => {
        try {
            const response = await axiosApi({
                method: "post",
                url: `${url}/aulas-por-atividades`,
                data: {
                    ...infoExtra,
                    criticas: dadosCritica
                }
            });
            const horariosOfertasAulasAux = horariosOfertas.slice();
            const horario = response.data.horario;
            const horarioIndex = horariosOfertas.findIndex(ho => ho.id === infoExtra.horario_oferta_perido_id);
            horariosOfertasAulasAux[horarioIndex].horarios_aulas.push({ ...horario });
            setHorariosOfertas(horariosOfertasAulasAux);
            ativar("adicionar", "sucesso");
        } catch (error) {
            console.log(error)
            if (error.response.data.erro) {
                const msg = JoinMsgError(error.response.data.erro)
                ativar(400, msg);
            } else {
                ativar("adicionar", "error");
            }
        }
        finally {
            setBloquearBotao(false)
            setModalCritica(false);
            setDados(null);
            setDadosCritica(null);
        }
    };

    const criticar = async () => {
        setBloquearBotao(true)
        var dados = {
            horario_oferta_perido_id: horarioModal?.id,
            aulas_id: aula?.id,
            grupo_alunos: grupoIds ?? [],
            professores_id: professoresDaAula,
            local_id: local?.id,
            inicio: inicio,
            horario_aula_id: horariosAula?.id,
            servico_id: servico,
            observacao: observacao,
            semestre_id: semestre_id,
            turma_id: turma_id,
            dnd: false
        }
        setDados(dados)
        const response = await axiosApi.post(`/criticar-aula`, dados);
        if (response.data.criticado) {
            setDadosCritica(response.data.dados)
            setModalCritica(true)
        } else {
            vinculaHorarioEAula(dados);
        }
    }

    const fecharModalCritica = () => {
        setBloquearBotao(false)
        setDadosCritica(null);
        setModalCritica(false)
    }

    const updateServico = (gruposServicoAtual) => {
        var dados = {
            horario_oferta_perido_id: horarioModal?.id,
            aulas_id: aula?.id,
            grupo_alunos: gruposServicoAtual ?? [],
            professores_id: professoresDaAula,
            local_id: local?.id,
            inicio: inicio,
            horario_aula_id: horariosAula?.id,
            servico_id: servico,
            observacao: observacao,
            semestre_id: semestre_id,
            turma_id: turma_id,
            dnd: false,
            tema_id: tema_id,
        }

        vinculaHorarioEAula(dados)
    }

    const vinculaHorarioEAula = (infos) => {
        setIsLoading(true)
        axiosApi({
            method: "post",
            url: `${url}/aulas-por-atividades`,
            data: {
                ...infos,
                criticas: dadosCritica
            }
        }).then(resp => {
            // horario === aula assosiada coma as informções
            if (!horariosAula) {
                const horariosOfertasAulasAux = horariosOfertas.slice();
                const { horario } = resp.data;
                const horarioIndex = horariosOfertas.findIndex(ho => ho.id === horarioModal.id);
                let aulaIndex = 0;
                if (horario.aula != null)
                    aulaIndex = horariosOfertasAulasAux[horarioIndex].horarios_aulas.findIndex(ho => ho.id !== horario.aula.id);
                else
                    aulaIndex = horariosOfertasAulasAux[horarioIndex].horarios_aulas.findIndex(ho => ho.id !== horario.servico.id);
                horariosOfertasAulasAux[horarioIndex].horarios_aulas.push({ ...horario });
                // setHorariosDefinidos(prev => horariosOfertasAulasAux); //REMOVENDO PQ QUEBROU OUTRA COISA
                setHorariosOfertas(prev => horariosOfertasAulasAux);
            } else {
                const horariosOfertasAulasAux = horariosOfertas.slice();
                const { horario } = resp.data;
                const horarioIndex = horariosOfertas.findIndex(ho => ho.id === horarioModal.id);
                const aulaIndex = horariosOfertasAulasAux[horarioIndex].horarios_aulas.findIndex(ho => ho.id === horariosAula.id);
                horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex] = { ...horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex], ...horario }
                horariosOfertasAulasAux[horarioIndex].horarios_aulas = [...horariosOfertasAulasAux[horarioIndex].horarios_aulas];
                if (horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex].servico_id == null) {
                    if (horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex].aula.duracao_minima > 2) {
                        horariosOfertasAulasAux.find((el, index) => {
                            if (el.horarios_aulas[0]?.horario_oferta_perido_id == horarioModal.id && index != horarioIndex) {
                                horariosOfertasAulasAux[index].horarios_aulas[aulaIndex] = { ...horariosOfertasAulasAux[index].horarios_aulas[aulaIndex], ...horario };
                                horariosOfertasAulasAux[index].horarios_aulas = [...horariosOfertasAulasAux[index].horarios_aulas];
                            }
                        })
                    }
                } else {
                    var horario_aula = horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex];
                    const configuracoesRodizio = horario_aula.servico.servico_ciclo?.[0]?.ciclo?.configuracao_rodizio;
                    horariosOfertasAulasAux.find((el, index) => {
                        if (el.horarios_aulas[0]?.horario_oferta_perido_id == horarioModal.id && index != horarioIndex) {
                            horariosOfertasAulasAux[index].horarios_aulas[aulaIndex] = { ...horariosOfertasAulasAux[index].horarios_aulas[aulaIndex], ...horario };
                            horariosOfertasAulasAux[index].horarios_aulas = [...horariosOfertasAulasAux[index].horarios_aulas];
                        }
                    })
                }
                // setHorariosDefinidos(prev => horariosOfertasAulasAux); //REMOVENDO PQ QUEBROU OUTRA COISA
                setHorariosOfertas(prev => horariosOfertasAulasAux);
                setHorarioModal(prev => horariosOfertasAulasAux[horarioIndex])
            }
        }).catch(error => {
            console.log(error)
            if (error.response.data.erro) {
                const msg = JoinMsgError(error.response.data.erro)
                ativar(400, msg);
            } else {
                ativar("adicionar", "error");
            }
        }).finally(() => {
            setBloquearBotao(false)
            setModalCritica(false);
            setModalAssociarEstaAberto(false);
            setIsLoading(false);
            setDados(null);
            setDadosCritica(null);
        });
    };
    const confirmaDeletarHorarioModal = (horario) =>
        ativarCofirmacao({
            titulo: 'Deletar',
            mensagem: 'Deseja deletar, essa operação não pode ser desfeita?',
            callback: () => deletarHorarioModal(horario)
        })

    const deletarHorarioModal = (horario) => {
        setIsLoading(true);
        axiosApi({
            method: "delete",
            url: `${url}/aulas-por-atividades/${horario.id}`,
        }).then(resp => {


            const horariosOfertasAulasAux = horariosOfertas.slice();
            const horarioIndex = horariosOfertas.findIndex(ho => ho.id === horario.horario_oferta_perido_id);
            const aulaIndex = horariosOfertasAulasAux[horarioIndex].horarios_aulas.findIndex(ho => ho.id === horario.id);
            if (horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex].aula != null) {
                const aulaMais2H = horariosOfertasAulasAux[horarioIndex].horarios_aulas[aulaIndex].aula.duracao_minima > 2;
                horariosOfertasAulasAux[horarioIndex].horarios_aulas.splice(aulaIndex, 1);
                if (aulaMais2H) {
                    horariosOfertasAulasAux.find((el, index) => {
                        if (el.horarios_aulas[0]?.horario_oferta_perido_id == horarioModal.id && index != horarioIndex) {
                            horariosOfertasAulasAux[index].horarios_aulas.splice(aulaIndex, 1);
                        }
                    })
                }
            } else {
                horariosOfertasAulasAux[horarioIndex].horarios_aulas.splice(aulaIndex, 1);
                horariosOfertasAulasAux.find((el, index) => {
                    if (el.horarios_aulas[0]?.horario_oferta_perido_id == horarioModal.id && index != horarioIndex) {
                        horariosOfertasAulasAux[index].horarios_aulas.splice(aulaIndex, 1);
                    }
                })
            }
            //setHorariosDefinidos(horariosOfertasAulasAux); REMOVENDO PQ QUEBROU OUTRA COISA
            setHorariosOfertas(() => horariosOfertasAulasAux);
        }).catch(error => {
            console.log(error)
            ativar("sucesso", "error");
        }).finally(() => {
            setIsLoading(false);
            setModalVisualizarEstaAberto(false);
        });
    }

    const handleGrupo = (grupoIds) => {
        if (grupoIds === undefined)
            setGrupoIds([]);
        else
            setGrupoIds(grupoIds);
    }

    const handleAulas = (aulaId) => {
        setAula(aulas.find(aula => aula.id === aulaId))
    }
    // const professorfiltro = () => aula?.professor.filter(elemente => elemente.includes(elemente))
    const handleProfessores = (professoresId) => {
        // const professoresDaAulaAux = professoresDaAula.slice();
        // const index = professoresDaAulaAux.indexOf(professorId);
        // if (index === -1) {
        //     professoresDaAulaAux.push(professorId);
        // } else {
        //     professoresDaAulaAux.splice(index, 1);
        // }
        setProfessoresDaAula(professoresId);
    }

    const handleObservacao = (value) => {
        if (value.length <= 255)
            setObservacao(value)
        else
            ativar(400, 'Número máximo de caracteres atingido!')
    }

    const handleLocal = (localId) => {
        setLocal(locais.find(local => local.id === localId))
    }

    const handleAgregadorGrupo = (agregadorId) => {
        setAgregadorGrupo(agregadorId);
        setGrupoIds([]);
    }

    const agregadorGruposOptions = () => {
        return [
            { value: "0", label: "Todos" },
            { value: "1", label: "Grupo 1" },
            { value: "2", label: "Grupo 2" },
            { value: "3", label: "Grupo 3" }
        ]
    };

    const exportSemanaPadraoPDF = async () => {
        const element = document.createElement('div');
        const componentHTML = ReactDOMServer.renderToString(
            <PDFSemanaPadrao
                grupos={grupos}
                abrirModalAssociar={abrirCriarModalAssociar}
                abrirModalVisualizar={abrirModalVisualizar}
                aulas={aulas}
                className="h-100"
                colClassname="calendario-padrao--comDomingoESabado"
                horariosDefinidos={horariosDefinidos}
                datasInstitucionais={datasInstitucionais}
                deletarHorario={confirmaChamaDeleta}
                deletarHorarioModal={confirmaDeletarHorarioModal}
                inicioDaSemana={0}
                finalDaSemana={6}
                horarioSelecionado={infoHorarioSelecionado}
                horariosCurso={getHorariosDoCurso()}
                tipoAtividades={tiposDeAtividadesDoTema()}
                selecionarHorario={selecionarHorario}
                horariosOfertas={horariosOfertas}
                setHorariosOfertas={setHorariosOfertas}
                éTipoOferta={true}
                aulasPDF={aulasPDF}
                semanasPDF={semanasPDF}
                mesesPDF={mesesPDF}
                periodoLetivo={periodoLetivo}
                temas={temas}
                dataUltimaAtualizacao={dataUltimaAtualizacao}
                tipoOferta={{
                    mudarSemana,
                    mudarMes,
                    semana,
                }}
                url={url}
            />);
        element.innerHTML = componentHTML;

        const options = {
            filename: `Semana Padrão - ${temas?.nome} - ${periodoLetivo?.descricao}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: [297, 210], orientation: 'landscape', autoAddPage: false },
        };

        html2pdf()
            .set(options)
            .from(element)
            .outputPdf(pdf => {
                // Após a renderização do conteúdo, adiciona manualmente uma quebra de página
                pdf.addPage();
            })
            .save();
    };

    const agregadorGruposValue = () =>
        agregadorGruposOptions().find(elemento => elemento.value === agregadorGrupo.toString());

    return (
        <NewLoader isActive={isLoading} overlay >
            <Row>
                <Col xs={12}>
                    <CardCalendarioPadrao
                        bloquearBotao={bloquearBotao}
                        grupos={grupos}
                        abrirModalAssociar={abrirCriarModalAssociar}
                        abrirModalVisualizar={abrirModalVisualizar}
                        aulas={aulas}
                        turma={turma}
                        className="h-100"
                        colClassname="calendario-padrao--comDomingoESabado"
                        horariosDefinidos={horariosDefinidos}
                        datasInstitucionais={datasInstitucionais}
                        deletarHorario={confirmaChamaDeleta}
                        deletarHorarioModal={confirmaDeletarHorarioModal}
                        inicioDaSemana={0}
                        finalDaSemana={6}
                        horarioSelecionado={infoHorarioSelecionado}
                        horariosCurso={getHorariosDoCurso()}
                        tipoAtividades={tiposDeAtividadesDoTema()}
                        selecionarHorario={selecionarHorario}
                        horariosOfertas={horariosOfertas}
                        setHorariosOfertas={setHorariosOfertas}
                        éTipoOferta={true}
                        tipoOferta={{
                            mudarSemana,
                            mudarMes,
                            semana,
                        }}
                        url={url}
                        exportSemanaPadraoPDF={exportSemanaPadraoPDF}
                        periodoLetivo={periodoLetivo}
                        semestreId={semestre_id}
                        temas={temas}
                        setModalCritica={setModalCritica}
                        setDadosCritica={setDadosCritica}
                        dadosCritica={dadosCritica}
                        criticaDnD={criticaDnD}
                    />
                </Col>
                <Col sm={12} md={4} className="mt-4">
                    <CardInfoCalendarioOfertas
                        horarioSelecionado={infoHorarioSelecionado}
                        tema={temas}
                        periodoLetivo={periodoLetivo}
                    />
                </Col>
                <Col sm={12} md={8} className="mt-4">
                    <CardAddAtividadePadrao
                        grupos={grupos}
                        infoHorarioSelecionado={infoHorarioSelecionado}
                        ofertaPeriodo={ofertasPeriodos}
                        datasInstitucionais={datasInstitucionais}
                        salvarHorario={salvarHorario}
                        onChangeHorario={onChangeHorario}
                        horariosCurso={getHorariosDoCurso()}
                        tipoAtividades={tiposDeAtividadesDoTema()}
                        inicioDaSemana={0}
                        finalDaSemana={6}
                        tipoOferta={true}
                        temas={temas}
                    />
                </Col>
            </Row>
            <ModalCritica
                isOpen={modalCritica}
                dados={dadosCritica}
                infoExtra={dados}
                vinculaHorarioEAula={vinculaHorarioEAula}
                submitAulaDnD={submitAulaDnD}
                fecharModalCritica={fecharModalCritica}
            />
            <ModalConteudo
                isOpen={modalConteudo}
                id={provaId}
                setModalConteudo={setModalConteudo}
            />
            <ModalAssociar
                setBloquearBotao={setBloquearBotao}
                bloquearBotao={bloquearBotao}
                aula={aula}
                grupoIds={grupoIds}
                agregadorGrupo={agregadorGrupo}
                aulas={aulas}
                professores={professoresDaAula}
                modalAssociarEstaAberto={modalAssociarEstaAberto}
                Verificacao={vinculaHorarioEAula}
                updateServico={updateServico}
                criticar={criticar}
                grupos={grupos}
                inicio={inicio}
                observacao={observacao}
                horarioModal={horarioModal}
                tipoAtividades={tiposDeAtividadesDoTema()}
                servico={servico}
                setServico={setServico}
                horarioAulaId={horarioAulaId}

                handleAgregadorGrupo={handleAgregadorGrupo}
                handleGrupo={handleGrupo}
                handleAulas={handleAulas}
                handleLocal={handleLocal}
                handleProfessores={handleProfessores}
                handleObservacao={handleObservacao}

                setAula={setAula}
                setHorarioModal={setHorarioModal}
                setObservacao={setObservacao}

                local={local}
                locais={locais}
                setInicio={setInicio}

                setModalAssociarEstaAberto={setModalAssociarEstaAberto}
                agregadorGruposOptions={agregadorGruposOptions}
                agregadorGruposValue={agregadorGruposValue}
                ativarAlert={ativar}
            />
            <ModalVisualizar
                horarios_aulas={horarioModal?.horarios_aulas}
                modalVisualizarEstaAberto={modalVisualizarEstaAberto}
                setModalVisualizarEstaAberto={setModalVisualizarEstaAberto}
                grupos={grupos}
                tiposDeAtividades={tiposDeAtividades}
                grupoIds={grupoIds}
                agregadorGruposValue={agregadorGruposValue}
                deletarHorario={confirmaDeletarHorarioModal}
                abrirModalAssociar={abrirEditarModalAssociar}
                modalCancelarAula={modalCancelarAula}
                abrirModalConteudo={abrirModalConteudo}
            />
            <ModalCancelarAula
                modalCancelar={modalCancelar}
                setModalCancelar={setModalCancelar}
                submitCancelarAula={submitCancelarAula}
                titulo={'Cancelar Aula'}
                mensagem={'Deseja cancelar aula: '}
            />
        </NewLoader>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(ativarModalConfirm(obj))
    }
}

export default connect(null, mapDispatchToProps)(CalendarioOfertaPeriodoLetivo);
