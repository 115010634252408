
import React from 'react'
import { Col } from 'reactstrap';
import PropTypes from 'prop-types'
import { formatarValor } from '../funcoes'
import { FiMap } from "react-icons/fi";
import { GoChecklist } from "react-icons/go";

const CampoGenerico = ({dados, formatter, params, name, label, onClick, col, definirOnclick, icon, url, metodo}) => {
    const definirValor = () => {
        if (icon) {
            switch (icon) {
                case "mapa":
                    return <FiMap color="var(--cor-text-secundario)" size="14px" style={{ cursor: 'pointer' }}/>
                case "checklist":
                    return <GoChecklist color="var(--cor-text-secundario)" size="14px" style={{ cursor: 'pointer' }}/>
                default:
                    return <span>{formatarValor(dados[name], formatter)}</span>;
            }
        }

        return <span>{formatarValor(dados[name], formatter)}</span>;
    }

    return (
        <Col {...col} onClick={() => definirOnclick({onClick, params, dados, url, metodo})}>
            {label && <b>{`${label}: `}</b>} {definirValor()}
        </Col>
    )
}

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

CampoGenerico.defaultProps = {
    params: [],
    definirOnclick: () => (undefined),
    col: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        className: "campo-generico"
    }
};

CampoGenerico.propTypes = {
    label: PropTypes.string,
    params: PropTypes.array,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.string,
    formatter: PropTypes.string,
    col: PropTypes.shape(colShape)
}

export default CampoGenerico
