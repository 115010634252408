import React from 'react'
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";

const PopOverObservacao = ({ id, observacao }) => {
    return (
        <UncontrolledPopover trigger="hover" placement="top" target={`pop2${id}`} style={{ width: '360px' }}>
            <PopoverHeader >Observação</PopoverHeader>
            <PopoverBody>
                <ul>
                    {observacao}
                </ul>
            </PopoverBody>
        </UncontrolledPopover >
    )
}

export default React.memo(PopOverObservacao);