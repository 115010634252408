import React, { useState } from 'react'
import ModalHoc from "../Hoc/modalHoc"
import { connect } from 'react-redux'
import * as actionsRedux from '../Store/Actions/index'
import { HeaderModal, FiltroConsulta, NewLoader } from '../Basicos';
import { Button, Row } from 'reactstrap';
import { axiosApi } from '../Basicos/axiosInstances';
import FileServer from 'file-saver';
import { AiFillFileExcel, AiFillFilePdf } from 'react-icons/ai';

function Modal(props) {
    const { estrutura, toggle, children } = props;
    const { header, exportacao, alunos, grupo, aulas, professores, semanaOfertas, semanaPadrao, alteracoesSemanaPadrao } = estrutura.modal;
    const [isLoading, setIsLoading] = useState(false);

    const dataEmpty = (alunos?.length === 0 || alunos?.length === undefined) && (grupo?.length === 0 || grupo?.length === undefined) && (aulas?.length === 0 || aulas?.length === undefined) && (professores?.length === 0 || professores?.length === undefined) && (semanaOfertas?.aulas?.length === 0 || semanaOfertas?.aulas?.length === undefined) && (semanaPadrao?.semanapadrao?.horarios?.length === 0 || semanaPadrao?.semanapadrao?.horarios?.length === undefined) && (alteracoesSemanaPadrao?.length === 0 || alteracoesSemanaPadrao?.length === undefined);

    const getExportDownload = async (format_file) => {
        try {
            setIsLoading(true);
            await axiosApi.post(`exportacao/${format_file}/${exportacao?.requestExportacao}`, exportacao?.paramsExport, {
                responseType: 'blob',
            }).then((res) => {
                const fileName = `${exportacao?.fileName}.${format_file}`;
                const file = new File([res.data], fileName, { type: `${res?.data?.Blob?.type};charset=utf-8` });
                FileServer.saveAs(file);
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }
    
    return (
        <NewLoader isActive={isLoading} overlay >
            <Row noGutters={true}>
                <HeaderModal
                    {...header}
                    sm={12}
                    toggle={toggle}
                    colClassName="modalConsulta__menu"
                />
                <FiltroConsulta
                    gerarConsulta={(params) => props.gerarConsulta(params, props.indice)}
                    estrutura={props.subFuncionalidades.estrutura}
                    params={props.paramsConsulta}
                // {...filtro}
                />
                {children}
                {(exportacao && !dataEmpty) && (
                    <div className="align-content-buttons_consulta">
                        {exportacao.allowedFiles.includes('xlsx') ?
                            <Button
                                className="float-left export-btnConsulta"
                                size="sm"
                                onClick={() => getExportDownload('xlsx')}
                            >
                                <AiFillFileExcel size="1.2rem" style={{ padding: '1px' }} />
                                Exportar XLSX
                            </Button> : ''}
                        {exportacao.allowedFiles.includes('pdf') ?
                            <Button
                                className="float-rigth export-btnConsulta"
                                onClick={() => getExportDownload('pdf')}
                                size="sm"
                            >
                                <AiFillFilePdf size="1.2rem" style={{ padding: '1px' }} />
                                Exportar PDF
                            </Button> : ''}
                    </div>
                )}

            </Row>
        </NewLoader>

    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const consulta = (params, indice) => dispatch(actionsRedux.ativarModalSubFuncionalidade("consultas", indice, 'relatorio', null, params));
    return {
        gerarConsulta: consulta,
        atualizarFiltroRemotamente: () => dispatch(actionsRedux.buscarFiltrosSubFuncionalidade(ownProps.funcionalidade, ownProps.indice)),
        exportar: (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params)), ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        desativar: () => dispatch(actionsRedux.desativarAlert())
    }
}

const mapStateToProps = (state) => {
    return {
        indice: state.subFuncionalidades.indiceSubModal,
        subFuncionalidades: state.subFuncionalidades.consultas[state.subFuncionalidades.indiceSubModal],
        paramsConsulta: state.subFuncionalidades.paramsConsulta
    }
}

const ModalConsulta = ModalHoc(React.memo(Modal));
export default connect(mapStateToProps, mapDispatchToProps)(ModalConsulta);