import * as tiposActions from './tiposActions'
import { convertToSlug } from '../../Basicos/funcoes'
import { axiosApi } from '../../Basicos/axiosInstances'
import * as actionsRedux from './index'

export const atualizarSubFuncionalidade = (funcionalidade, indiceSub, params = undefined) => {
    return (dispatch, getState) => {
        const { subFuncionalidades } = getState();
        var subFuncionalidade = '';
        if (funcionalidade == "agenda")
            subFuncionalidade = subFuncionalidades["semana-padrao"][indiceSub];
        else
            subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        axiosApi.get(subFuncionalidade.url, { params: params })
            .then((response) => {
                dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, {
                    ...response.data,
                }));
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    dispatch(actionsRedux.ativarAlert(400, error?.response?.data?.mensagem))
                }
            });
    }
}

export const buscarFiltrosSubFuncionalidade = (funcionalidade, indiceSub, params = undefined) => {
    return (dispatch, getState) => {
        const { subFuncionalidades } = getState();
        const subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        axiosApi.get('/subfuncionalidade/filtros', { params: { requisicaoURL: subFuncionalidade.url, ...params } })
            .then((response) => {
                dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, response.data));
            })
            .catch((error) => {
                // console.log(error);
            })
    }
}

export const removerSubFuncionalidade = (funcionalidade, indiceSub, remover_id) => {
    return (dispatch, getState) => {
        const { subFuncionalidades } = getState();
        const subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        let dados = subFuncionalidade.dados.slice();
        axiosApi.delete(subFuncionalidade.url + '/' + remover_id).then(response => {
            for (let x = 0; x < dados.length; x++) {
                if (String(dados[x].id) === String(remover_id)) {
                    if (dados[x].status && response.data.model) {
                        dados[x] = response.data.model
                    } else {
                        dados.splice(x, 1);
                    }
                    dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { dados }, 'remover'));
                    dispatch(actionsRedux.ativarAlert("inativo", "sucesso"));
                    break;
                }
            }
        }).catch(error => {
            dispatch(actionsRedux.ativarAlert("inativo", "erro"))
        })
    }
}

export const adicionarSubFuncionalidade = (funcionalidade, indiceSub, objeto) => {
    return (dispatch, getState) => {
        const { subFuncionalidades } = getState();
        const subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        if (Array.isArray(subFuncionalidade.dados)) {
            let dados = subFuncionalidade.dados.slice();
            dados.unshift(objeto)
            // if (funcionalidade == 'mensagens') 
            // else dados.push(objeto);
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { dados }));
        } else if (funcionalidade == 'eventos-e-noticias') {
            let dados = [];
            dados[0] = objeto;
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { dados }));
        }
    }
}

export const editarSubFuncionalidade = (funcionalidade, indiceSub, objeto) => {
    return (dispatch, getState) => {
        const { subFuncionalidades } = getState();
        const subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        if (subFuncionalidade.dados) {
            let dados = subFuncionalidade.dados.slice();
            for (let x = 0; x < dados.length; x++) {
                if (String(dados[0].id) === String(objeto.id)) {
                    dados[0] = objeto;
                    dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { dados }));
                    break;
                }
            }
        } else if (funcionalidade == 'eventos-e-noticias') {
            let dados = [];
            dados[0] = objeto;
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { dados }));
        }
    }
}

export const ativarModalSubFuncionalidade = (funcionalidade, indiceSub, tipo, id, params = undefined) => {
    return (dispatch, getState) => {
        dispatch(toggleLoader(true));
        const { subFuncionalidades } = getState();

        const subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        let requisicaoURL = subFuncionalidade.url;
        switch (tipo) {
            case 'adicionar':
                requisicaoURL += '/create';
                break;
            case 'editar':
                requisicaoURL += '/' + id + '/edit';
                break;
            default:
                break
        }

        axiosApi.get(requisicaoURL, { params })
            .then((response) => {
                if (response.data.modal) {
                    dispatch(atualizarModalSubFuncionalidade({
                        editar: (objeto) => dispatch(actionsRedux.editarSubFuncionalidade(funcionalidade, indiceSub, objeto)),
                        adicionar: (objeto) => dispatch(actionsRedux.adicionarSubFuncionalidade(funcionalidade, indiceSub, objeto)),
                        estruturaModal: response.data,
                        indiceSubModal: indiceSub,
                        subfuncionalidadeRef: subFuncionalidade,
                        paramsConsulta: params,
                        tipoAcao: tipo,
                        estadoModal: true
                    }));
                } else {
                    dispatch(actionsRedux.ativarAlert("modal", "sucesso"));
                }
            })
            .catch((error) => {
                dispatch(actionsRedux.ativarAlert("modal", "erro"));
            })
            .finally(() => {
                dispatch(toggleLoader(false));
            });
    }
}

export const exportarSubfuncionalidade = (funcionalidade, indiceSub, params = undefined) => {
    return (dispatch, getState) => {
        const { subFuncionalidades } = getState();
        const subFuncionalidade = subFuncionalidades[funcionalidade][indiceSub];
        dispatch(toggleLoader(true));
        axiosApi.post(subFuncionalidade.url, params)
            .then((response) => {
                dispatch(toggleLoader(false));
                dispatch(actionsRedux.ativarAlert("modal", "sucesso"))
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                dispatch(actionsRedux.ativarAlert("modal", "erro"))
            });
    }
}

export const buscarValoresPaginacao = (funcionalidade, indiceSub, url, params, metodo = "GET") => {
    return async (dispatch) => {
        try {
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { tabelaLoader: true }));
            const response = await axiosApi({ method: metodo, url: url, params: params })
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, {
                dados: response.data.dadosTabela,
                quantidadeDePaginas: response.data.quantidadeDePaginas,
                tabelaLoader: false
            }));
        } catch (error) {
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { tabelaLoader: false }));
        }
    }
}

export const desativarModalSubFuncionalidade = () => {
    return dispatch => {
        dispatch(toggleModal(false));
    }
}

export const toggleModal = (estado) => {
    return {
        type: tiposActions.TOGGLE_MODAL_SUBFUNCIONALIDADE,
        dados: { estadoModal: estado }
    }
}

export const toggleLoader = (estado) => {
    return {
        type: tiposActions.TOGGLE_LOADER_SUBFUNCIONALIDADE,
        dados: { loader: estado }
    }
}

export const inicializarSubFuncionalidade = (funcionalidades) => {
    const dados = { navsConsulta: { itens: [] } };
    // console.log(funcionalidades)
    funcionalidades.forEach(({ url, descricao, subfuncionalidades = [] }) => {
        const funcionalidade = convertToSlug(descricao);
        // if (funcionalidade !== "empresas") {
        dados[funcionalidade] = subfuncionalidades.map((subfuncionalidade) => {
            // console.log(subfuncionalidade)
            return {
                dados: undefined,
                loader: undefined,
                url: url + subfuncionalidade.url,
                descricao: subfuncionalidade.descricao,
                campos: JSON.parse(subfuncionalidade.nivel_por_subfuncionalidade.campos_custom),
                filtros: JSON.parse(subfuncionalidade.nivel_por_subfuncionalidade.filtros_custom),
                acoes: JSON.parse(subfuncionalidade.nivel_por_subfuncionalidade.acoes_custom),
            }
        });
        // }
    });
    return {
        type: tiposActions.INCIALIZAR_SUBFUNCIONALIDADE,
        dados
    }
}

export const atualizarModalSubFuncionalidade = (dados) => {
    return {
        type: tiposActions.ATUALIZAR_MODAL_SUBFUNCIONALIDADE,
        modal: dados
    }
}

export const carregarSubFuncionalidade = (funcionalidade, indice, dados, acao) => {
    return {
        type: tiposActions.ATUALIZAR_SUBFUNCIONALIDADE,
        indice,
        funcionalidade,
        dados,
        acao
    }
}

export const pegaNoticias = (funcionalidade, indiceSub) => {
    return async (dispatch) => {
        try {
            const response = await axiosApi({
                method: 'GET',
                url: '/eventos-noticias',
            })
            dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, {
                dados: response.data
            }));
        } catch (error) {
            //dispatch(carregarSubFuncionalidade(funcionalidade, indiceSub, { tabelaLoader: false }));
        }
    }
}
