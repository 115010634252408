import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Label, FormGroup, FormFeedback } from "reactstrap";
import PropTypes from 'prop-types';
import uploadIcon from "../../images/ic_pdf.png";
import imagem from "../../images/pdf.png"
import MenuTable from '../../Menu/menuTable';

import { TiDocument } from 'react-icons/ti';

//comparar tamanho do array files , ver quantos falta e dar um slice no array accept, caso esteja lotado manda o aviso
function FileExportFormPDF({ 
    accept,
    placeholder,
    style, 
    label, 
    preview, 
    valorAtual,
    onChange, 
    name, 
    limiteDeArquivos,
    minSize, 
    maxSize 
}) {

    const files = valorAtual;

    const onDrop = (acceptedFiles) => {
        let quantidadeRestante = limiteDeArquivos - files.length;
        onChange(name, [...files, ...acceptedFiles.slice(0, quantidadeRestante)]);
    }

    // const definePDFURL = (file) => file instanceof File ? URL.createObjectURL(file) : file.url;
    const isPDF = (file) => file instanceof File ? file.type.includes('pdf') : file.tipo_mime.includes('pdf');

    const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
        accept: accept,
        maxSize: maxSize,
        minSize: minSize,
        onDrop
    });

    const aceitos = files.map((file, indice) => {
        
        const removerOnClick = () => {
            const auxFiles = files.slice();
            auxFiles.splice(indice, 1);
            onChange(name, auxFiles); 
        }

        return (
            <div key={indice} style={{
                display: "flex",
                flexDirection: "row",
                margin: "8px",
            }}>
           
                {(preview && isPDF(file)) ? (
                    <img src={imagem} className="pdf-file" />
                    // <iframe src={definePDFURL(file)} className="img-file"/>

                ) : (
                    <TiDocument className="menuIcons pdf-file"/> 
                )}
                 
                <span style={{ flex: 1, margin: "0 10px 0 10px", lineHeight: "60px" }}>
                    { file instanceof File ? file.name : file.nome }
                </span>
                <MenuTable
                    alinharDireita
                    iconRemover
                    removerOnClick={removerOnClick}
                />
            </div>
        )
    });

    const rejeitados = rejectedFiles.map((file, indice) => (
        <FormFeedback style={{ display: "block" }} key={indice}>
            {`Arquivo ${file instanceof File ? file.name : file.nome} é inválido verifique o tamanho ou nome!`}
        </FormFeedback>
    ));

    return (
        <Col {...style.col} className="form-col">
            <FormGroup className="form-group">
            {label && (
                <Label className={style.labelClass + " label"} >
                    {label + ":"}
                </Label>
            )}
            
                {aceitos}
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <img src={uploadIcon} width="150" height="150" />
                    <p className="m-0" >Arraste e solte o PDF ou clique para selecionar o arquivo para upload</p>
                    {placeholder && <em> {placeholder} </em>}
                </div>
                {rejeitados}
            </FormGroup>
        </Col>

    );
}

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

FileExportFormPDF.defaultProps = {
    mask: undefined,
    preview: true,
    valorAtual: [],
    limiteDeArquivos: 1000,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};

FileExportFormPDF.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}

export default FileExportFormPDF;