import React from 'react'
function Auxiliares({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
            version="1.0"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 65 65"
            style={style}
            className={className}
        >
            <g
                fill={color}
                stroke="none"
            >
                <path d="M 54.125 14.675781 C 53.292969 11.777344 50.621094 9.648438 47.457031 9.648438 C 44.296875 9.648438 41.625 11.777344 40.792969 14.675781 L 0 14.675781 L 0 18.484375 L 40.792969 18.484375 C 41.625 21.382812 44.296875 23.515625 47.457031 23.515625 C 50.621094 23.515625 53.292969 21.382812 54.125 18.484375 L 65 18.484375 L 65 14.675781 Z M 47.457031 19.707031 C 45.734375 19.707031 44.335938 18.304688 44.335938 16.582031 C 44.335938 14.859375 45.738281 13.457031 47.457031 13.457031 C 49.179688 13.457031 50.582031 14.859375 50.582031 16.582031 C 50.582031 18.304688 49.179688 19.707031 47.457031 19.707031 Z M 47.457031 19.707031 " />
                <path d="M 12.207031 25.578125 C 9.042969 25.578125 6.371094 27.707031 5.542969 30.605469 L 0 30.605469 L 0 34.414062 L 5.542969 34.414062 C 6.371094 37.316406 9.042969 39.445312 12.207031 39.445312 C 15.371094 39.445312 18.042969 37.316406 18.871094 34.414062 L 65 34.414062 L 65 30.605469 L 18.871094 30.605469 C 18.042969 27.707031 15.371094 25.578125 12.207031 25.578125 Z M 12.207031 35.636719 C 10.484375 35.636719 9.082031 34.234375 9.082031 32.511719 C 9.082031 30.789062 10.484375 29.386719 12.207031 29.386719 C 13.929688 29.386719 15.332031 30.789062 15.332031 32.511719 C 15.332031 34.234375 13.929688 35.636719 12.207031 35.636719 Z M 12.207031 35.636719 " />
                <path d="M 33.566406 41.507812 C 30.40625 41.507812 27.730469 43.636719 26.902344 46.539062 L 0 46.539062 L 0 50.347656 L 26.902344 50.347656 C 27.730469 53.246094 30.40625 55.375 33.566406 55.375 C 36.730469 55.375 39.402344 53.246094 40.230469 50.347656 L 65 50.347656 L 65 46.539062 L 40.230469 46.539062 C 39.402344 43.636719 36.730469 41.507812 33.566406 41.507812 Z M 33.566406 51.566406 C 31.84375 51.566406 30.445312 50.164062 30.445312 48.441406 C 30.445312 46.71875 31.84375 45.316406 33.566406 45.316406 C 35.289062 45.316406 36.691406 46.71875 36.691406 48.441406 C 36.691406 50.164062 35.289062 51.566406 33.566406 51.566406 Z M 33.566406 51.566406 " />
            </g>
        </svg>
    );
};
export default Auxiliares;





