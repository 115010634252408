export const primeiraImagemDeUmaNoticia = (noticia) => {
    if (noticiaTemImagem(noticia))
        return noticia.imagem[0].url ? noticia.imagem[0].url : URL_IMAGEM_PADRAO;
    return URL_IMAGEM_PADRAO;
}

const URL_IMAGEM_PADRAO = process.env.REACT_APP_NOTICIA_BASE_URL;

const noticiaTemImagem = (noticia) => {
    if (noticia.imagem.length === 0)
        return false;
    return true;
}