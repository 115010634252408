import React, { Fragment, useState } from 'react';
import { Col, Button, ButtonGroup, Modal, ModalBody, Form, ModalHeader } from "reactstrap";
import CardHoc from "../../Hoc/cardHoc";
import DayPicker from 'react-day-picker';
import { ContextMenuTrigger, ContextMenu } from 'react-contextmenu';
import { pt_br, formatLaravelDate, formatDate } from '../../Basicos/funcoes';
import {InputForm} from '../../Basicos/index';
import { set } from 'date-fns/esm';

function CardCalendario({
    selectedDays,
    onDayClick,
    deletarOnClick = () => { },
    datasEstrutura = {},
    tipoDeDatas = [],
    legenda,
    tipoDataselecionado,
    setTipoDataselecionado = () => { },
    setFimModal,
    fimModal,
    setSugestao,
    sugestao,
    button_day,
    setDiaLetivos,
    diasLetivos,
    setDiasLetivos,
    calcular,
    setSugestoes, 
    sugestoes, 
    diaContado, 
    mesContado, 
    anoContado,
    salvarsugestao,
    salvarOnClick = () => { },
}) {
    const pegaCorDoTipoDeDatasPorId = id => tipoDeDatas.find(tipo => tipo.id === id).cor;
    const datasEstruturaArray = Object.entries(datasEstrutura);
   
    const modifiersStyles = {};


    const modifiers = {};
    datasEstruturaArray.forEach(([key, value]) => {
        modifiersStyles[key] = {
            color: "white",
            backgroundColor: pegaCorDoTipoDeDatasPorId(value.tipo_de_data_id)
        }

        modifiers[key] = value.data;
    });
const onChange = (nome, valor) => {
    setDiasLetivos(valor)
}
    const renderDay = data =>
        <Dia
            data={data}
            legenda={legenda}
            datasEstrutura={datasEstrutura}
            deletarOnClick={deletarOnClick}
        />
    return (
        <Fragment>
            <Col>
                <DayPicker
                    showOutsideDays
                    locale={'pt-br'}
                    selectedDays={selectedDays}//{salvarOnClick(tipoDataselecionado?.id, selectedDays)}
                    onDayClick={onDayClick}
                    style={{ width: '100%' }}
                    renderDay={renderDay}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                    {...pt_br}
                />
            </Col>

            {button_day && (
                <Col className="tipos-marcadores">
                    <Col className="tipos-marcadores">

                        <hr />
                        {

                            tipoDeDatas.map((tipoData, indice) =>
                                (
                                    (tipoData.id !== 3 && tipoData.id !== 2) ?
                                        <ButtonGroup>
                                            <button onClick={() => setTipoDataselecionado(tipoData)} className={"tipo-marcador-button btn-outline-info"} active={tipoDataselecionado === tipoData} style={{ backgroundColor: tipoData.cor }} key={indice}>
                                                {tipoData.descricao}
                                            </button>
                                        </ButtonGroup>


                                        : null

                                ))

                        }

                        {<button className={"tipo-fim-button btn-outline-fim"} onClick={() => setFimModal(true)}>Fim de Semestre</button>}
                    </Col>
                    <Col className="tipos-marcadores-salve">
                        <h8>Marcador Selecionado: </h8>
                        {<button className={"tipo-info-button btn-outline-info"} onClick={() => setTipoDataselecionado(null)} style={{ color: tipoDataselecionado?.cor }}>{tipoDataselecionado ? tipoDataselecionado?.descricao : "Sem Marcador"}</button>}
                    </Col>
                    <Col className="tipos-marcadores-salve">


                    </Col>
                </Col>
            )}
            <Modal isOpen={fimModal}>
                <ModalHeader>
                    <h4 className={"text-fim-semesestre"}>Fim do Semestre</h4>
                </ModalHeader>
                <ModalBody>

                    <p className={"texto-p"}>O fim do semestre pode ser definido:</p>
                    <p className={"texto-aviso"}>OBS: marcar todas as provas e sábados letivos antes de gerar a data automatica </p>

                    <Col sm={10} className={'alinhamento-button'}>
                        {<button className={"font-weight-bold btn btn-padrao"} onClick={() => { setSugestao(true, setFimModal(false)) }}>Automaticamente</button>}
                    </Col>
                    <Col sm={10} className={'alinhamento-button'}>
                        {<button className={" font-weight-bold btn btn-padrao"} onClick={() => { setTipoDataselecionado(tipoDeDatas[1], setFimModal(false)) }}>Manualmente</button>}


                    </Col>

                </ModalBody>

            </Modal>
            <Modal isOpen={sugestao}>
                <ModalHeader>
                    <h4 className={"text-fim-semesestre"}>Fim do Semestre</h4>
                </ModalHeader>
                <ModalBody>

                <Form >
                            <InputForm
                                type="number"
                                name="dias"
                                label="Quantos dias letivos terá o semestre"
                                onChange={onChange}
                            />

                            <Col className="align-content-middle">
                                <Button className="mx-3" onClick={() => setSugestao(false)}>Cancelar</Button>
                                <Button className="mx-3" onClick={()=>setSugestao(false, calcular())}>Calcular</Button>
                            </Col>

                        </Form>
                </ModalBody>
            </Modal>
            <Modal isOpen={sugestoes}>
                <ModalHeader>
                    <h4 className={"text-fim-semesestre"}>Fim do Semestre</h4>
                </ModalHeader>
                <ModalBody>


                    <p className={"texto-p"}>O fim do semestre será : </p>
                    <p className={"texto-data"}>{diaContado} de {mesContado} de {anoContado}</p>
                    <Col sm={10} className={'alinhamento-button'}>
                        {<button className={"font-weight-bold btn btn-padrao"} onClick={() => { setSugestoes(false, salvarsugestao())}}>Aceitar Sugestão</button>}
                    </Col>
                    <Col sm={10} className={'alinhamento-button'}>
                        {<button className={" font-weight-bold btn btn-padrao"} onClick={() => { setTipoDataselecionado(tipoDeDatas[1], setSugestoes(false))}}>Marcar Manualmente</button>}
                    </Col>
                    <Col sm={10} className={'alinhamento-button'}>
                        {<button className={" font-weight-bold btn btn-padrao"} onClick={() => {setSugestoes(false)}}>Cancelar</button>}
                    </Col>

         
                </ModalBody>
            </Modal>

        </Fragment>
    );
}

export default CardHoc(CardCalendario);


function Dia({ data, legenda, datasEstrutura, deletarOnClick }) {
    const dataKey = formatDate(data, formatLaravelDate);
    const dataNaoEstaNaEstrutura = !Object.keys(datasEstrutura).includes(dataKey);

    if (legenda || dataNaoEstaNaEstrutura)
        return <NomeExibidoPorDia data={data} />;

    return <DiaComContextMenu data={data} dataKey={dataKey} deletarOnClick={deletarOnClick} />
}
function DiaComContextMenu({ data, dataKey, deletarOnClick }) {
    const attributes = {
        className: `align-content-middle`,
        style: { flex: "1 1 0px", height: '100%' },
    };

    return (
        <Fragment>
            <ContextMenuTrigger
                id={dataKey}
                attributes={attributes}
            >
                <NomeExibidoPorDia data={data} />
            </ContextMenuTrigger>

            <ContextMenu
                id={dataKey}
                className="horarioCalendario__contextMenu zindex-tooltip shadow-lg"
            >
                <Button onClick={() => deletarOnClick(data)}>
                    Deletar 
				</Button>
            </ContextMenu>
        </Fragment>
    );
}

function NomeExibidoPorDia({ data }) {
    return (
        <span className="dia-calendario-principal">{data.getDate()}</span>
    );
}