import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Nav, TabContent, TabPane, Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actionsRedux from '../../../Store/Actions/index';
import InputForm from '../../Form/InputForm';
import SelectForm from '../../Form/SelectForm';
import { axiosApi } from '../../axiosInstances';
import './style.css';
import NavItemCustom from '../../navItem';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import NewLoader from '../../NewLoader';

const ModalCiclo = ({
    isOpen,
    setIsOpen,
    ativar,
    setCiclos,
    cicloId
}) => {
    const campos = {
        semestre_id: 'Semestre',
        turma_id: 'Turma',
        tipo_atividade_id: 'Tipo de Atividade',
        titulo: 'Título',
    };
    const campos2 = {
        servico_id: 'Serviço',
        qtd_rodizio: 'Qtd de rodízio por aluno'
    };
    const style = {
        col4: { xs: 6, md: 4, sm: 4, lg: 4, xl: 4 },
        col6: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }
    };
    const [isLoading, setIsLoading] = useState(true);
    const [qtdCampos, setQtdCampos] = useState(1);
    const [tab, setTab] = useState(1);
    const [semestresOptions, setSemestresOptions] = useState([]);
    const [turmasOptions, setTurmasOptions] = useState([]);
    const [tiposAtividadeOptions, setTiposAtividadeOptions] = useState([]);
    const [servicosOptions, setServicosOptions] = useState([]);
    const [aulasOptions, setAulasOptions] = useState([]);
    const [dados, setDados] = useState({
        semestre_id: undefined,
        turma_id: undefined,
        tipo_atividade_id: undefined,
        titulo: undefined,
    });
    const [dadosTab2, setDadosTab2] = useState([
        {
            obrigatorio_locais: 'false',
            manter_professor: 'false'
        }
    ]);

    const toggle = () => {
        setDados({
            semestre_id: undefined,
            turma_id: undefined,
            tipo_atividade_id: undefined,
            titulo: undefined,
        });
        setDadosTab2([]);
        setServicosOptions(undefined);
        setTab(1);
        setIsOpen();
    };

    const alterTab = (value) => {
        setTab(value);
    }

    const onChange = (nome, valor, indice) => {
        switch (nome) {
            case 'semestre_id':
                setDados({ ...dados, semestre_id: valor });
                getAulas(valor);
                break;
            case 'turma_id':
                setDados({ ...dados, turma_id: valor });
                break;
            case 'tipo_atividade_id':
                setDados({ ...dados, tipo_atividade_id: valor });
                getServicos(valor);
                break;
            case 'titulo':
                setDados({ ...dados, titulo: valor });
                break;
            case 'qtd_alunos':
                setDados({ ...dados, qtd_alunos: valor });
                break;
            case 'servico_id':
                setDadosTab2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], servico_id: valor };
                    return novoEstado;
                });
                break;
            case 'qtd_rodizio':
                setDadosTab2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], qtd_rodizio: valor };
                    return novoEstado;
                });
                break;
            case 'ordem_alocacao':
                setDadosTab2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], ordem_alocacao: valor };
                    return novoEstado;
                });
                break;
            case 'pre_requisito_aula_id':
                setDadosTab2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], pre_requisito_aula_id: valor };
                    return novoEstado;
                });
                break;
            case 'manter_professor':
                setDadosTab2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], manter_professor: valor };
                    return novoEstado;
                });
                break;
            case 'obrigatorio_locais':
                setDadosTab2(prevState => {
                    const novoEstado = [...prevState];
                    novoEstado[indice] = { ...novoEstado[indice], obrigatorio_locais: valor };
                    return novoEstado;
                });
                break;
            default:
                break;
        }
    };

    const getServicos = async (tipoAtividadeId) => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/servicos-por-atividade?tipo_atividade_id=${tipoAtividadeId}`);
            setServicosOptions(data.map(element => ({ label: element.descricao, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    }

    const getAulas = async (semestreId) => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/aulas-turma-semestre?semestre_id=${semestreId}`);
            setTurmasOptions(data.turmas.map(element => ({ label: element.descricao, value: element.id })));
            setAulasOptions(data.aulas.map(element => ({ label: element.titulo, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    }

    const onChangeCampos = (evento, index) => {
        let campos = 0;
        if (evento == "mais") {
            campos = qtdCampos + 1;
            setDadosTab2(prevState => {
                const novoEstado = [...prevState];
                novoEstado[index + 1] = { ...novoEstado[index + 1], manter_professor: "false" };
                return novoEstado;
            });
            setDadosTab2(prevState => {
                const novoEstado = [...prevState];
                novoEstado[index + 1] = { ...novoEstado[index + 1], obrigatorio_locais: "false" };
                return novoEstado;
            });
        }
        if (evento == "menos") {
            if (qtdCampos == 1) {
                campos = 1;
                setDadosTab2([]);
            } else {
                campos = qtdCampos - 1;
                dadosTab2.splice(index, 1);
                setDadosTab2(dadosTab2);
            }
        }

        setQtdCampos(campos);
    }

    const getFiltros = async () => {
        try {
            if (isOpen) {
                setIsLoading(true);
                const { data } = await axiosApi.get('/auxiliares/filtros-ciclo');
                setSemestresOptions(data.semestres.map(element => ({ label: element.descricao, value: element.id })));
                setTiposAtividadeOptions(data.tipos_atividade.map(element => ({ label: element.nome, value: element.id })));

                if (cicloId != null) {
                    const { data } = await axiosApi.get(`/auxiliares/ciclo/${cicloId}`);
                    setDados({
                        semestre_id: data.dados.semestre_id,
                        turma_id: data.dados.turma_id,
                        tipo_atividade_id: data.dados.tipo_atividade_id,
                        titulo: data.dados.titulo,
                    });
                    setAulasOptions(data.aulas_pre_requisito.map(element => ({ label: element.titulo, value: element.id })));
                    setServicosOptions(data.servicos.map(element => ({ label: element.descricao, value: element.id })));
                    setDadosTab2(data.servicos_ciclo);
                    setQtdCampos(data.servicos_ciclo.length == 0 ? 1 : data.servicos_ciclo.length);
                    setTurmasOptions(data.turmas.map(element => ({ label: element.descricao, value: element.id })))
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getFiltros();
    }, [isOpen]);

    const salvar = async () => {
        let isValid = true
        for (const [campo, descricao] of Object.entries(campos)) {
            if (dados[campo] == null) {
                ativar(400, `Campo ${descricao} vazio!`);
                isValid = false;
                break;
            }
        }

        if (!isValid)
            return;

        for (const [index, item] of dadosTab2.entries()) {
            for (const [campo, descricao] of Object.entries(campos2)) {
                if (item[campo] == null) {
                    ativar(400, `O campo ${descricao} está ausente no item ${index + 1}!`);
                    isValid = false;
                    break;
                }
            }
            if (!isValid) break;
        }

        if (!isValid)
            return;

        try {
            let infos = dados;
            infos.itens = dadosTab2;
            if (cicloId != null) {
                const response = await axiosApi.put(`/auxiliares/ciclo/${cicloId}`, infos);
                ativar(200, "Ciclo editado com sucesso!");
                setCiclos(prevCiclo =>
                    prevCiclo.map(ciclo =>
                        ciclo.id === response.data.id ? response.data : ciclo
                    )
                );
            } else {
                const response = await axiosApi.post('/auxiliares/ciclo', infos);
                ativar(200, "Ciclo adicionado com sucesso!");
                setCiclos(prevCiclos => [...prevCiclos, response.data]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            toggle();
        }
    }

    return (
        <NewLoader isActive={isLoading} overlay >
            <Modal isOpen={isOpen} toggle={toggle} size='xl'>
                <ModalHeader className="menu-modal-formal">
                    <h2>
                        <b>Ciclo</b>
                    </h2>
                    <Nav tabs className="tabs-ciclo">
                        <NavItemCustom tabs classNamePai="tab-pai" classname="tabs-item-ciclo" activeTab={tab}
                            onClick={() => alterTab(1)}
                            descricao={"Geral"}
                            id={1}
                        />
                        <NavItemCustom tabs classNamePai="tab-pai" classname="tabs-item-ciclo" activeTab={tab}
                            onClick={() => alterTab(2)}
                            descricao={"Itens"}
                            id={2}
                        />
                    </Nav>
                </ModalHeader>
                <ModalBody>
                    <TabContent activeTab={tab}>
                        <TabPane tabId={1}>
                            <Table bordered striped>
                                <Row className="modal-grupos-aluno">
                                    <InputForm
                                        type="text"
                                        name="titulo"
                                        label="Título"
                                        onChange={onChange}
                                        value={dados.titulo}
                                    />
                                    <SelectForm
                                        label="Semestre"
                                        name="semestre_id"
                                        placeholder="Selecione"
                                        options={semestresOptions}
                                        value={semestresOptions?.find(option => option.value === dados.semestre_id) || ''}
                                        onChange={onChange}
                                        style={{ col: style.col6 }}
                                    />
                                    <SelectForm
                                        label="Turma"
                                        name="turma_id"
                                        placeholder="Selecione"
                                        options={turmasOptions}
                                        value={turmasOptions?.find(option => option.value === dados.turma_id) || ''}
                                        onChange={onChange}
                                        style={{ col: style.col6 }}
                                    />
                                    <SelectForm
                                        label="Tipo de Atividade"
                                        name="tipo_atividade_id"
                                        placeholder="Selecione"
                                        options={tiposAtividadeOptions}
                                        value={tiposAtividadeOptions?.find(option => option.value === dados.tipo_atividade_id) || ''}
                                        onChange={onChange}
                                    />
                                </Row>
                            </Table>
                        </TabPane>

                        <TabPane className="itens-ciclo" tabId={2}>
                            <Table bordered striped>
                                {Array.from({ length: qtdCampos }, (_, index) => (
                                    <Row className="modal-grupos-aluno modal-ciclo">
                                        <Col md={10} className="campos-itens-ciclo">
                                            <SelectForm
                                                indice={index}
                                                label="Serviço"
                                                name="servico_id"
                                                placeholder="Selecione"
                                                options={servicosOptions}
                                                value={servicosOptions?.find(option => option.value === dadosTab2?.[index]?.servico_id) || ''}
                                                onChange={onChange}
                                            />
                                            <Row className="campos-ciclo">
                                                <InputForm
                                                    required={true}
                                                    indice={index}
                                                    type="text"
                                                    name="qtd_rodizio"
                                                    label="Qtd de rodízio por aluno"
                                                    onChange={onChange}
                                                    value={dadosTab2?.[index]?.qtd_rodizio}
                                                    style={{ col: style.col4 }}
                                                />
                                                <InputForm
                                                    indice={index}
                                                    type="text"
                                                    name="ordem_alocacao"
                                                    label="Ordem de Alocação"
                                                    onChange={onChange}
                                                    value={dadosTab2?.[index]?.ordem_alocacao}
                                                    style={{ col: style.col4 }}
                                                />
                                                <SelectForm
                                                    indice={index}
                                                    label="Aula Pré-Requisito"
                                                    name="pre_requisito_aula_id"
                                                    placeholder="Selecione"
                                                    options={aulasOptions}
                                                    value={aulasOptions?.find(option => option.value === dadosTab2?.[index]?.pre_requisito_aula_id) || ''}
                                                    onChange={onChange}
                                                    style={{ col: style.col4 }}
                                                />
                                            </Row>
                                            <Row className="campos-ciclo">
                                                <Col md={6}>
                                                    <h6>Manter Mesmo Professor</h6>
                                                    <Row className="radio-ciclo">
                                                        <Col md={4}>
                                                            <input
                                                                type="radio"
                                                                name={`manter_professor_${index}`} // Nome único com o index
                                                                onChange={(e) => onChange('manter_professor', e.target.value, index)}
                                                                value="true"
                                                                checked={dadosTab2?.[index]?.manter_professor == "true" || dadosTab2?.[index]?.manter_professor == true} />{' '}Sim
                                                        </Col>
                                                        <Col md={4}>
                                                            <input
                                                                type="radio"
                                                                name={`manter_professor_${index}`} // Nome único com o index
                                                                onChange={(e) => onChange('manter_professor', e.target.value, index)}
                                                                value="false"
                                                                checked={dadosTab2?.[index]?.manter_professor == "false" || dadosTab2?.[index]?.manter_professor == false}
                                                            />{' '}Não
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <h6>Obrigatório Passar Todos os Locais do Serviço</h6>
                                                    <Row className="radio-ciclo">
                                                        <Col md={4}>
                                                            <input
                                                                type="radio"
                                                                name={`obrigatorio_locais_${index}`} // Nome único com o index
                                                                onChange={(e) => onChange('obrigatorio_locais', e.target.value, index)}
                                                                value="true"
                                                                checked={dadosTab2?.[index]?.obrigatorio_locais == "true" || dadosTab2?.[index]?.obrigatorio_locais == true} />{' '}Sim
                                                        </Col>
                                                        <Col md={4}>
                                                            <input
                                                                type="radio"
                                                                name={`obrigatorio_locais_${index}`} // Nome único com o index
                                                                onChange={(e) => onChange('obrigatorio_locais', e.target.value, index)}
                                                                value="false"
                                                                checked={dadosTab2?.[index]?.obrigatorio_locais == "false" || dadosTab2?.[index]?.obrigatorio_locais == false}
                                                            />{' '}Não
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={2} className="botoes-modal-cilo">
                                            {qtdCampos > 1 && index == 0
                                                || qtdCampos > 1 && index != qtdCampos - 1 ? "" : <FiPlus onClick={() => onChangeCampos("mais", index)} className="menuIconsReplica " />}
                                            <FiTrash2 onClick={() => onChangeCampos("menos", index)} className="menuIconsReplica" />
                                        </Col>
                                    </Row>

                                ))}
                            </Table>
                        </TabPane>
                    </TabContent>

                    <Row>
                        <Col className="align-content-middle">
                            <Button className="mx-3" onClick={() => toggle()}>Cancelar</Button>
                            <Button className="mx-3" onClick={() => salvar()}>Salvar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </NewLoader>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    };
};

export default connect(null, mapDispatchToProps)(ModalCiclo);