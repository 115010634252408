

import React from 'react'
function Locais({ style, className, width = "55px", height = "57px", color = "" }) {
	return (
		<svg
			version="1.0"
			x="0px"
			y="0px"
			width={width}
			height={height}
			viewBox="0 0 65 65"
			style={style}
			className={className}
		>
			<g
				fill={color}
				stroke="none"
			>
				<path d="M 32.5 0 C 19.519531 0 8.960938 10.558594 8.960938 23.539062 C 8.960938 39.648438 30.027344 63.296875 30.921875 64.296875 C 31.765625 65.234375 33.234375 65.234375 34.078125 64.296875 C 34.972656 63.296875 56.039062 39.648438 56.039062 23.539062 C 56.039062 10.558594 45.480469 0 32.5 0 Z M 32.5 59.632812 C 25.410156 51.210938 13.199219 34.597656 13.199219 23.539062 C 13.199219 12.898438 21.855469 4.238281 32.5 4.238281 C 43.144531 4.238281 51.800781 12.898438 51.800781 23.539062 C 51.800781 34.597656 39.589844 51.210938 32.5 59.632812 Z M 32.5 59.632812 " />
				<path d="M 32.5 11.695312 C 25.96875 11.695312 20.65625 17.011719 20.65625 23.539062 C 20.65625 30.070312 25.96875 35.382812 32.5 35.382812 C 39.03125 35.382812 44.34375 30.070312 44.34375 23.539062 C 44.34375 17.011719 39.03125 11.695312 32.5 11.695312 Z M 32.5 31.144531 C 28.308594 31.144531 24.894531 27.734375 24.894531 23.539062 C 24.894531 19.347656 28.308594 15.9375 32.5 15.9375 C 36.691406 15.9375 40.105469 19.347656 40.105469 23.539062 C 40.105469 27.734375 36.691406 31.144531 32.5 31.144531 Z M 32.5 31.144531 " />

			</g>
		</svg>
	);
};

export default Locais;
