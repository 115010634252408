
import React from 'react'

function Import({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
        version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
			height={height}
			viewBox="0 0 40 40"
			style={style}
			className={className}
            >
             <g
				fill={color}
				stroke="none"
			>
            <path d="M18.45 26L25.1591 18H20.1273V0H16.7727V18H11.7409L18.45 26ZM33.5455 0H23.4818V3.98H33.5455V32.04H3.35455V3.98H13.4182V0H3.35455C1.50955 0 0 1.8 0 4V32C0 34.2 1.50955 36 3.35455 36H33.5455C35.3905 36 36.9 34.2 36.9 32V4C36.9 1.8 35.3905 0 33.5455 0ZM18.45 26L25.1591 18H20.1273V0H16.7727V18H11.7409L18.45 26ZM33.5455 0H23.4818V3.98H33.5455V32.04H3.35455V3.98H13.4182V0H3.35455C1.50955 0 0 1.8 0 4V32C0 34.2 1.50955 36 3.35455 36H33.5455C35.3905 36 36.9 34.2 36.9 32V4C36.9 1.8 35.3905 0 33.5455 0Z" />
            </g>
       </svg>
    )
};

export default Import;

