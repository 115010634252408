import React from 'react'
import { Row, Col, Button, Label } from 'reactstrap';

const ParteSuperiorNoticias = (props) => {
    return (
        <Col xs="12">
            <Row>
                <Label xs={12} sm={8}><b className="label-nome-pagina">{props.titulo}</b></Label>
                <Col xs={12} sm={4}>
                    <Button onClick={props.onClick} className="float-right">{props.buttonLabel}</Button>
                </Col>
            </Row>
        </Col>
    )
}


export default ParteSuperiorNoticias
