import React from 'react'

function Pesquisar({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
            version="1.0"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 65 65"
            style={style}
            className={className}
        >
            <g
                fill={color}
                stroke="none"
            >
                <path d="M 62.921875 59.203125 L 47.453125 43.113281 C 51.429688 38.386719 53.609375 32.4375 53.609375 26.242188 C 53.609375 11.773438 41.835938 0 27.367188 0 C 12.894531 0 1.121094 11.773438 1.121094 26.242188 C 1.121094 40.714844 12.894531 52.488281 27.367188 52.488281 C 32.800781 52.488281 37.976562 50.847656 42.402344 47.738281 L 57.988281 63.949219 C 58.640625 64.625 59.515625 65 60.457031 65 C 61.34375 65 62.1875 64.660156 62.828125 64.046875 C 64.1875 62.734375 64.230469 60.566406 62.921875 59.203125 Z M 27.367188 6.847656 C 38.0625 6.847656 46.765625 15.546875 46.765625 26.242188 C 46.765625 36.941406 38.0625 45.640625 27.367188 45.640625 C 16.671875 45.640625 7.96875 36.941406 7.96875 26.242188 C 7.96875 15.546875 16.671875 6.847656 27.367188 6.847656 Z M 27.367188 6.847656 " />

            </g>
        </svg>
    )
};

export default Pesquisar;


