import React from 'react'
import { Button } from 'reactstrap'
import { IconInput } from '../index'
import { Link } from "react-router-dom"

const RecuperarSenhaForm = (props) => {
    return (


        <div className="div-form-login" >

            <div className="div-wrapper-centralizado">
                <p className='texto-explicativo-superior'>
                    Enviaremos um email com instruções de como redefinir sua senha.
                </p>
            </div>
            <IconInput tipo={"usuario"}
                placeholder="E-mail"
                tipoPattern={true}
                onChange={props.onChange}
                invalido={props.invalido[0]}
                defaultValue={props.formulario.usuario}
                submit={props.submit}
            />

            <Button className="btnHeader"
                onClick={props.submitOnClick}>
                Enviar
            </Button>

            <Link to='/login' className='color-verde'>Voltar ?</Link>

        </div>
    )
}

export default RecuperarSenhaForm
