import React from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup } from 'reactstrap';
import { FiEdit, FiTrash2, FiPlus, FiSave } from 'react-icons/fi';
import { connect } from 'react-redux'
import { FaRegClone } from 'react-icons/fa';
import { ativarModalConfirm } from './../Store/Actions/index'

const MenuTable = (props) => {
    return (
        <div className={(props.alinharDireita) ? "float-right align-content-middle" : "align-content-middle"} style={props.containerStyle}>
            <ButtonGroup>
                {(props.iconAdicionar | props.todos) ? <FiPlus onClick={props.adicionarOnClick} className="menuIconsReplica " /> : null}
                {(props.iconEdit | props.todos) ? <FiEdit onClick={props.editarOnClick} className="menuIcons " /> : null}
                {(props.iconReplicar | props.todos) ? <FaRegClone onClick={props.replicarOnClick} className="menuIconsReplica" /> : null}
                {(props.iconRemover2 | props.todos) ? <FiTrash2 onClick={() => props.ativarCofirmacao({ titulo: 'deletar', mensagem: 'Deseja remover este item ?', callback: props.removerOnClick })} className="menuIconsReplica" /> : null}
                {(props.iconRemoverLocal | props.todos) ? <FiTrash2 onClick={() => props.ativarCofirmacao({ titulo: 'deletar', mensagem: 'Deseja remover este item ?', callback: props.removerLocal })} className="menuIconsReplica" /> : null}
                {(props.iconSalvar | props.todos) ? <FiSave onClick={() => props.ativarCofirmacao({ titulo: 'salvar', mensagem: 'Deseja salvar este item ?', callback: props.salvarLocal })} className="menuIconsReplica" /> : null}
                {(props.iconRemover | props.todos) ? <FiTrash2 onClick={() => props.ativarCofirmacao({ titulo: 'deletar', mensagem: 'Deseja remover este item ?', callback: props.removerOnClick })} className="menuIcons" /> : null}

            </ButtonGroup>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativarCofirmacao: (obj) => dispatch(ativarModalConfirm(obj))
    }
}

MenuTable.propTypes = {
    iconVisualizar: PropTypes.bool,
    iconEdit: PropTypes.bool,
    iconRemover: PropTypes.bool,
    todos: PropTypes.bool
}

export default connect(null, mapDispatchToProps)(MenuTable);


