import React from 'react';
import { Col, Modal, ModalBody,Button,ModalHeader} from 'reactstrap';
import { eachDayOfInterval } from 'date-fns'
import { TabelaModal } from '../Basicos';

export default function ModalDetalhes({

    detalhes,setDetalhes,informacoes, periodo, horario
}){
   
    if(!informacoes)
        return null

        var difference = eachDayOfInterval({
            start: new Date(periodo.inicio),
            end: new Date(periodo.fim)
        });
        informacoes.flatMap( informacao => 
            horario.filter(horario => 
                horario.tipo_de_atividade_id === informacao.tipo_atividade_id)
                .map(informacao => informacao)
            );

        // const getdados = () => informacoes.filter(informacao => informacao.inicio === difference)
        // const getSomatoria = () => informacoes.map(informacao => ({id:informacao.tipo_de_atividade_id, inicio : informacao.inicio, fim : informacao.fim}))
    return (
        <Modal isOpen={detalhes} className="modalVisualizardetalhes" >
              <ModalHeader className="modal-content">
                <p className="m-0 font-weight-bold nome_modal_detalhe"> Detalhes </p>
            </ModalHeader >
            <ModalBody>
                                         <TabelaModal
                                        tabelaClassName="tabelaConsulta"
                                        columns={[
                                            {
                                                Header: 'Tipo de Atividade',
                                                accessor: 'tiposdeatividades.nome',
                                            },
                                            {
                                                Header: 'Carga Horária Previsão',
                                                accessor: 'carga_horaria',
                                            },
                                            {
                                                Header:'Carga Horária a Realizar',
                                                accessor:'qtd_restante_hora',
                                            },
                                            {
                                                Header: 'Diferença',
                                                accessor: 'diferenca',
                                            },
                                            {
                                                Header:'Aulas Previstas',
                                                accessor:'min_de_aula',
                                            },
                                            {
                                                Header:'Aulas a Realizar',
                                                accessor:'qtd_restante_aula',
                                            }
                                        ]}
                                        data={informacoes}
                                    />
                <Col sm={12} className="align-content-middle mb-3 p-0 mt-3">
                    <Button
                        onClick={() => setDetalhes(false)}
                        className="btn-transparente"
                    >
                        Fechar
                    </Button>
                </Col>
            </ModalBody >
        </Modal >
    );
}