import React from 'react'
import { Col } from 'reactstrap';
import MenuTable from '../../Menu/menuTable';

const CampoHeader = (props) => {
    return props.header && (
        <Col sm={12} className={"item-crud-meta-header align-content-middle"}>
            <span className="text-ellipsis" style={{fontWeight: 700}}>
                {props.dados[props.header]}
            </span>
            <MenuTable
                alinharDireita
                iconEdit={props.update !== undefined}
                iconRemover={props.delete !== undefined}
                iconReplicar={props.replicate !== undefined}
                iconActivate={props.activate     !== undefined  && props.dados['activate']      !== undefined  ? props.dados['activate']    : true}
                iconInactivate={props.inactivate !== undefined  && props.dados['inactivate']    !== undefined  ? props.dados['inactivate']  : true}
                iconReject={props.reject         !== undefined  && props.dados['reject']        !== undefined  ? props.dados['reject']      : true}
                containerStyle={{ marginLeft: 'auto' }}
                replicarOnClick={props.replicarOnClick}
                removerOnClick={props.removerOnClick}
                editarOnClick={props.editarOnClick}
                activateOnClick={props.activateOnClick}
                inactivateOnClick={props.inactivateOnClick}
                rejectOnClick={props.rejectOnClick}
            />
        </Col>
    )
}

CampoHeader.defaultProps = {
    header: null
};
export default CampoHeader
