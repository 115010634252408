import React, { useState, Fragment } from 'react';
import { Col, Row, Modal, ModalBody, Form, Button, FormGroup, Label, Input } from 'reactstrap';
import { NewLoader, SelectForm, RadioForm, InputForm } from '../Basicos';


export default function ModalCritica({
    isOpen, dados, vinculaHorarioEAula, submitAulaDnD, infoExtra, fecharModalCritica
}) {
    const escolherFuncao = () => {
        if (infoExtra.dnd)
            submitAulaDnD(infoExtra);
        else
            vinculaHorarioEAula(infoExtra);

    }
    return (
        <Modal className="modal-critica" isOpen={isOpen}>
            <ModalBody>
                <Row className="titulo-modal-critica">
                    <h4><b>Conflito de Aulas</b></h4>
                </Row>
                {dados?.map((dado) => {
                    return (
                        <ul className='ul-log'>
                            {
                                dado.tipo_critica_id == 3 ?
                                    dado?.grupos == "Todos"
                                        ? <><span className='fonte-negrito'>Todos</span> os grupos estão alocados no mesmo dia e horário para a aula <span className='fonte-negrito'>{dado?.aulas_id != null ? dado?.aula?.aula?.titulo : dado?.aula?.servico?.descricao}</span> para <span className='fonte-negrito'>{dado?.aula.oferta_periodo_letivo.perido_letivo.descricao} {dado.aulas_id != null ? dado.aula.aula.semestre.descricao : dado.aula.oferta_periodo_letivo.semestre.descricao}</span> semestre <span className='fonte-negrito'>{dado?.aula?.turma?.descricao}</span></>
                                        : <>Os grupos
                                            {dado?.grupos?.descricao?.map((grupo) => { return (<><span className='fonte-negrito'>{grupo?.descricao}</span>,</>) })} estão alocados no mesmo dia e horário para a aula <span className='fonte-negrito'>
                                                {dado.aula.aulas_id != null ? dado.aula.aula.titulo : dado.aula.servico.descricao}</span> para <span className='fonte-negrito'>
                                                {dado.aula.oferta_periodo_letivo.perido_letivo.descricao} {dado.aula.aulas_id != null ? dado.aula.aula.semestre.descricao : dado.aula.oferta_periodo_letivo.semestre.descricao} </span> semestre <span className='fonte-negrito'> {dado?.aula?.turma?.descricao}</span></>
                                    : <>{dado.tipo_critica_id == 1 ? 'O(A) Professor(a) ' : dado.tipo_critica_id == 2 ? 'O local ' : 'Grupo'}
                                        <span className='fonte-negrito'>
                                            {dado.tipo_critica_id == 1 ?
                                                dado?.professor?.nome_social :
                                                dado?.aula?.local?.nome}
                                        </span> está alocado no mesmo dia e horário
                                        para a aula <span className='fonte-negrito'>
                                            {dado.aula.aulas_id != null ? dado.aula.aula.titulo : dado.aula.servico.descricao}</span> para <span className='fonte-negrito'>
                                            {dado.aula.oferta_periodo_letivo.perido_letivo.descricao} {dado.aula.aulas_id != null ? dado.aula.aula.semestre.descricao : dado.aula.oferta_periodo_letivo.semestre.descricao} </span> semestre <span className='fonte-negrito'> {dado?.aula?.turma?.descricao}</span>
                                    </>
                            }
                        </ul>
                    )
                })}

                <Row className="titulo-modal-critica">
                    <h4 className='alerta-modal-critica'>Deseja prosseguir com a alteração?</h4>
                </Row>
                <Col sm={12} className="align-content-middle mb-3 p-0">
                    <Button
                        onClick={() => fecharModalCritica()}
                        className="float-right btn-transparente"
                    >
                        Não
                    </Button>
                    <Button onClick={() => escolherFuncao()}>Sim</Button>
                </Col>
            </ModalBody>
        </Modal>
    );
}
