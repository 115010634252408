import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col, Label } from "reactstrap"
import { ParteSuperiorDashboard, ParteSuperiorButton } from './superior/index'
import { ParteInferiorDashboard, ParteInferiorEmpresas } from './inferior/index'
import MenuHoc from "./../Hoc/menuHoc"
import { connect } from 'react-redux'
import * as actionsRedux from '../Store/Actions/index'
import { useHistory } from 'react-router-dom'
import ParteSuperiorNoticias from './superior/parte-superior-noticias'
import ParteInferiorNoticias from './inferior/parte-inferior-noticias'

const gerarParteSuperior = (props) => {
	switch (props.funcionalidade) {
		case 'dashboard':
			return <ParteSuperiorDashboard optionsSelect={props?.options}
				setDadosFiltrados={props.setDadosFiltrados}
				setValoresFiltro={props.setValoresFiltro}
				valoresFiltro={props.valoresFiltro}
				isActive={props.isActive}
			/>
		case 'funcionario':
			return <ParteSuperiorButton buttonLabel='Novo Funcionário'
				onClick={props.ativarModalFuncionario}
				titulo={props.titulo} />
		case 'noticias-e-eventos':
			return <ParteSuperiorNoticias
				buttonLabel='Adicionar'
				onClick={props.ativarModalNoticiasEEventos}
				titulo={props.titulo}
			/>
		case 'usuario':
			return <ParteSuperiorButton buttonLabel='Novo Usuário'
				onClick={props.ativarModalUsuario}
				titulo={props.titulo} />
		case 'noticia-individual':
			return (
				<Label xs={12} sm={12}>
					<BackButton>
						<span className="label-nome-pagina font-weight-bold mr-4">
							{"<"}
						</span>
						<b className="label-nome-pagina">{props.titulo}</b>
					</BackButton>
				</Label>
			)
		default:
			return <Label xs={12} sm={12}><b className="label-nome-pagina">{props.titulo}</b></Label>
	}
}

function BackButton({ children }) {
	let history = useHistory();

	return (
		<div className="cursor-ponteiro" onClick={() => history.goBack()}>
			{children}
		</div>
	);
}

const gerarParteInferior = (props) => {
	switch (props.funcionalidade) {
		case 'dashboard':
			return <ParteInferiorDashboard selectChange={props.selectChange} />
		case 'empresas':
			return <ParteInferiorEmpresas match={props.match} />
		case 'noticias-e-eventos':
			return <ParteInferiorNoticias
				selectChange={props.selectChange}
				navs={props.navs}
				activeTab={props.activeTab}
				setActiveTab={props.setActiveTab}
				setFilter={props.setFilter}
				filterNoticias={props.filterNoticias} />
		default:
			return <Col sm={12} className="preencherEspaco"></Col>
	}
}

const MenuDasFuncionalidades = (props) => {
	return (
		<Fragment>
			{gerarParteSuperior(props)}
			{gerarParteInferior(props)}
		</Fragment>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		ativarModalUsuario: () =>
			dispatch(actionsRedux.ativarModalSubFuncionalidade('usuario', 0, 'adicionar')),
		ativarModalFuncionario: () =>
			dispatch(actionsRedux.ativarModalSubFuncionalidade('funcionario', 0, 'adicionar')),
		ativarModalNoticiasEEventos: () =>
			dispatch(actionsRedux.ativarModalSubFuncionalidade('eventos-e-noticias', 0, 'adicionar'))
	}
}

const Menu = MenuHoc(MenuDasFuncionalidades)
export default connect(null, mapDispatchToProps)(Menu)

MenuDasFuncionalidades.propTypes = {
	funcionalidade: PropTypes.string.isRequired,
	titulo: PropTypes.string.isRequired,
	radioChange: PropTypes.func,
	inputChange: PropTypes.func,
	selectChange: PropTypes.func,
	match: PropTypes.object
}