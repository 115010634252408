import React from 'react'
import { Row, Col, Label } from 'reactstrap';
import { SelectForm } from '../../Basicos';
const ParteSuperior = ({ optionsSelect, setDadosFiltrados, setValoresFiltro, valoresFiltro, isActive }) => {

  const onChangeSelect = (nome, valor) => {
    switch (nome) {
      case 'curso_id':
        setValoresFiltro({ ...valoresFiltro, curso_id: valor })
        const filtraCurso = optionsSelect[0].filter(dado => (dado.curso_id == valor))
        setDadosFiltrados(filtraCurso)
        break;
      case 'periodo_letivo_id':
        setValoresFiltro({ ...valoresFiltro, periodo_letivo_id: valor })
        const filtraPeriodoLetivo = optionsSelect[1].filter(dado => (dado.periodo_letivo_id == valor))
        setDadosFiltrados(filtraPeriodoLetivo)
        break;
      case 'semestre_id':
        setValoresFiltro({ ...valoresFiltro, semestre_id: valor })
        const filtraSemestre = optionsSelect[2].filter(dado => (
          dado.curso_id == valoresFiltro.curso_id
          && dado.semestre_id == valor))
        setDadosFiltrados(filtraSemestre)
        break;
      default:
        break;
    }
  }

  const style = {
    col4: { xs: 6, md: 3, sm: 3, lg: 3, xl: 3 },
    col6: { xs: 6, md: 2, sm: 2, lg: 2, xl: 2 }
  }
  return (
    <Col xs="12">
      <Row>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginRight: '4rem' }}>
          <Label xs={12} sm={6} md={4} lg={6} ><b className="label-nome-pagina">{"Dashboard"}</b></Label>
          {isActive ? <SelectForm
            label="Curso"
            name="curso_id"
            placeholder="Curso"
            options={optionsSelect[0]}
            onChange={onChangeSelect}
            value={optionsSelect[0] && optionsSelect[0].find(option => (option.value === valoresFiltro.curso_id)) || ''}
            style={{ col: style.col6 }}
          /> : ""}
          {isActive ? <SelectForm
            label="Período Letivo"
            name="periodo_letivo_id"
            placeholder="Período Letivo"
            options={optionsSelect[1]}
            value={optionsSelect[1] && optionsSelect[1].find(option => (option.value === valoresFiltro.periodo_letivo_id)) || ''}
            onChange={onChangeSelect}
            // readOnly={!valoresFiltro.curso_id}
            style={{ col: style.col6 }}
          /> : ""}
          {isActive ? <SelectForm
            label="Semestre"
            name="semestre_id"
            placeholder="Semestre"
            options={optionsSelect[2]}
            value={optionsSelect[2] && optionsSelect[2].find(option => (option.value === valoresFiltro.semestre_id)) || ''}
            onChange={onChangeSelect}
            readOnly={!valoresFiltro.curso_id}
            style={{ col: style.col6 }}
          /> : ""}
        </div>
      </Row>
    </Col>
  )
}

export default ParteSuperior
