import * as tiposActions from '../Actions/tiposActions'
// import format from 'date-fns/format'

const ativar = (tipo, acao) => {
    return {
        tipo: tipo,
        acao: acao,
        ...definirAlert(tipo, acao),
        type: tiposActions.ATIVAR_ALERT,
    };
}

export const ativarAlert = (tipo, acao) => {
    return (dispatch) => {
        dispatch(ativar(tipo, acao));
        setTimeout(() => dispatch(desativarAlert()), 3000);
    }
}

export const desativarAlert = () => {
    return {
        type: tiposActions.DESATIVAR_ALERT,
    }
}

export const ativarModalConfirm = ({ mensagem, titulo, callback }) => {
    console.log(callback)
    return {
        titulo,
        mensagem,
        callback,
        type: tiposActions.ATIVAR_MODAL_CONFIRM,
    };
}

export const desativarModalConfirm = () => {
    return {
        type: tiposActions.DESATIVAR_MODAL_CONFIRM,
    }
}

const definirAlert = (tipo, acao) => {
    // const horario = format(new Date(), 'HH:mm:ss');
    switch (tipo) {
        case "remover":
            if (acao === "sucesso") {
                return { mensagem: "Sua remoção foi efetuada com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível efetuar sua remoção!", cor: "danger" }
            }
        case 400:
            return { mensagem: acao, cor: "danger" }
        case 201:
        case 200:
            return { mensagem: acao, cor: "success" }
        case "editar":
            if (acao === "sucesso") {
                return { mensagem: "Sua edição foi efetuada com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível efetuar sua edição!", cor: "danger" }
            }
        case "adicionar":
            if (acao === "sucesso") {
                return { mensagem: "Sua adição foi efetuada com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível efetuar sua adição!", cor: "danger" }
            }
        case "inativo":
            if (acao === "sucesso") {
                return { mensagem: "Sua Inativação/Ativação foi efetuada com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível efetuar sua inativação!", cor: "danger" }
            }
        case "login":
            if (acao === "sucesso") {
                return { mensagem: "Seu login foi efetuado com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível efetuar seu login. Verifique seus dados e tente novamente !", cor: "danger" }
            }
        case "loginWithToken":
            if (acao === "sucesso") {
                return { mensagem: "Seu login foi efetuado com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Acesso não autorizado! Entre em contato com o Administrador da Plataforma", cor: "danger" }
            }
        case "acesso":
            if (acao === "sucesso") {
                return { mensagem: "Seu login foi efetuado com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Sua sessão expirou. Faça o login e tente novamente !", cor: "danger" }
            }
        case "recuperar-senha":
            if (acao === "sucesso") {
                return { mensagem: "Um e-mail foi enviado com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível enviar um e-mail. Tente novamente !", cor: "danger" }
            }

        case "mudar-senha":
            if (acao === "sucesso") {
                return { mensagem: "Sua senha foi alterada com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível alterar sua senha. Tente novamente !", cor: "danger" }
            }
        case "conexaoWebService":
            return { mensagem: "Não foi estabelecer conexão com o servidor. Faça um refresh na pagina !", cor: "danger" }
        case "modal":
            if (acao === "sucesso") {
                return { mensagem: "Sua ação foi realizada com sucesso!", cor: "success" }
            } else {
                return { mensagem: " Não foi realizar sua ação. Tente novamente!", cor: "danger" }
            }
        case "replicar":
            if (acao === "sucesso") {
                return { mensagem: "Seus dados foram replicados com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível replicar seus dados!", cor: "danger" }
            }

        case "exportar":
            if (acao === "iniciar") {
                return { mensagem: "Foi iniciado a exportação!", cor: "warning" }
            } else if (acao === "sucesso") {
                return { mensagem: "Relatório exportado com sucesso!", cor: "success" }
            } else {
                return { mensagem: "Não foi possível exportar o relatório!", cor: "danger" }
            }
        case "semDadosRelatorio":
            return { mensagem: "Não há dados suficientes para elaboração desse relatório!", cor: "warning" }
        case 403:
            return { mensagem: acao, cor: "danger" }
        default: return { mensagem: undefined, cor: undefined }
    }
}
