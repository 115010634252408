import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Label, FormFeedback, FormGroup } from "reactstrap"
import { selectStyle } from '../funcoes'
import Select from 'react-select'

/********************************************************************************************************************************************/
//Componente responsavel por configurar um select do react-select de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/******************************************************************************************************************************************/

const SelectForm = (props) => {
    const [manipuladoPeloUsuario, setManipuladoPeloUsuario] = useState(false);
    const [maximoSelecionado, setMaximoSelecionado] = useState(false);

    const onChange = (selecionados, { action }) => {
        // console.log(selecionados, action)
        if (selecionados == null && action == 'remove-value') {
            props.onChange(props.name, [], props.indice, [], action);
            return;
        }
        if (props.isMulti) {
            selecionados = selecionados ? selecionados : [];
            if (selecionados.length == 0 && action == "clear") {
                props.onChange(props.name, [], props.indice, [], action);
                return;
            }

            let ultimoValor = selecionados[selecionados.length - 1].value;
            const valores = selecionados.length >= 1 ? selecionados.map((elemento) => (elemento.value)) : undefined;

            if (action === "select-option" && maximoSelecionado) {
                return;
            }

            if (action === "select-option" && selecionados.length === props.maxOptions) {
                setMaximoSelecionado(true);
            }
            setMaximoSelecionado(props.maxOptions ? selecionados.length >= props.maxOptions : false);
            props.onChange(props.name, valores, props.indice, ultimoValor, action);
        } else {
            props.onChange(props.name, selecionados.value, props.indice);
        }
    }

    const noOptionsMessage = () => (maximoSelecionado ? 'Máximo atingido' : 'Sem opções');

    const changeClass = () => (isValid() ? "is-invalid input-invalid" : "");

    const isValid = () => ((manipuladoPeloUsuario || props.submit) && props.invalido);

    useEffect(() => {
        if (isNaN(props.maxOptions)) {
            setMaximoSelecionado(false)
        }
    }, [props.maxOptions])

    return (
        <Col {...props.style.col} className={"form-col " + props.colunaClass}>
            <FormGroup className={(props.hidden ? "hidden " : "") + (props.newSelect ? "new-form-group" : "")}>
                <Label className={props.labelHidden ? "hidden" : props.style.labelClass + " label"}>
                    {props.label + ":"}
                </Label>
                {/* <FormGroup className="form-group-feedback-input"> */}
                <Select
                    defaultValue={props.valorPadrao}
                    noOptionsMessage={noOptionsMessage}
                    isMulti={props.isMulti}
                    placeholder={props.placeholder}
                    options={maximoSelecionado ? undefined : props.options}
                    styles={selectStyle}
                    onBlur={props.newSelect ? props.onBlur : () => setManipuladoPeloUsuario(true)}
                    className={`${props.type == "select2" ? "height-form " : ""}` + "select select-form " + props.style.elementoClass + changeClass() + `${props.telaAgenda ? " tela-agenda" : ""}`}
                    onChange={onChange}
                    isDisabled={props.readOnly}
                    menuPortalTarget={props.menuPortalTarget}
                    value={props.value}
                />
                <FormFeedback style={{ display: isValid() ? "block" : "none" }}>
                    Selecione uma opção valida !
                </FormFeedback>
                {/* </FormGroup> */}
            </FormGroup>
        </Col>
    );
}

export default React.memo(SelectForm);



const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

SelectForm.defaultProps = {
    hidden: false,
    placeholder: "",
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "select select-form",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }
    },
    newSelect: false
};

SelectForm.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    valorInicial: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    maxOptions: PropTypes.number,
    telaAgenda: PropTypes.bool,
    style: PropTypes.shape(styleShape),
}