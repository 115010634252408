import { Col, Label, CustomInput, FormFeedback, FormGroup } from "reactstrap"
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/******************************************************************************************************************************************************/
//Componente responsavel por configurar um customInput(Radio) do reactstrap de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/****************************************************************************************************************************************************/

const RadioForm = (props) => {
    const [checked, setChecked] = useState(props.valorPadrao);

    const onChange = (event) => {
        props.onChange(props.name, event.target.value)
        setChecked(event.target.value);
    };
    const changeClass = () => (isValid());

    const isValid = () => ((checked) && props.invalido);


    const gerarId = () => (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);

    return (
        <Col {...props.style.col} className="form-col">
            <FormGroup className="form-group">

                <Label className={props.style.labelClass}>
                    {props.label}
                </Label>

                <div className="d-flex">
                    {props.options.map((elemento, indice) => (
                        <>
                            {/* {checked === elemento.value} */}
                            <CustomInput
                                type="radio"
                                key={props.label + indice}
                                id={gerarId()}
                                name={props.name}
                                label={elemento.label}
                                checked={elemento.label == "Todos"
                                    || elemento.label == "Grupo 1"
                                    || elemento.label == "Grupo 2"
                                    || elemento.label == "Grupo 3" ? checked === elemento.value : checked == "true" ? "true" === elemento.value : "false" === elemento.value}
                                onChange={onChange}
                                disabled={props.disabled}
                                value={elemento.value}
                                className={
                                    (indice === 0) ? "margin-radio" : ""
                                        + changeClass()
                                        + (indice !== 0) ? "ml-5" : ""
                                }
                            />
                        </>
                    ))}
                </div>
                <FormFeedback style={{ display: isValid() ? "block" : "none" }}>
                    Selecione uma opção valida !
                </FormFeedback>
            </FormGroup>
        </Col>
    );
}

export default RadioForm;

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)

}

RadioForm.defaultProps = {
    placeholder: "",
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "select select-form",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};

RadioForm.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    valorInicial: PropTypes.string,// index da opçao padrao
    onChange: PropTypes.func.isRequired,
    style: PropTypes.shape(styleShape)
}
