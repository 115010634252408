import React, { useState } from "react";

const ButtonGroup = ({ buttons, doSomethingAfterClick }) => {
  const [clickedId, setClickedId] = useState(0);

  const handleClick = (event, id) => {
    setClickedId(id);
    doSomethingAfterClick(event);
  };

  const classNameRoundedButton = (clickedId) => {
    switch (clickedId) {
      case 0:
        return "rounded-l-xl";
      case 3:
        return "rounded-r-xl";
      default:
        return "";
    }
  };

  return (
    <div className="div-button-group-filter-dashboard">
      {buttons.map((buttonLabel, i) => (
        <button
          key={i}
          name={buttonLabel}
          value={i}
          onClick={(event) => handleClick(event, i)}
          className={i === clickedId ? `button-filter-graph-dashboard focus:outline-none ${classNameRoundedButton(i)}` : `button-filter-indigo-graph-dashboard focus:outline-none ${classNameRoundedButton(i)}`}
        >
          {buttonLabel}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;