import React, { Fragment } from 'react'
import { Col } from 'reactstrap'
import { connect } from 'react-redux'
import * as actionsRedux from '../Store/Actions/index'
import { MenuDasFuncionalidades } from '../Menu';
import { CardsCadastroDeAtividades } from './Cards';
import { Route } from "react-router-dom";
import CardSemestresComTemas from './Cards/CardSemestreTemas';

function CalendarioDeAtividades({ subFuncionalidade, indiceSubFuncionalidade }) {
    return (
        <Fragment>
            <MenuDasFuncionalidades
                funcionalidade={"oferta-por-periodo-letivo"}
                classNavBar="navbar-consultas"
                titulo="Calendário de Atividades das Semanas Padrão"
            />
            <Col sm={12} md={12} className="col-conteudo">
                <Route
                    exact
                    path={subFuncionalidade.url}
                    render={(props) => {
                        return  <CardSemestresComTemas
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indiceSubFuncionalidade}
                                    funcionalidade={"auxiliares"}
                                />
                    }}
                />

                <Route
                exact
                    path={`${subFuncionalidade.url}/tema/:tema_id/periodo/:periodo_letivo_id/turma/:turma_id`}
                    render={(props) => (
                        <CardsCadastroDeAtividades
                            {...props}
                            subFuncionalidade={subFuncionalidade}
                            indiceSubFuncionalidade={indiceSubFuncionalidade}
                            funcionalidade={"auxiliares"}
                        />
                    )}
                />
            </Col>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        atualizar: (dados) => dispatch(actionsRedux.atualizarDadosEmpresa(dados))
    }
}

export default connect(null, mapDispatchToProps)(CalendarioDeAtividades)