import React from 'react';
import { ModalConsulta } from '.';
import Tabela from '../Basicos/Tabela'; // Certifique-se de importar o caminho correto
import LinhaAluno from './../Basicos/LinhaAluno'; // Importe o componente LinhaAluno

function ModalConsultaListaRodizioAnalitico(props) {
    const { estrutura } = props;
    const { rodizio } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "listaRodizioAnalitico",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Nome Aluno',
                    accessor: 'nome_aluno',
                },
                {
                    Header: 'Matricula',
                    accessor: 'matricula',
                },
                {
                    Header: 'Grupo',
                    accessor: 'grupo',
                }
            ],
        }],
        []
    );

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <Tabela
                columns={columns}
                data={rodizio}
                tabelaClassName="tabelaConsulta"
                RowComponent={LinhaAluno} // Passa o componente LinhaAluno para renderizar as linhas
            />
        </ModalConsulta>
    );
}

export default ModalConsultaListaRodizioAnalitico;
