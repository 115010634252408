import React, { Fragment, useState } from 'react';
import { ModalConsulta } from '.';
import { CardCalendarioPadrao } from '../Conteudo/Cards';
import {
    transformaDataEmString,
    stringEmDate,
    calendarioOfertaService,
    mesesDaSemanaFormatado,
} from '../../services/calendarioOferta';
import { Button, Input } from 'reactstrap';

function ModalConsultaHorariosOfertas(props) {
    const { data } = props;
    const dadosSemanaOfertas = data.modal.semanaOfertas;
    const {
        aulas,
        calendarioInstitucional,
        grupoalunos,
        horariosofertas,
        ofertaPeriodo,
        periodoLetivo,
        semestre,
        tema,
        tiposDeAtividades
    } = dadosSemanaOfertas;
    const getHorariosDoCurso = () => tema?.curso?.horarios ?? [];

    const [pageIndex, setPageIndex] = useState(0);

    const getTipoDeAtividadeDoTema = () => {
        return tiposDeAtividades;
    }

    const cardsCalendarioPadrao = () => {

        const calendarioService = calendarioOfertaService({
            dataInicial: stringEmDate(ofertaPeriodo.data_inicio),
            dataFinal: stringEmDate(ofertaPeriodo.data_fim)
        });

        const semanas = calendarioService.semanasEntreDataInicialEDataFinal();

        function BotaoPaginacao(props) {
            const {
                className,
                style = {},
                buttonStyle = {},
                disabled = true,
                onClick,
                children
            } = props;

            return (
                <div className={className} style={style}>
                    <Button onClick={onClick} disabled={disabled} style={buttonStyle}>
                        {children}
                    </Button>{' '}
                </div>
            );
        }

        function nextPage() {
            setPageIndex(pageIndex + 1);
        }

        function previousPage() {
            setPageIndex(pageIndex - 1);
        }

        function gotoPage(pageIndex) {
            setPageIndex(pageIndex);
        }

        const canPreviousPage = pageIndex !== 0;
        const canNextPage = pageIndex + 1 !== semanas.length;

        function BotoesPaginacaoPrevious(props) {
            return (
                <Fragment>
                    <BotaoPaginacao
                        className='-previous'
                        disabled={!canPreviousPage}
                        onClick={() => previousPage()}
                    >
                        {'<'}
                    </BotaoPaginacao>
                </Fragment>
            );
        }

        function BotoesPaginacaoNext(props) {
            return (
                <Fragment>
                    <BotaoPaginacao
                        className='-next'
                        style={{ marginRight: '3px' }}
                        buttonStyle={{ marginRight: '5px' }}
                        disabled={!canNextPage}
                        onClick={() => nextPage()}
                    >
                        {'>'}
                    </BotaoPaginacao>
                </Fragment>
            );
        }
        const semana = semanas[pageIndex];

        const horariosDefinidos = semana.flatMap(diaDaSemana =>
            horariosofertas.filter(horarioOferta =>
                horarioOferta.data.toString() === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
            )
        );
        const datasInstitucionais = semana.flatMap(diaDaSemana =>
            calendarioInstitucional.filter(data_institucional =>
                data_institucional.data === transformaDataEmString(diaDaSemana, 'yyyy-MM-dd')
            )
        );
        return (
            <Fragment>
                <div className="calendario-padrao__mesesDaSemana" style={{padding: '1rem'}}>
                    <h1 className="d-inline">{mesesDaSemanaFormatado(semana)}</h1>
                </div>
                <CardCalendarioPadrao
                    key={pageIndex}
                    grupos={grupoalunos}
                    aulas={aulas}
                    colClassname="calendario-padrao--comDomingoESabado"
                    className="h-200"
                    datasInstitucionais={datasInstitucionais}
                    inicioDaSemana={0}
                    finalDaSemana={6}
                    horariosDefinidos={horariosDefinidos}
                    horariosCurso={getHorariosDoCurso()}
                    tipoAtividades={getTipoDeAtividadeDoTema()}
                    horariosOfertas={[]}
                    éTipoOferta={false}
                    // temMenu={false}
                    éConsulta={true}
                    tipoOferta={{
                        semana,
                    }}
                />
                <div className="align-content-middle mt-2" style={{ padding: '2rem' }}>
                    <div className="paginacao ReactTable -pagination">
                        <BotoesPaginacaoPrevious />
                        <div className="-center">
                            <span style={{ margin: '3px 10px', fontSize: '1rem' }}>
                                <span>Pagina </span> {' '}
                                <span>
                                    <Input
                                        type="number"
                                        value={pageIndex + 1}
                                        min={1}
                                        max={semanas.length}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '100px', display: 'inline-block', textAlign: 'center' }}
                                    />
                                </span>{' '}
                                <span>de {semanas.length}</span>
                            </span>
                        </div>
                        <BotoesPaginacaoNext />
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <section className="modalConsulta__calendarios">
                {cardsCalendarioPadrao()}
            </section>
        </ModalConsulta>
    );
}

export default ModalConsultaHorariosOfertas;