import React, { Fragment, useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import Select from 'react-select'
import { useTable, useBlockLayout, useFilters } from 'react-table'
import { FixedSizeList } from 'react-window'
import { selectStyle } from '../Basicos/funcoes';
import SelectForm from './Form/SelectForm'


const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id, regex, disabled, selectOptions, inputType, selectOptionsDisponivel },
    updateMyData, // This is a custom function that we supplied to our table instance
    getAlunosIdFromTable,
    alunosIds
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const [isValid, setIsValid] = React.useState(true)
    const [newOptions, setNewOptions] = React.useState(selectOptionsDisponivel)

    const onChange = e => {
        const { name, value, type, checked } = e.target
        if (type === 'checkbox') {
            getAlunosIdFromTable(checked ? 'add' : 'remove', original)
            setValue(checked)
        } else {
            setIsValid(regex.test(value))
            setValue(value)
        }
    }

    const selectOnChange = (name, valores, indice) => {
        setValue(valores)
        if (isValid && (valores != initialValue)) {
            updateMyData({
                ...original,
                row_id: original.id,
                campo: id,
                valor: name.value
            })
        }
    }

    const selectOnChangeMulti = (name, valores, indice) => {
        if (valores == undefined) {
            setNewOptions(selectOptions)
            setValue([])
        } else if (valores.length == 0) {
            console.log(valores, indice, name)
        } else {
            newFunction(valores)
            setValue(valores)
        }
        if (isValid && (valores != initialValue)) {
            updateMyData({
                ...original,
                row_id: original.id,
                campo: id,
                valor: valores == undefined ? [] : valores
            })
        }
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if (isValid && (value != initialValue)) {
            updateMyData({
                ...original,
                row_id: original.id,
                campo: id,
                valor: value
            })
        }
    }

    const selectOnBlur = () => {
        if (value != initialValue) {
            updateMyData({
                ...original,
                row_id: original.id,
                campo: id,
                valor: value?.value
            })
        }
    }

    const selectOnBlurMulti = () => {
        if (value != initialValue) {
            updateMyData({
                ...original,
                row_id: original.id,
                campo: id,
                valor: value
            }, newOptions)
        }
    }

    const newFunction = (value) => {
        var index = selectOptions.findIndex(element => element.value == value[0]);
        let modifiedArr = selectOptions.filter(function (element) {
            if (element.value == selectOptions[index].value)
                return element;
        });
        setNewOptions(modifiedArr)
    }

    const getInput = (inputType) => {
        switch (inputType) {
            case 'checkbox':
                return <Input type='checkbox' style={{ position: 'initial', marginTop: '.5rem', marginLeft: '0' }} checked={value} onChange={onChange} disabled={disabled} />
            case 'select':
                return (selectOptions.length && <Select
                    noOptionsMessage={() => 'Sem opções'}
                    options={selectOptionsDisponivel}
                    styles={selectStyle}
                    value={selectOptions.find(elemento => elemento.value == value)}
                    className={"select select-form"}
                    menuPortalTarget={document.body}
                    onChange={selectOnChange}
                    labelHidden
                    name="grupos_ids"
                // onBlur={selectOnBlur}
                />)
            case 'newSelect':
                return (selectOptions?.length &&
                    <SelectForm
                        name="grupos_ids"
                        labelHidden
                        value={selectOptions?.filter(elemento => value?.includes(elemento.value))}
                        options={selectOptionsDisponivel}
                        onChange={selectOnChangeMulti}
                        isMulti
                        colunaClass="px-0"
                        style={selectStyle}
                        isRequired={true}
                        onBlur={selectOnBlurMulti}
                        newSelect={true}
                    />)
            default:
                return <Input value={value} onChange={onChange} onBlur={onBlur} className="input-tabela" disabled={disabled} invalid={!isValid} />
        }
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        if (alunosIds?.length && inputType === 'checkbox') {
            setValue(alunosIds.includes(original.id))
        }
    }, [alunosIds])

    return (
        //verifica qual o tipo de input e renderiza
        getInput(inputType)
    )
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, Header }
}) {
    return (
        <input
            value={filterValue || ''}
            className="form-control filtro-tabela"
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={''}
        />
    )
}

function TableWithVirtualizedRow({
    columns,
    data,
    tabelaClassName = "table-default",
    updateMyData,
    getAlunosIdFromTable,
    alunosIds,
    setFiltro,
    isGrupoProfessor,
    isConteudoProva
}) {
    // Use the state and functions returned from useTable to build your UI

    const defaultColumn = React.useMemo(
        () => ({
            width: 150,
            Cell: EditableCell,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        rows,
        prepareRow,
        headerGroups,
        getTableProps,
        getTableBodyProps,
        totalColumnsWidth
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            updateMyData,
            getAlunosIdFromTable,
            alunosIds
        },
        useFilters,
        useBlockLayout
    )

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <tr
                    {...row.getRowProps({
                        style,
                    })}
                >
                    {row.cells.map(cell => {
                        return (
                            <td {...cell.getCellProps({
                                className: `${cell.column.id === 'nome_professor' ? 'coluna-fixa' : ""} ${cell.column.className} ${tabelaClassName}__celulaDados ${isGrupoProfessor ? "tabela-professor" : ""}`,
                                style: {
                                    ...cell.column.style,
                                    position: cell.column.id === 'nome_professor' ? 'sticky' : null,
                                    left: cell.column.id === 'nome_professor' ? 0 : null,
                                    zIndex: cell.column.id === 'nome_professor' ? 1 : null
                                },
                                default: "N/A"
                            })}>
                                {
                                    cell.column.id === "array_professor" ?
                                        <>
                                            <h1 className='conteudo-professor-modal'>
                                                {cell.value.map((professor, index) => {
                                                    return (
                                                        <>
                                                            {`${cell.value.length - 1 == index ? professor.nome_social : `${professor.nome_social}, `}`}
                                                        </>
                                                    )
                                                })}
                                            </h1>
                                        </> : cell.column.id === "array_grupo" ?
                                            <h1 className='conteudo-professor-modal'>
                                                {cell.value.map((grupo, index) => {
                                                    return (
                                                        <>
                                                            {`${cell.value.length - 1 == index ? grupo.grupo.descricao : `${grupo.grupo.descricao}, `}`}
                                                        </>
                                                    )
                                                })}
                                            </h1>
                                            : cell.column.id === "array_data" ?
                                                <h1 className='conteudo-professor-modal'>
                                                    {cell.value.map((data, index) => {
                                                        return (
                                                            <>
                                                                {`${cell.value.length - 1 == index ? data : `${data}, `}`}
                                                            </>
                                                        )
                                                    })}
                                                </h1>
                                                :
                                                cell.value
                                                    || cell.column.id === "acoes"
                                                    || cell.column.id === "Cor"
                                                    || cell.column.id === "#"
                                                    || cell.column.id === "grupo"
                                                    || cell.column.id === "grupo_ace"
                                                    || cell.column.id === "grupo_cha"
                                                    || cell.column.id === "local_ace"
                                                    || cell.column.id === "local_cha"
                                                    || cell.column.id === "tutoria_id"
                                                    || cell.column.id === "Grupo 1"
                                                    || cell.column.id === "Grupo 2"
                                                    || cell.column.id === "Professor"
                                                    ? cell.render('Cell')
                                                    : "N/A"}
                            </td>
                        )
                    })}
                </tr>
            )
        },
        [prepareRow, rows]
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} className={`${tabelaClassName}`}>
                {/* <table {...getTableProps()} className={`${tabelaClassName} ${isGrupoProfessor ? "header-professor" : ""}`}> */}
                <thead className={`${isGrupoProfessor ? "header-professor" : ""}`}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps({ style: { width: '100%' } })}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps({
                                    className: `${column.className} ${tabelaClassName}__celulaCabecalho`,
                                    style: {
                                        ...column.style,
                                        position: column?.Header == "Nome" && isGrupoProfessor ? 'sticky' : null,
                                        left: column?.Header == "Nome" && isGrupoProfessor ? 0 : null
                                    },
                                })}>
                                    <div style={{ fontSize: '1rem' }}>{column.render('Header')}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps({ style: { width: '100%' } })}>

                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps({
                                    className: `${column.className} ${tabelaClassName}__celulaCabecalho`,
                                    style: {
                                        ...column.style, background: '#fbfbfb',
                                        position: column?.Header == "Nome" && isGrupoProfessor ? 'sticky' : null,
                                        left: column?.Header == "Nome" && isGrupoProfessor ? 0 : null
                                    },
                                })}>
                                    <div>
                                        {column.filterable !== false && column.render('Filter')}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    <FixedSizeList
                        height={isGrupoProfessor ? rows.length * 60 : isConteudoProva ? 600 : 400}
                        itemCount={rows.length}
                        itemSize={60}
                        width='100%'
                        className={isGrupoProfessor ? 'overflow-professor' : ''}
                    >
                        {RenderRow}
                    </FixedSizeList>
                    <div className='align-content-middle'>Total: {data.length}</div>
                </tbody>
            </table>
        </>
    )
}

export default TableWithVirtualizedRow