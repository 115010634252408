import React from 'react';

function Aulas({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
            version="1.0"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 65 65"
            style={style}
            className={className}
        >
            <g
                fill={color}
                stroke="none"
            >
                <path d="M 57.359375 13.082031 L 50.464844 13.082031 L 50.464844 8.367188 C 50.464844 7.332031 49.53125 6.507812 48.496094 6.507812 C 47.460938 6.507812 46.527344 7.34375 46.527344 8.367188 L 46.527344 13.09375 L 16.671875 13.09375 L 16.671875 8.367188 C 16.671875 7.332031 15.738281 6.507812 14.703125 6.507812 C 13.667969 6.507812 12.734375 7.34375 12.734375 8.367188 L 12.734375 13.09375 L 6.085938 13.09375 C 2.847656 13.082031 0.214844 15.730469 0.214844 18.964844 L 0.214844 53.398438 C 0.214844 56.636719 2.847656 59.285156 6.097656 59.285156 L 57.359375 59.285156 C 60.597656 59.285156 63.246094 56.648438 63.246094 53.398438 L 63.246094 18.964844 C 63.246094 15.730469 60.609375 13.082031 57.359375 13.082031 Z M 11.429688 55.34375 L 11.429688 51.40625 L 19.714844 51.40625 L 19.714844 55.34375 Z M 43.71875 55.34375 L 43.71875 53.375 L 53.914062 53.375 L 53.914062 55.34375 Z M 59.304688 53.191406 C 59.304688 54.125 58.703125 54.9375 57.851562 55.222656 L 57.851562 51.503906 C 57.851562 50.46875 57.015625 49.644531 55.992188 49.644531 L 41.640625 49.644531 C 40.605469 49.644531 39.78125 50.480469 39.78125 51.503906 L 39.78125 55.332031 L 23.652344 55.332031 L 23.652344 49.3125 C 23.652344 48.277344 22.816406 47.453125 21.792969 47.453125 L 9.347656 47.453125 C 8.316406 47.453125 7.488281 48.289062 7.488281 49.3125 L 7.488281 55.332031 L 6.097656 55.332031 C 4.90625 55.332031 4.128906 54.050781 4.128906 52.871094 L 4.128906 19.140625 C 4.128906 17.945312 4.90625 16.984375 6.097656 16.984375 L 57.152344 16.984375 C 58.34375 16.984375 59.304688 17.957031 59.304688 19.140625 Z M 59.304688 53.191406 " />

            </g>
        </svg>
    )
};

export default Aulas;





