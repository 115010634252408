import React, { useState, useEffect, Fragment } from 'react';
import { Col, Button, Row, Label } from "reactstrap";
import { DataUnicForm, DateForm, SelectForm } from '../../Basicos';
import CardHoc from "../../Hoc/cardHoc";

import { montarDiaDaSemanaSelect, parseDate, formatLaravelDate } from '../../Basicos/funcoes'
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore'
import { isEqual } from 'date-fns';

function CardAddAtividadePadrao({
    infoHorarioSelecionado,
    salvarHorario,
    onChangeHorario,
    horariosCurso,
    tipoAtividades,
    inicioDaSemana,
    finalDaSemana,
    ofertaPeriodo,
    datasInstitucionais = [],
    temas = [],
    sugestao,
    tipoOferta = false,
    grupos
}) {
    const [disabledSalvar, setDisabledSalvar] = useState(true);
    const [labIsec, setLabIsec] = useState([]);
    const [grupoOptions, setGrupoOptions] = useState([]);
    const [novoTipoAtividadesOptions, setNovoTipoAtividadesOptions] = useState();
    const [tipoatividadeValue, setTipoAtividadeValue] = useState();
    const [idAntigo, setIdAntigo] = useState();
    const [tipoAtividadesOptions, setTipoAtividadesOptions] = useState([])

    const { dia_da_semana, data_inicio, data_fim, horario_do_curso_id, tipo_atividade_id, data_selecionada } = infoHorarioSelecionado;

    const dataInstitucionalValue = datasInstitucionais.find(elemento => elemento.data === data_selecionada);

    const horariosCursoOptions = React.useMemo(() =>
        horariosCurso.map(elemento => ({ value: elemento.id, label: elemento.horario.descricao })),
        [horariosCurso]
    );

    const horarioCursoValue = horariosCursoOptions.find(elemento => elemento.value === horario_do_curso_id);

    useEffect(() => {
        if (dia_da_semana === 0) {
            setTipoAtividadesOptions(tipoAtividades.filter(elemento => elemento.abreviatura == 'DOM').map(elemento => ({ value: elemento.id, label: elemento.nome })))
        } else if (dia_da_semana === 6) {
            setTipoAtividadesOptions(tipoAtividades.filter(elemento => elemento.abreviatura == 'SAB').map(elemento => ({ value: elemento.id, label: elemento.nome })))
        } else {
            setTipoAtividadesOptions(tipoAtividades.map(elemento => ({ value: elemento.id, label: elemento.nome })))
        }
    }, [dia_da_semana]);

    // const tipoatividadeValue = tipoAtividadesOptions.find(elemento => elemento.value === tipo_atividade_id);

    // const novoTipoAtividadesOptions = React.useMemo(() => {
    //     console.log('b')

    // });

    const diaDaSemanaOptions = React.useMemo(() =>
        montarDiaDaSemanaSelect(inicioDaSemana, finalDaSemana),
        [inicioDaSemana, finalDaSemana]
    );

    const diaDaSemanaValue = diaDaSemanaOptions.find(elemento => elemento.value == dia_da_semana);

    // const temasOptions = React.useMemo(() =>
    //     tema => ({ value: tema.id, label: tema.nome }));

    // const temasValue = temasOptions.find(tema => tema.value === tema_id);

    useEffect(() => {
        if (idAntigo == undefined)
            setIdAntigo(infoHorarioSelecionado?.id);
        else {
            if (idAntigo != infoHorarioSelecionado?.id && infoHorarioSelecionado?.id != undefined) {
                setTipoAtividadeValue(undefined);
                setIdAntigo(infoHorarioSelecionado?.id);
            }
        }
        if (tipoatividadeValue == undefined || tipoatividadeValue == null || (idAntigo != infoHorarioSelecionado?.id && infoHorarioSelecionado?.id != undefined)) {
            // var tipoIndex = tipoAtividadesOptions.findIndex(elemento => elemento.value === tipo_atividade_id)
            // var newOptions = tipoAtividadesOptions;
            // setNovoTipoAtividadesOptions(newOptions.splice(tipoIndex, 1));
        }
        if (tipoOferta)
            setDisabledSalvar(!(dia_da_semana && data_selecionada && horario_do_curso_id && tipo_atividade_id))
        else
            setDisabledSalvar(!(dia_da_semana && data_inicio && horario_do_curso_id && tipo_atividade_id),)
    }, [dia_da_semana, data_inicio, horario_do_curso_id, tipo_atividade_id, data_selecionada, tipoOferta]);

    const mudarMarcador = (name, value) => {
        if (name === 'intervalo_data') {
            // onChangeHorario((prevState) => ({ ...prevState, data_inicio: sugestao, horario_do_curso_id : infoHorarioSelecionado?.horario_do_curso_id ,dia_da_semana:infoHorarioSelecionado?.dia_da_semana , estruturasNoMesmoDia:[] }));
            onChangeHorario({ data_inicio: value.from, data_fim: value.to });

        } else if (name === 'tipo_atividade_id') {
            setTipoAtividadeValue(tipoAtividadesOptions.find(elemento => elemento.value === value));
            onChangeHorario({ [name]: value });
        }
        else {
            onChangeHorario({ [name]: value });
            // setInfoHorarioSelecionado((prevState) => ({ ...prevState, data_inicio: sugestao, horario_do_curso_id : infoHorarioSelecionado?.horario_do_curso_id ,dia_da_semana:infoHorarioSelecionado?.dia_da_semana , estruturasNoMesmoDia:[] }));

        }
    };
    const verificarDiaInstituicional = () => {

        if (tipoOferta) {
            const dataSelecionada = parseDate(infoHorarioSelecionado.data_selecionada, formatLaravelDate)
            const dataInicio = parseDate(ofertaPeriodo.data_inicio, formatLaravelDate);
            const dataFim = parseDate(ofertaPeriodo.data_fim, formatLaravelDate);

            const inicioSemestre = isAfter(dataSelecionada, dataInicio) || isEqual(dataSelecionada, dataInicio);
            const fimSemestre = isBefore(dataSelecionada, dataFim) || isEqual(dataSelecionada, dataFim);
            const naoEferiado = !dataInstitucionalValue || dataInstitucionalValue.tipo_de_data_id != 3;
            const sabadoLetivo = dia_da_semana != 6 || (dataInstitucionalValue && dataInstitucionalValue.tipo_de_data_id == 5);
            const domingoLetivo = dia_da_semana != 0 || (dataInstitucionalValue && dataInstitucionalValue.tipo_de_data_id == 6);
            return (inicioSemestre && fimSemestre && naoEferiado);
        }

        return true;
    }

    useEffect(() => {
        if (tipoOferta) {
            setLabIsec(tipoAtividades?.filter(el => el.codprincipal == 'ISEC' || el.codprincipal == 'LAB' || el.codprincipal == 'CHA' || el.codprincipal == 'ACE').map(el => el.id))
            setGrupoOptions(grupos?.filter(grupo => (grupo.tipo_de_grupo_id == 1))
                .map(grupo => ({ value: grupo.id, label: grupo.descricao })))
        }
    }, [tipoAtividades])

    const FormularioCadastroDeAtividade = () =>
        tipoOferta
            ? <Fragment>
                <Label style={{ margin: "1rem" }}> <h6>Tema:</h6> {temas.nome}</Label>
                <DateForm
                    name="data_selecionada"
                    label="Data"
                    style={{ labelClass: "label-centralizado" }}
                    onChange={mudarMarcador}
                    dataInicial={{ "label": "Data", ...dateInicialFormStyle, diasFuturos: true }}
                    value={{ from: parseDate(data_selecionada, formatLaravelDate) }}
                />

                {labIsec.includes(infoHorarioSelecionado.tipo_atividade_id) &&
                    <SelectForm
                        name="grupos_ids"
                        label="Grupo"
                        isMulti={true}
                        value={grupoOptions.filter(option => infoHorarioSelecionado.grupos_ids?.includes(option.value))}
                        options={grupoOptions}
                        onChange={mudarMarcador}
                    />
                }
                <SelectForm
                    name="tipo_atividade_id"
                    label="Tipo de Atividade"
                    value={tipoatividadeValue ? tipoatividadeValue : {}}
                    options={tipoAtividadesOptions}
                    onChange={mudarMarcador}
                />

                <SelectForm
                    name="horario_do_curso_id"
                    label="Tipo de Horário"
                    value={horarioCursoValue}
                    options={horariosCursoOptions}
                    onChange={mudarMarcador}
                />

            </Fragment>
            : <Fragment>
                <SelectForm
                    name="dia_da_semana"
                    label="Dia da Semana"
                    value={diaDaSemanaValue}
                    options={diaDaSemanaOptions}
                    onChange={mudarMarcador}
                />
                <SelectForm
                    name="tipo_atividade_id"
                    label="Tipo de Atividade"
                    value={tipoatividadeValue ? tipoatividadeValue : {}}
                    options={tipoAtividadesOptions} //se der buxo, volta pro novo
                    onChange={mudarMarcador}
                />

                <SelectForm
                    name="horario_do_curso_id"
                    label="Tipo de Horário"
                    value={horarioCursoValue}
                    options={horariosCursoOptions}
                    onChange={mudarMarcador}
                />

                <DateForm
                    name="intervalo_data"
                    label="Tipo de Marcador"
                    style={{ labelClass: "label-centralizado" }}
                    onChange={mudarMarcador}
                    dataInicial={{ "label": "Data Inicial", ...dateInicialFormStyle, diasFuturos: true }}
                    dataFinal={{ "label": "Data Final", ...dateFinalFormStyle, diasFuturos: true }}
                    value={{ from: parseDate(data_inicio, formatLaravelDate), to: parseDate(data_fim, formatLaravelDate) }}
                />
            </Fragment>

    return (
        <Col>
            <div className="align-content-middle mb-2" style={{ margin: "0rem 0rem", borderBottom: "1px solid var(--cor-border-secundario)", padding: "-1rem" }}>
                <h6>
                    <strong>Cadastro de Tipo de Atividades</strong>
                </h6>
            </div>
            {
                dia_da_semana !== undefined && verificarDiaInstituicional() ?
                    <Fragment>
                        <div className="align-content-middle tipo-atividade-form-calendario">
                            <Row>
                                <FormularioCadastroDeAtividade />
                            </Row>
                        </div>
                        <div className="align-content-middle mt-2">
                            <Button disabled={disabledSalvar} onClick={() => salvarHorario()} >Salvar</Button>
                        </div>
                    </Fragment>
                    :
                    <div className="align-content-middle mb-3 p-3" style={{ color: "var(--cor-border-secundario)" }}>
                        <strong>Selecione um dia para continuar</strong>
                    </div>
            }
        </Col>
    );
}

export default React.memo(CardHoc(CardAddAtividadePadrao));

const dateInicialFormStyle = {
    "placeholder": "Data Inicial",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12,
            "xl": 12
        }
    }
}
const dateFinalFormStyle = {
    "placeholder": "Data Final",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "xs": 12,
            "sm": 12,
            "md": 12,
            "lg": 12,
            "xl": 12
        }
    }
}