import React from 'react'
import { ModalConsulta } from '.';
import { Tabela, ListaPrimaria } from '../Basicos';
import { CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer';
import { Col } from 'reactstrap';

function ModalConsultaProfessorSintetico(props) {
    const {estrutura} = props;
    const {professores, listaPrimaria} = estrutura.modal;
    
    const columns = React.useMemo(
        () => [{
            Header: "Professores",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Titulação',
                    accessor: 'titulacao.nome',
                },
                {
                    Header: 'Nome',
                    accessor: 'nome',
                },
                {
                    Header: 'Matrícula',
                    accessor: 'matricula',
                },
                {
                    Header: 'CPF',
                    accessor: 'cpf',
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                }
            ]
        }],
        []
      )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <Col xs={12} sm={3} md={3} lg={3} xl={2} className="tamanho-lista-primaria cor-body">
                <ListaPrimaria
                    scrollRef={React.createRef()}
                    cache={new CellMeasurerCache({fixedWidth: true, defaultHeight: 100})}
                    // rowRenderer={this.itemListaPrimaria}
                    listaRef={React.createRef()}
                    listaPrimaria={listaPrimaria}
                />
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={10} className="tamanho-lista-secundaria cor-body">
                <Tabela 
                    columns={columns} 
                    data={professores} 
                    tabelaClassName="tabelaConsulta"
                />
            </Col>
        </ModalConsulta>
    );
}

export default ModalConsultaProfessorSintetico;
