import * as tiposActions from '../Actions/tiposActions'
import { setStateRedux } from '../setStateRedux'

const initialState = {
    empresas: undefined,
    solucao: undefined,
    funcionalidades: undefined,
    subFuncionalidades: undefined,
    niveis: undefined,
    alterar: undefined
}

const atualizar = (state, action) => {
    return setStateRedux(state, { ...action.dados })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case tiposActions.ATUALIZAR_EMPRESA:
            return atualizar(state, action)
        default:
            return state;
    }
}

export default reducer