import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Col,Button, Row, Collapse } from 'reactstrap';
import { FaAngleDoubleDown, FaSearch } from 'react-icons/fa';
import { Estruturas } from "../index"

const iniciarValorPadrao = (estrutura, params) => {
    if (estrutura && params) {
        return estrutura.map((elemento) => {
            switch (elemento.type) {
                case 'select':
                    let select = Object.assign({}, elemento);
                    for (let indice = 0; indice < select.options.length; indice++) {
                        if (select.options[indice].value === params[select.name]) {
                            select.valorPadrao = Object.assign({}, select.options[indice]);
                            break;
                        }
                    };
                    return select;
                case 'asyncselect':
                    return Object.assign({}, elemento);
                case "date": {
                    let date = Object.assign({}, elemento);
                    date.dataInicial = Object.assign({}, elemento.dataInicial);
                    if (elemento.dataFinal) {
                        date.dataInicial.valorPadrao = params[elemento.name].from + ' 00:00:00';
                        date.dataFinal = Object.assign({}, elemento.dataInicial);
                        date.dataFinal.valorPadrao = params[elemento.name].to + ' 00:00:00';
                    } else {
                        date.dataInicial.valorPadrao = params[elemento.name];
                    }
                    return date;
                }
                default:
                    let def = Object.assign({}, elemento);
                    def.valorPadrao = params[elemento.name];
                    return def;
            }
        });
    } else {
        return estrutura
    }
} 

const FiltroConsulta = (props) => {
    const [estrutura] = useState(iniciarValorPadrao(props.estrutura, props.params));
    const [active, setActive] = useState(false);
    const formRef = useRef();

    const submit = (formRef, callback) => {
        if (!formRef.current.state.incompleto) {
            const params = Object.assign({}, formRef.current.formulario);
            setActive(false);
            callback(params);
        }
    }
    
    return  props.ativo && (
            <Col xs={12} sm={12} className = "filtro-consulta-modal" style = { active ? { maxHeight:"2000px" } : { maxHeight:"20px", height:'20px' }}>
                <Row noGutters={true} style = { active ? { display:"inherit", padding: '20px' } : { display:"none" } }>
                    <Collapse isOpen={estrutura !== undefined && active}> 
                        <Estruturas estrutura={estrutura} tipo={"forms"} ref={formRef}/>
                    </Collapse>  
                    <Col xs={12} sm={12} className="align-content-middle" >
                        <Button onClick={() => submit(formRef, props.gerarConsulta)}> Nova Consulta </Button>
                    </Col>
                </Row>
                <div 
                    className={ "seta-consulta-modal align-content-middle"} 
                    onClick = {() => setActive(!active) }
                > 
                    {
                        active 
                        ? <FaAngleDoubleDown color="white" size="15px" style = {{transform:'rotate(180deg)'}} /> 
                        : <FaSearch color="white" size="15px"/> 
                    }
                </div>
            </Col>
        );
}

FiltroConsulta.propTypes = {
    estrutura: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    params: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    gerarConsulta: PropTypes.func
}

FiltroConsulta.defaultProps = {
    ativo: true
}

export default FiltroConsulta
