import React from 'react'

const Loader = ({ fixed, overlay, padding }) => {
    return overlay ? (
            <div className="overlay" style={{ position: fixed ? 'fixed' : 'absolute' }}>
                <div className="loader" style={{ margin: padding ? '8px auto' : 'auto' }} />
            </div>
        ) : (
            <div className="loader" style={{ margin: padding ? '8px auto' : 'auto' }} />
    );
}

export default Loader
