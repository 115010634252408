import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Label, Input } from 'reactstrap';
import { axiosApi } from '../../Basicos/axiosInstances';
import DateForm from '../Form/DateForm';
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import TabelaTrocarAula from './TabelaTrocarAula/TabelaTrocarAula';
import { pt_br, parseDate, formatLaravelDate } from '../../Basicos/funcoes'
import { SelectForm } from '..';
import InputForm from '../Form/InputForm';

const ModalTrocarAula = ({
    ativar,
    className,
    HorarioAulas,
    modalTrocarAula,
    horariosOfertas,
    setModalTrocarAula,
    setHorariosOfertas
}) => {

    const [aulasPossiveis, setAulasPossiveis] = useState([]);
    const [aulaSelecionada, setAulaSelecionada] = useState();
    const loadAulasCompativeis = async (name, value) => {
        try {
            const duracao_minima = HorarioAulas?.horarios_aulas[0]?.aula?.duracao_minima;
            const { oferta_periodo_letivos_id, tipo_atividade_id, tema_id } = HorarioAulas
            const response = await axiosApi.get('aulas/trocar-aulas', {
                params: {
                    tema_id: tema_id,
                    duracao_minima: duracao_minima,
                    tipo_atividade_id: tipo_atividade_id,
                    oferta_periodo_letivos_id: oferta_periodo_letivos_id,
                    data: value
                }
            });
            setAulasPossiveis(response.data.dados);
            if (response.data.dados.length === 0) {
                ativar(400, 'Sem aulas compatíveis para a data selecionada');
            }
        } catch (error) {
            console.log(error)
        }
    }

    const aulasOptions = HorarioAulas.horarios_aulas && HorarioAulas.horarios_aulas.map(horarioAula => (
        {
            value: horarioAula.id,
            label: `${horarioAula.aula.titulo} - Grupo ${horarioAula.grupos_por_aula.length > 0 ? horarioAula.grupos_por_aula.map(grupoAula => (grupoAula.grupo.descricao)) : 'Todos'}`
        }))

    const onChangeSelect = (name, value) => {
        setAulaSelecionada(
            HorarioAulas.horarios_aulas && HorarioAulas.horarios_aulas.find(horarioAula => (horarioAula.id == value))
        )
    }

    const toggle = () => (
        setModalTrocarAula({
            ...modalTrocarAula,
            isOpen: !modalTrocarAula.isOpen
        }),
        setAulasPossiveis([])
    );

    const onChange = (nome, valor) => {
        console.log(nome)
    }

    const styleInputs = {
        col: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
        col3: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
        col4: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
    }

    const styleSelectForm = {
        diasFuturos: true,
        placeholder: "dd/mm/aaaa",
        style: {
            labelClass: "label-conteudoInferior",
            elementoClass: "",
            col: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }
        },
    }

    const professoresAulaSelecionada = aulaSelecionada !== undefined ? aulaSelecionada.professores.map(professor => (` ${professor.nome_social}`)) : undefined;

    return (
        <Modal isOpen={modalTrocarAula.isOpen} toggle={toggle} className={className} size='xl'>
            <ModalHeader>
                Trocar Aula
            </ModalHeader>
            <ModalBody>
                <Row>
                    <SelectForm
                        name="horarioAula_id"
                        label="Aula"
                        placeholder="Selecione uma aula"
                        options={aulasOptions}
                        onChange={onChangeSelect}
                        style={styleInputs}
                    />
                    <InputForm
                        label="Tipo de Atividade"
                        name="Tipo de Atividade"
                        type="text"
                        onChange={onChange}
                        value={aulaSelecionada !== undefined ? aulaSelecionada.horario_periodo.tipo_de_atividades.nome : 'Selecione uma Aula'}
                        style={{ col: { ...styleInputs.col3 } }}
                        disabled
                    />
                    <InputForm
                        label="Duração"
                        name="Duração"
                        type="text"
                        onChange={onChange}
                        value={aulaSelecionada !== undefined ? aulaSelecionada.aula.duracao_minima : 'Selecione uma Aula'}
                        style={{ col: { ...styleInputs.col3 } }}
                        disabled
                    />
                </Row>
                <Row>
                    <InputForm
                        label="Professores"
                        name="Professores"
                        type="text"
                        onChange={onChange}
                        value={professoresAulaSelecionada !== undefined ? professoresAulaSelecionada : 'Selecione uma Aula'}
                        disabled
                    />
                </Row>
                <Row>
                    <InputForm
                        label="Dia da Semana"
                        name="Dia da Semana"
                        type="text"
                        onChange={onChange}
                        value={pt_br.weekdaysLong[HorarioAulas?.dia_da_semana]}
                        style={styleInputs}
                        disabled
                    />
                    <InputForm
                        label="Hora Atual"
                        name="Hora Atual"
                        type="text"
                        onChange={onChange}
                        value={aulaSelecionada !== undefined ? aulaSelecionada.inicio : 'Selecione uma Aula'}
                        style={styleInputs}
                        disabled
                    />
                </Row>
                <Row>
                    <DateForm
                        name="data"
                        onChange={onChange}
                        dataInicial={{
                            label: "Data Atual",
                            readOnly: true,
                            ...styleSelectForm,
                        }}
                        value={{ from: parseDate(HorarioAulas?.data, formatLaravelDate) }}
                    />
                    <DateForm
                        onChange={loadAulasCompativeis}
                        name="data"
                        dataInicial={{
                            label: "Data Pretendida",
                            ...styleSelectForm
                        }}
                    />
                </Row>
                <Row noGutters>
                    <TabelaTrocarAula
                        toggle={toggle}
                        HorarioAulas={HorarioAulas}
                        aulasPossiveis={aulasPossiveis}
                        aulaSelecionada={aulaSelecionada}
                        horariosOfertas={horariosOfertas}
                        setHorariosOfertas={setHorariosOfertas}
                        ativar={ativar}
                    />
                </Row>
            </ModalBody>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

export default connect(null, mapDispatchToProps)(ModalTrocarAula);