import React from 'react'
import { Col, Input, Nav, Row } from 'reactstrap';
import { NavItemCustom } from '../../Basicos';


const ParteInferiorNoticias = (props) => {
  return (
    <Col sm={12} md={12} className={props.textClass}>
      <Row className="tab-navbar-noticias">
        {props.navs && (
          <Col sm={6} className="align-content-start">
            <Nav tabs className="">
              {props.navs.map((elemento, indice) => {
                return <NavItemCustom
                  activeTab={props.activeTab}
                  classname={'h5 font-weight-bold'}
                  onClick={() => props.setActiveTab(indice)}
                  descricao={elemento.label}
                  id={indice}
                  key={indice}
                />
              })}
            </Nav>
          </Col>)}
        {props.navs && (
          <Col sm={6} className="align-content-ends">
            <div id="pesquisar-wrap" style={{ paddingBottom: '0.5rem' }}>
              <Input
                type={"text"}
                name={"pesquisa"}
                placeholder={"Pesquisar"}
                value={props.filterNoticias || ''}
                onChange={e => { props.setFilter(e.target.value || undefined) }}
              />
            </div>
          </Col>)}
      </Row>
    </Col>

  )
}

export default ParteInferiorNoticias

