

import React from 'react'
import { Col, CustomInput, FormGroup } from 'reactstrap';

const SwitchForm = (props) => {
    const [checked, setChecked] = React.useState(props.checked)

    const onChange = (event) => {
        props.onChange(event.target.name, event.target.checked)
        setChecked(event.target.checked);
    }

    React.useEffect(() => {
        setChecked(props.checked);
    }, [props.checked, props.id]);

    return (
        <Col {...props.style.col} style={{ padding: '0px' }}>
           <FormGroup className={(props.hidden ? "hidden" : "")}>
                <CustomInput
                    onChange={onChange}
                    type="switch"
                    id="material-switch"
                    name={props.name}
                    disabled={props.disabled}
                    className={props.className}
                    label={props.label}
                    inline={props.inline}
                    checked={checked}
                    defaultChecked={props.valorPadrao}
                />
            </FormGroup>
        </Col>
    )
}

SwitchForm.defaultProps = {
    checked: false,
    onChange: () => { },
    height: 15,
    width: 30,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "select select-form",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};


export default SwitchForm
