import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';

function ModalConsultaAlteracoesSemanaPadrao(props) {
    const { estrutura } = props;
    const { alteracoesSemanaPadrao } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "alteracoesSemanaPadrao",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Semestre',
                    accessor: 'semestre',
                },
                {
                    Header: 'Tipo de Atividade',
                    accessor: 'tipo_atividade',
                },
                {
                    Header: 'Aula',
                    accessor: 'aula',
                },
                {
                    Header: 'Data da Aula',
                    accessor: 'data_aula',
                    type: 'data'
                },
                {
                    Header: 'Carga Horária',
                    accessor: 'carga_horaria',
                },
                {
                    Header: 'Usuário',
                    accessor: 'usuario',
                },
                {
                    Header: 'Data da Alteração',
                    accessor: 'data_alteracao',
                    type: 'dataAndHour'
                },
                {
                    Header: 'Tipo de Alteração',
                    accessor: 'tipo_alteracao',
                },
                {
                    Header: 'Descrição da Alteração',
                    accessor: 'descricao',
                }
            ],
        }],
        []
    )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"

            {...props}
        >
            <Tabela
                columns={columns}
                data={alteracoesSemanaPadrao}
                tabelaClassName="tabelaConsulta"
            />
        </ModalConsulta>
    );
}

export default ModalConsultaAlteracoesSemanaPadrao;
