import React, { useState, useEffect, Fragment } from 'react'
import { Card, Row, Col, Button, Modal } from "reactstrap";
import { axiosApi } from '../../Basicos/axiosInstances';
import { connect } from 'react-redux';
import { NewLoader } from '../../Basicos';
import * as actionsRedux from '../../Store/Actions/index';
import { Link } from 'react-router-dom';
import ModalCalendario from '../../Modal/ModalCalendario';
import { MdSearch } from "react-icons/md";
import ModalPeriodoCurso from '../../Modal/ModalPeriodoCurso';

function CardCalendarios({
    ativar,
    ativarCofirmacao,
    setCursoId,
    setPeriodoLetivoId,
    curso,
    periodoLetivo,
    subFuncionalidade: {
        url
    }
}) {
    const [calendarios, setCalendarios] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [periodosLetivos, setPeriodosLetivos] = useState([]);
    const [cursos, setCursos] = useState([]);

    const [modalAberto, setModalAberto] = useState(false);
    const [modal, setModal] = useState(false);
    const [semestres, setSemestres] = useState({});
    const [agregdorSemestre, setAgregdorSemestre] = useState({});
    const [descricao, setDescricao] = useState([]);
    const [calendarioID, setCalendarioID] = useState();

    const toggleModal = () => setModal(!modal);
    const toggleModa2 = () => setModalAberto(!modal);

    const calendariosDoCursoSelecionado = () => calendarios.filter(calendario => calendario.periodo_letivo_id === periodoLetivo && calendario.curso_id === curso);

    const periodoLetivoSelecionado = () => periodosLetivos.find(pL => pL.id === periodoLetivo);
    const cursoSelecionado = () => cursos.find(cs => cs.id === curso);

    const edite = (calendario) => {
        const ids = calendario.semestre.map(cal => cal.semestre_id)
        setModalAberto(true)
        setCalendarioID(calendario)
        setDescricao(calendario.descricao)
        setAgregdorSemestre(ids)

    }
    const handleSemestre = (agregadorId) => {
        setAgregdorSemestre(agregadorId);
        // setAgregdorSemestre([]);
    }
    const addicionarCalendario = () => {
        setIsLoading(true)
        axiosApi({
            method: "post",
            url: `${url}/calendario`,
            data: {
                id: calendarioID?.id,
                descricao: descricao,
                semestres: agregdorSemestre,
                periodo: periodoLetivo,
                curso_id: curso
            }
        }).then(resp => {
            setIsLoading(true)

            if (calendarioID) {
                const CalendariosAux = calendarios.slice();
                const { calendario } = resp.data;
                const calendarioindex = CalendariosAux.findIndex(ca => ca.id == calendarioID?.id);
                CalendariosAux[calendarioindex] = { ...CalendariosAux[calendarioindex], ...calendario }
                setCalendarios(CalendariosAux)
                setDescricao(null)

            }
            else {
                const CalendariosAux = calendarios.slice();
                const { calendario } = resp.data;
                CalendariosAux.push({ ...calendario });
                setCalendarios(CalendariosAux)
                setDescricao(null)

            }

        }).catch(error => {
            ativar("adicionar", "error");
        }).finally(() => {
            setModalAberto(false);
            setIsLoading(false);
        });
    };
    const confirmaDeletarHorarioModal = (calendario) =>
        ativarCofirmacao({
            titulo: 'Deletar',
            mensagem: 'Deseja deletar?',
            callback: () => deletarcalendarioModal(calendario)
        })

    const deletarcalendarioModal = (calendario) => {
        axiosApi({
            method: "delete",
            url: `${url}/calendario/${calendario.id}`,
        }).then(resp => {
            setIsLoading(true)
            const CalendariosAux = calendarios.slice();
            const calendarioIndex = calendarios.findIndex(ca => ca.id === calendario.id)
            CalendariosAux.splice(calendarioIndex, 1);
            setCalendarios(CalendariosAux)
            setDescricao(null)
        }).catch(error => {
            ativar("sucesso", "error");
        }).finally(() => {
            setIsLoading(false);

        });
    }

    //Requisição inicial 
    useEffect(() => {
        axiosApi.get(url).then(resp => {
            setCalendarios(resp.data.calendario);
            setPeriodosLetivos(resp.data.periodo);
            setCursos(resp.data.curso)
            setSemestres(resp.data.semestre)
        }).catch(error => {
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }
        });
    }, [url]);

    //Ao gerar, modal confirmação

    const BotaoVisualizar = ({ url, calendario }) => {

        return (
            <Col>
                <Link
                    className="mx-auto"
                    method="get"
                    to={`${url}/calendario/${calendario.id}`}
                >
                    <Button className="font-weight-bold btn btn-excluir">Visualizar</Button>

                </Link>
                <Button className="font-weight-bold btn btn-excluir" onClick={() => edite(calendario)}>Editar</Button>
                <Button className="font-weight-bold btn btn-excluir" onClick={() => confirmaDeletarHorarioModal(calendario)}>Excluir</Button>
            </Col>
        );

    }

    const Item = ({ calendario }) => {
        return (
            <Col className="mt-5">
                <Card className="d-flex py-5 CardCalendarios__card">
                    <span className=" d-flex font-weight-bold azul-principal mx-auto mb-3 CardCalendarios__card--titulo ">
                        {calendario.descricao}
                    </span>

                    <span className=" azul-principal semenstredocalendario">
                        Semestres:
                    </span>

                    <span className=" azul-principal semenstredocalendario">
                        {
                            calendario.semestre.map((semestre, index) =>
                                (index > 0) ?
                                    <span key={index}> - {semestre.semestre.descricao}</span> :
                                    <span key={index}>{semestre.semestre.descricao}</span>
                            )
                        }
                    </span>
                    {
                        <BotaoVisualizar url={url} calendario={calendario} />
                    }

                </Card>
            </Col>
        );
    }

    const CardComCalendarios = () => {
        return (
            <Card className="mt-3 px-5 pb-5">
                <Col>
                    <Button onClick={toggleModa2} className="float-right button-novo" >
                        Novo
                    </Button>
                </Col>
                <Row xs="1" sm="2" md="3" lg="4">
                    {
                        calendariosDoCursoSelecionado().map(calendario =>
                            <Item calendario={calendario} key={calendario.id} />
                        )
                    }
                </Row>
            </Card>
        );
    }
    const Titulo = () => {
        return (
            <h1 className="m-0 font-weight-bold label-nome-pagina">
                Calendários Curso: {cursoSelecionado()?.nome} / Periodo Letivo - {periodoLetivoSelecionado()?.descricao}

                <Button onClick={toggleModal} className="float-right butom-pesquisa" >
                    <MdSearch />
                </Button>

            </h1>
        );
    }

    return (
        <NewLoader isActive={isLoading} overlay >
            {
                periodosLetivos && cursos && periodoLetivo && curso &&
                <Fragment>
                    <Titulo />
                    <CardComCalendarios />
                </Fragment>
            }
            {
                (periodosLetivos && cursos) && (
                    <ModalPeriodoCurso
                        isOpen={modal}
                        setIsOpen={setModal}
                        cursos={cursos}
                        periodosLetivos={periodosLetivos}
                        cursoId={curso}
                        setCursoId={setCursoId}
                        periodoLetivoId={periodoLetivo}
                        setPeriodoLetivoId={setPeriodoLetivoId}
                    />
                )
            }
            <Modal isOpen={modalAberto}>
                <ModalCalendario
                    modalAberto={modalAberto}
                    setModalAberto={setModalAberto}
                    curso={curso}
                    periodoLetivo={periodoLetivo}
                    semestres={semestres}
                    periodosLetivos={periodoLetivoSelecionado.descricao}
                    handleSemestre={handleSemestre}
                    setAgregdorSemestre={setAgregdorSemestre}
                    agregdorSemestre={agregdorSemestre}
                    setDescricao={setDescricao}
                    descricao={descricao}
                    addicionarCalendario={addicionarCalendario}
                />
            </Modal>
        </NewLoader>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        setCursoId: (tipo, acao) => dispatch(actionsRedux.setCurso(tipo, acao)),
        setPeriodoLetivoId: (tipo, acao) => dispatch(actionsRedux.setPeriodoLetivo(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        curso: state.auth.cursoId,
        periodoLetivo: state.auth.periodoLetivoId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardCalendarios)