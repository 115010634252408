import React from 'react'

function Professor({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
            version="1.0"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 65 65"
            style={style}
            className={className}
        >
            <g
                fill={color}
                stroke="none"
            >
                <path d="M 60.78125 4.722656 L 15.011719 4.722656 C 13.65625 4.722656 12.550781 5.832031 12.550781 7.1875 L 12.550781 24.679688 C 7.761719 25.800781 4.203125 30.09375 4.203125 35.21875 C 4.203125 38.664062 5.816406 41.730469 8.328125 43.710938 C 3.722656 46.050781 0.507812 50.714844 0.238281 56.15625 C 0.226562 56.253906 0.214844 56.34375 0.214844 56.429688 C 0.214844 56.464844 0.214844 56.488281 0.214844 56.527344 C 0.214844 56.660156 0.214844 56.785156 0.214844 56.921875 L 0.214844 56.933594 C 0.214844 57.746094 0.878906 58.398438 1.691406 58.398438 L 28.332031 58.398438 C 29.144531 58.398438 29.808594 57.746094 29.808594 56.933594 L 29.808594 56.921875 C 29.808594 53.128906 28.382812 49.683594 26.042969 47.058594 L 60.78125 47.058594 C 62.136719 47.058594 63.246094 45.953125 63.246094 44.597656 L 63.246094 7.1875 C 63.246094 5.832031 62.136719 4.722656 60.78125 4.722656 Z M 8.128906 35.230469 C 8.128906 31.425781 11.207031 28.347656 15.011719 28.347656 C 18.816406 28.347656 21.894531 31.425781 21.894531 35.230469 C 21.894531 39.03125 18.816406 42.109375 15.011719 42.109375 C 11.207031 42.109375 8.128906 39.019531 8.128906 35.230469 Z M 4.425781 54.457031 C 5.542969 49.644531 9.851562 46.0625 15 46.0625 C 20.144531 46.0625 24.464844 49.644531 25.574219 54.457031 Z M 59.304688 43.121094 L 22.410156 43.121094 C 24.515625 41.152344 25.832031 38.332031 25.832031 35.21875 C 25.832031 29.738281 21.757812 25.207031 16.476562 24.496094 L 16.476562 8.664062 L 59.304688 8.664062 Z M 59.304688 43.121094 " />
            </g>
        </svg>
    )
};

export default Professor;

