import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import { HeaderModal, InputForm } from '../Basicos'

const ModalCancelarAula = ({ modalCancelar, setModalCancelar, submitCancelarAula, titulo, mensagem }) => {
    const { isOpen, horarioAula } = modalCancelar
    const toggle = () => setModalCancelar({ ...modalCancelar, isOpen: !isOpen });
    const ref = useRef(null)

    const onChange = (value) => {
        setModalCancelar({
            ...modalCancelar,
            motivo: value
        })
    }

    const validaMotivo = () => {
        ref.current.focus();
        if (!!modalCancelar.motivo) {
            submitCancelarAula()
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <HeaderModal sm={12} toggle={toggle} titulo={titulo.toUpperCase()} />
            <ModalBody>
                <Row noGutters={true}>
                    <Col sm={12} style={{ padding: '8px' }}>
                        <h5>{mensagem}</h5>
                        <h6>{horarioAula?.aulas_id == null ? horarioAula?.servico.descricao : horarioAula?.aula.titulo}</h6>
                    </Col>
                    <Col sm={12} style={{ padding: '8px' }}>
                        <InputForm
                            type='text'
                            innerRef={ref}
                            label={'Motivo cancelamento'}
                            name='motivo_cancelamento'
                            onChange={(name, value) => onChange(value)}
                            invalido={!modalCancelar.motivo}
                        />
                    </Col>
                </Row>
                <Row noGutters={true}>
                    <Col sm={12} style={{ padding: '8px' }}>
                        <Button outline color="primary"
                            onClick={toggle}
                            className={"float-left btnHeader btnHeader-danger m-0"}>
                            NÃO
                        </Button>
                        <Button outline color="danger"
                            onClick={validaMotivo}
                            className={"float-right btnHeader m-0"}>
                            SIM
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalCancelarAula