import React, { useState } from 'react'
import { Fragment } from 'react';
import Select from 'react-select';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import { selectStyle } from '../funcoes';

const ModalAgruparAlunos = ({
    isOpen, setIsOpen,
    alunosIds, setAlunosIds,
    semestreId, cursoId,
    selectOptions,
    setModalAdicao,
    criarGruposAlunos
}) => {

    const [grupo, setGrupo] = useState();
    const [isValid, setIsValid] = useState(undefined);
    const regex = /^([A-Za-z]{1})$/

    const [dadosForm, setDadosForm] = useState({
        grupo: undefined,
        tutoria_id: undefined,
        alunosIds: alunosIds,
        semestre_id: semestreId,
        curso_id: cursoId
    });

    const toggle = () => setIsOpen(!isOpen)

    const onSubmit = () => {
        setAlunosIds([])
        criarGruposAlunos(dadosForm)
        toggle()
        setModalAdicao(false)
    }

    const resetValores = () => (
        setDadosForm({
            grupo: undefined,
            tutoria_id: undefined,
            alunosIds: alunosIds,
            semestre_id: semestreId,
            curso_id: cursoId
        }),
        setIsValid(undefined)
    )
    const onChange = ({ name, value }) => {

        setIsValid(name === 'grupo' ? regex.test(value) : true)
        setDadosForm({
            ...dadosForm,
            [name]: value
        })
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='md'>
            <ModalHeader toggle={toggle}>Agrupar Alunos</ModalHeader>
            <ModalBody>
                <Row noGutters>
                    <Col>
                        Quantidade de Alunos Selecionados: {alunosIds.length}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Row>
                            <Col>
                                <input type="radio" name="tipo_grupo" onChange={(e) => (setGrupo(e.target.value), resetValores())} value='grupo' />{' '}Grupo
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input type="radio" name="tipo_grupo" onChange={(e) => (setGrupo(e.target.value), resetValores())} value='tutoria' />{' '}Tutoria
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {grupo === 'grupo' &&
                            <Fragment>
                                <Input
                                    type='text'
                                    name='grupo'
                                    size='1'
                                    pattern="[A-Za-z]{1}"
                                    onChange={(e) => onChange({ name: e.target.name, value: e.target.value })} />
                                {
                                    dadosForm.grupo && !isValid &&
                                    <span style={{ color: 'red', fontSize: '0.7rem' }}>* ex: A ~ Z</span>
                                }
                            </Fragment>
                        }
                        {
                            grupo === 'tutoria' && selectOptions.length &&
                            <Select
                                noOptionsMessage={() => 'Sem opções'}
                                options={selectOptions}
                                styles={selectStyle}
                                className={"select select-form"}
                                menuPortalTarget={document.body}
                                onChange={(e) => onChange({ name: 'tutoria_id', value: e.value })}
                            />
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
                <Button color="primary" onClick={onSubmit} disabled={!isValid}>Salvar</Button>
            </ModalFooter>
        </Modal>)
}

export default ModalAgruparAlunos