import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';

function ModalConsultaAtividadesPrevistaGrupo(props) {
    const { estrutura } = props;
    const { alunos } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "alunos",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Grupo',
                    accessor: 'grupo_individual',
                },
                {
                    Header: 'Nome Aluno',
                    accessor: 'nome',
                },
                {
                    Header: 'Matrícula',
                    accessor: 'matricula',
                },
                {
                    Header: 'Atividade',
                    accessor: 'nome_atividade',
                },
                {
                    Header: 'Data',
                    accessor: 'atividade_data_chr',
                    type: 'arrayData'
                },
                {
                    Header: 'Quantidade de Atividades Total',
                    accessor: 'qtd_atividade_total',
                },
                {
                    Header: 'Quantidade Carga Horária Total',
                    accessor: 'carga_horaria_total',
                }
            ],
        }],
        []
    )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"

            {...props}
        >
            <Tabela
                columns={columns}
                data={alunos}
                tabelaClassName="tabelaConsulta"
            />
        </ModalConsulta>
    );
}

export default ModalConsultaAtividadesPrevistaGrupo;
