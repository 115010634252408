import React, { Fragment, useState, useEffect } from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import { Link } from 'react-router-dom';
import { axiosApi } from '../../Basicos/axiosInstances';
import { connect } from 'react-redux';
import * as actionsRedux from './../../Store/Actions/index';
import ModalPeriodoCurso from '../../Modal/ModalPeriodoCurso';

function CardSemestresComTemas({
    curso,
    setCursoId,
    periodoLetivo,
    setPeriodoLetivoId,
    ativar
}) {

    //cursos e periodos letivos exibidos no select
    const [periodoLetivos, setPeriodoLetivos] = useState(null);
    const [cursos, setCursos] = useState(null);

    // semestres com temas
    const [semestresComTemas, setSemestresComTemas] = useState(null);
    const [semanasGeradas, setSemanasGeradas] = useState([]);

    const [modal, setModal] = useState(false);

    useEffect(() => {
        pegaCursosEPeriodosLetivos();
    }, []);

    useEffect(() => {
        if (!periodoLetivo || !curso)
            setModal(true);
        if (periodoLetivo && curso)
            handleFormSubmit()
    }, [curso, periodoLetivo])

    const toggleModal = () => setModal(!modal);

    const pegaCursosEPeriodosLetivos = () => {
        axiosApi({
            method: 'GET',
            url: "/semana-padrao/calendario-de-atividades",
        })
            .then(resp => {
                setCursos(resp.data.dados.cursos);
                setPeriodoLetivos(resp.data.dados.periodoLetivos);

            }).catch(error => {
                if (error.response.status === 403) {
                    ativar(400, error?.response?.data?.mensagem);
                }
            });
    };

    const handleFormSubmit = async (event) => {
        await axiosApi({
            method: 'GET',
            url: "/semana-padrao/calendario-de-atividades",
            params: {
                curso_id: curso,
                periodo_letivo_id: periodoLetivo
            }
        }).then(resp => {
            setSemestresComTemas(resp.data.dados.semestresComTemas);
            setSemanasGeradas(resp.data.dados.semanasGeradas);
            setModal(false);
        }).catch(error => {
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }
        });
    }
    const Titulo = ({ periodoLetivo, curso }) => {
        return (
            <h1 className="m-0 font-weight-bold label-nome-pagina">
                {periodoLetivo?.descricao} - {curso?.nome}
                <Button onClick={toggleModal} className="float-right">
                    Editar
                </Button>
            </h1>
        );
    }

    return (
        <Fragment>
            <Titulo periodoLetivo={periodoLetivos?.find(el => el.id === periodoLetivo)} curso={cursos?.find(el => el.id === curso)} />
            <Card className="px-3 pt-5">
                <CardBody>
                    {
                        semestresComTemas
                        && semestresComTemas.map(semestreComTemas =>
                            <SemestreComTemas
                                key={semestreComTemas.id}
                                semestreComTemas={semestreComTemas}
                                periodoLetivo={periodoLetivo}
                                semanasGeradas={semanasGeradas}
                            />
                        )
                    }
                </CardBody>
            </Card>
            {/* Modal curso e periodo letivo */}
            {
                (cursos && periodoLetivos) && (
                    <ModalPeriodoCurso
                        isOpen={modal}
                        setIsOpen={setModal}
                        cursos={cursos}
                        periodosLetivos={periodoLetivos}
                        cursoId={curso}
                        setCursoId={setCursoId}
                        periodoLetivoId={periodoLetivo}
                        setPeriodoLetivoId={setPeriodoLetivoId}
                        submit={handleFormSubmit}
                    />
                )
            }
        </Fragment>
    )
}

const SemestreComTemas = ({ semestreComTemas, periodoLetivo, semanasGeradas }) => {
    return (
        semestreComTemas?.semestre_periodo_letivo.length > 0 ?
            semestreComTemas?.semestre_periodo_letivo.map((tema) => {
                return (
                    <div className="semestreComTemas d-flex align-items-center mb-5 flex-wrap">
                        <div className='semestre-turma'>
                            <div className="semestreComTemas__semestre-gerado d-flex justify-content-center align-items-center">
                                <span className="font-weight-bold" style={{ fontSize: "1.7em" }}>
                                    {semestreComTemas.descricao}
                                </span>
                            </div>
                            <span>
                                {tema?.turma?.descricao}
                            </span>
                        </div>
                        {tema?.semestreTema.map((temaa) => (
                            <Link className="semestreComTemasOfertas__temaLink" to={`/semana-padrao/calendario-de-atividades/tema/${temaa.tema.id}/periodo/${periodoLetivo}/turma/${tema?.turma?.id}`} key={temaa.tema.id}>
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="semestreComTemas__linha--gerado" />
                                    <div className="semestreComTemas__tema d-flex justify-content-center align-items-center px-2">
                                        <div className="font-weight-bold align-content-middle" style={{ fontSize: "1.2em", textAlign: "center" }}>
                                            {temaa.tema.nome}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                        }
                    </div>
                )
            }) :
            <div className="semestreComTemas d-flex align-items-center mb-5 flex-wrap">
                <div>
                    <div className="semestreComTemas__semestre-gerado d-flex justify-content-center align-items-center">
                        <span className="font-weight-bold" style={{ fontSize: "1.7em" }}>
                            {semestreComTemas.descricao}
                        </span>
                    </div>
                </div>
            </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        setCursoId: (tipo, acao) => dispatch(actionsRedux.setCurso(tipo, acao)),
        setPeriodoLetivoId: (tipo, acao) => dispatch(actionsRedux.setPeriodoLetivo(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        curso: state.auth.cursoId,
        periodoLetivo: state.auth.periodoLetivoId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardSemestresComTemas);