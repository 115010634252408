import React from 'react'
import { Col, Row, Button, Modal } from 'reactstrap';
import { HeaderModal } from "../Basicos/index"

const ModalAlert = ({ isOpen, setIsOpen, mensagem, titulo }) => {

    const toggleAlert = () => setIsOpen(!isOpen);

    return <Modal
                isOpen={isOpen}
                toggle={toggleAlert}
                size={'sm'}
                centered={true}
                className={"modal-map-body"}
                backdrop="static"
            >
                <Row noGutters={true}>
                    <HeaderModal sm={12} toggle={toggleAlert} titulo={titulo.toUpperCase()} />
                    <Col sm={12} style={{ fontSize: '0.9rem', padding: '8px', color: 'var(--cor-text-secundario)', textAlign: 'center' }}>
                        {mensagem}
                    </Col>
                    <Col sm={12} className='align-content-middle' style={{padding: '6px'}}>
                        <Button outline color="primary" onClick={toggleAlert} className={"float-left btnHeader btnHeader-danger m-0"}>
                            OK
                        </Button>
                    </Col>
                </Row>
            </Modal>
}
export default ModalAlert;