import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';


function ModalConsultaListaFrequencia(props) {
    const { estrutura } = props;
    const { alunos } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "Alunos",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Nome',
                    accessor: 'nome',
                },
                {
                    Header: 'Matrícula',
                    accessor: 'matricula',
                },
                {
                    Header: 'Grupo',
                    accessor: 'grupo_individual',
                }
            ],
        }],
        []
    )
    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <Tabela
                columns={columns}
                data={alunos}
                tabelaClassName="tabelaConsulta"
            />
        </ModalConsulta>
    );
}

export default ModalConsultaListaFrequencia;
