import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actionsRedux from '../../../Store/Actions/index';
import InputForm from '../../Form/InputForm';
import SelectForm from '../../Form/SelectForm';
import { axiosApi } from '../../axiosInstances';
import './style.css';
import NewLoader from '../../NewLoader';

const ModalGruposAlunoServico = ({
    isOpen,
    setIsOpen,
    ativar,
    setGruposAlunoServico,
    grupoAlunoId
}) => {
    const style = {
        col4: { xs: 6, md: 3, sm: 3, lg: 3, xl: 3 },
        col6: { xs: 6, md: 2, sm: 2, lg: 2, xl: 2 }
    };

    const [isLoading, setIsLoading] = useState(true);
    const [qtdCampos, setQtdCampos] = useState();
    const [gruposOptions, setGruposOptions] = useState([]);
    const [gruposOptionsDisponiveis, setGruposOptionsDisponiveis] = useState([]);
    const [semestresOptions, setSemestresOptions] = useState([]);
    const [turmasOptions, setTurmasOptions] = useState([]);
    const [valoresFiltro, setValoresFiltro] = useState({
        semestre_id: undefined,
        turma_id: undefined,
        qtd_alunos: 0
    });

    // Estado para armazenar os grupos de alunos
    const [gruposAlunos, setGruposAlunos] = useState([]);

    const toggle = () => {
        setIsLoading(false)
        setValoresFiltro({
            semestre_id: undefined,
            turma_id: undefined,
            qtd_alunos: 0
        });
        setGruposAlunos([]);
        setGruposOptions([]);
        setIsOpen(!isOpen);
    };

    const onChange = (nome, valor, indice, ultimoValor, action) => {
        switch (nome) {
            case 'semestre_id':
                setValoresFiltro({ ...valoresFiltro, semestre_id: valor });
                getTurmas(valor);
                break;
            case 'turma_id':
                setValoresFiltro({ ...valoresFiltro, turma_id: valor });
                getAlunos(valor);
                break;
            case 'qtd_alunos':
                if (valor == '') {
                    setValoresFiltro({ ...valoresFiltro, qtd_alunos: valor });
                    setQtdCampos(undefined);
                    setGruposAlunos([]);
                    setGruposOptionsDisponiveis(gruposOptions)
                } else {
                    setValoresFiltro({ ...valoresFiltro, qtd_alunos: valor });
                    setQtdCampos(Math.ceil(gruposOptions?.length / valor));
                    setGruposAlunos(new Array(Math.ceil(gruposOptions?.length / valor)).fill([]));
                    setGruposOptionsDisponiveis(gruposOptions)
                }
                break;
            case 'alunos':
                if (action == "clear") {
                    const filtrados = gruposOptions.filter(grupo => gruposAlunos[indice].includes(grupo.value));
                    setGruposOptionsDisponiveis(prevState => {
                        return [...prevState, ...filtrados];
                    });
                    setGruposAlunos(prevState =>
                        prevState.map((grupo, index) =>
                            index === indice ? [] : grupo
                        )
                    );
                } else {

                    const difference = gruposAlunos?.[indice]?.filter(item => !valor.includes(item))[0];
                    const grupoCorrespondente = gruposOptions.find(grupo => grupo.value === difference);

                    // Garante que `selectedAlunos` é uma array
                    const selectedAlunos = Array.isArray(valor) ? valor : [valor];

                    // Verifica se o limite de alunos foi atingido
                    if (selectedAlunos.length > valoresFiltro.qtd_alunos) {
                        ativar(400, "Limite de aluno por grupo atingido!");
                    } else {
                        // Caso `difference` seja `undefined` e `action` seja `remove-value`
                        if (difference === undefined && action === 'remove-value') {
                            // Limpa o grupo no índice especificado
                            setGruposAlunos(prevState =>
                                prevState.map((grupo, index) =>
                                    index === indice ? [] : grupo
                                )
                            );
                        } else {
                            // Adiciona `selectedAlunos` ao grupo no índice especificado
                            setGruposAlunos(prevState => {
                                // Garante que `prevState` é uma array e copia os grupos existentes
                                const newState = { ...prevState };

                                // Adiciona `selectedAlunos` no índice específico, sem modificar outros índices
                                newState[indice] = selectedAlunos;

                                return newState;
                            });
                        }

                        // Atualiza as opções disponíveis de acordo com a ação
                        setGruposOptionsDisponiveis(prevState => {
                            if (action === "remove-value" && grupoCorrespondente) {
                                // Adiciona a opção removida de volta ao array de opções disponíveis
                                return [...prevState, { value: grupoCorrespondente.value, label: grupoCorrespondente.label }];
                            } else {
                                // Remove `ultimoValor` das opções disponíveis
                                return prevState.filter(option => option.value !== ultimoValor);
                            }
                        });
                    }

                }
            default:
                break;
        }
    };

    const getTurmas = async (semestreId) => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/turmas-semestre?semestre_id=${semestreId}`);
            setTurmasOptions(data.map(element => ({ label: element.descricao, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    };

    const getAlunos = async (turmaId) => {
        try {
            const { data } = await axiosApi.get(`/auxiliares/grupos-semestre-turma?semestre_id=${valoresFiltro.semestre_id}&turma_id=${turmaId}`);
            setGruposOptions(data.map(element => ({ label: element.descricao, value: element.id })));
            setGruposOptionsDisponiveis(data.map(element => ({ label: element.descricao, value: element.id })));
        } catch (error) {
            console.log(error);
        }
    };

    const getFiltros = async () => {
        try {
            if (isOpen) {
                setIsLoading(true);
                const { data } = await axiosApi.get('/auxiliares/filtros-grupos-aluno');
                setSemestresOptions(data.semestres.map(element => ({ label: element.descricao, value: element.id })));

                if (grupoAlunoId != null) {
                    const { data } = await axiosApi.get(`/auxiliares/grupos-aluno/${grupoAlunoId}`);
                    setQtdCampos(data.qtd_campos);
                    setGruposAlunos(data.grupos_aluno);
                    setValoresFiltro({
                        semestre_id: data.dados.semestre_id,
                        turma_id: data.dados.turma_id,
                        qtd_alunos: data.dados.qtd_alunos,
                    });
                    setTurmasOptions(data.turmas.map(element => ({ label: element.descricao, value: element.id })));
                    setGruposOptions(data.grupos.map(element => ({ label: element.descricao, value: element.id })));
                    setGruposOptionsDisponiveis(data.grupos_disponiveis.map(element => ({ label: element.descricao, value: element.id })));
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const salvar = async () => {
        try {
            let infos = valoresFiltro;
            infos.itens = gruposAlunos;
            if (grupoAlunoId != null) {
                const response = await axiosApi.put(`/auxiliares/grupos-aluno/${grupoAlunoId}`, infos);
                ativar(200, "Grupos editado com sucesso!");
                setGruposAlunoServico(prevGrupos =>
                    prevGrupos.map(grupo =>
                        grupo.id === response.data.id ? response.data : grupo
                    )
                );
            } else {
                const response = await axiosApi.post('/auxiliares/grupos-aluno', infos);
                ativar(200, "Grupos adicionado com sucesso!");
                setGruposAlunoServico(prevGrupos => [...prevGrupos, response.data]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            toggle();
        }
    }

    useEffect(() => {
        getFiltros();
    }, [isOpen]);

    return (
        <NewLoader isActive={isLoading} overlay >
            <Modal isOpen={isOpen} toggle={toggle} size='xl'>
                <ModalHeader>
                    <h4>
                        <b>Grupos de Aluno Serviço</b>
                    </h4>
                </ModalHeader>
                <ModalBody>
                    <Row className="modal-grupos-aluno">
                        <SelectForm
                            label="Semestre"
                            name="semestre_id"
                            placeholder="Selecione"
                            options={semestresOptions}
                            value={semestresOptions?.find(option => option.value === valoresFiltro.semestre_id) || ''}
                            onChange={onChange}
                            style={{ col: style.col3 }}
                        />
                        <SelectForm
                            label="Turma"
                            name="turma_id"
                            placeholder="Selecione"
                            options={turmasOptions}
                            value={turmasOptions?.find(option => option.value === valoresFiltro.turma_id) || ''}
                            onChange={onChange}
                            style={{ col: style.col3 }}
                        />
                        <InputForm
                            type="number"
                            name="qtd_alunos"
                            label="Quantidade de Alunos"
                            value={valoresFiltro.qtd_alunos}
                            onChange={onChange}
                            style={{ col: style.col3 }}
                        />
                    </Row>
                    <Row className={valoresFiltro?.qtd_alunos !== 0 ? 'exibir' : 'nao-exibir'}>
                        {Array.from({ length: qtdCampos }, (_, index) => (
                            <>
                                <SelectForm
                                    key={index}
                                    isMulti
                                    indice={index}
                                    label={`Grupo ${index + 1}`}
                                    name="alunos"
                                    placeholder="Selecione"
                                    options={gruposOptionsDisponiveis}
                                    value={gruposOptions?.filter(option => gruposAlunos?.[index]?.includes(option.value)) || ''}
                                    onChange={onChange} // Passa o índice e o valor selecionado
                                />
                                <h1 className='numeracao-alunos-grupo'>{`${gruposAlunos?.[index]?.length ?? 0}/${valoresFiltro?.qtd_alunos}`}</h1>
                            </>
                        ))}
                    </Row>
                    <Row className={valoresFiltro?.qtd_alunos !== 0 ? 'exibir' : 'nao-exibir'}>
                        <Col className="align-content-middle">
                            <Button className="mx-3" onClick={() => toggle()}>Cancelar</Button>
                            <Button className="mx-3" onClick={() => salvar()}>Salvar</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </NewLoader>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    };
};

export default connect(null, mapDispatchToProps)(ModalGruposAlunoServico);
