import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap';
import { InputForm, SelectForm, Tabela } from '../Basicos';
import { axiosApi } from '../Basicos/axiosInstances';
import { montarColunas } from '../Basicos/funcoes';
import ModalCursoPeriodo from '../Modal/ModalCursoPeriodo';
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { ativarModalConfirm } from '../Store/Actions/index'

const SubgrupoAlunos = ({
    ativar,
    ativarCofirmacao,
    buscarValoresPaginacao,
    subFuncionalidade: {
        async,
        campos,
        acoes
    }, }) => {

    const [selectPeriodo, setSelectPeriodo] = useState([])
    const [selectCursos, setSelectCursos] = useState([])
    const [modalPeriodo, setModalPeriodo] = useState(true);
    const [selectSubGrupo, setSelectSubGrupo] = useState([])
    const [dadosTabela, setDadosTabela] = useState([])
    const [dados, setDados] = useState({
        quantidade: undefined,
        subgrupo_ids: [],

    })

    const resetForm = () => setDados({
        ...dados,
        id: undefined,
        quantidade: undefined,
        subgrupo_ids: []
    })

    const getPeriodoECurso = async () => {
        try {
            const { data } = await axiosApi.get('/alunos/subgrupo-de-alunos');
            const { periodos_letivo, cursos } = data;
            setSelectPeriodo(periodos_letivo.map(
                option => ({ label: option.descricao, value: option.id })));
            setSelectCursos(cursos.map(
                option => ({ label: option.nome, value: option.id })))
        } catch (error) {
            ativar(400, error.response.data.error)
            console.log(error);
        }
    }

    const getSubgruposCurso = async (curso_id, periodo_letivo_id) => {
        try {
            const { data } = await axiosApi.get(`/alunos/subgrupo-de-alunos?curso_id=${curso_id}&periodo_letivo_id=${periodo_letivo_id}`);
            const { subgrupos, subgrupoalunos } = data
            setDados({
                ...dados,
                curso_id: curso_id,
                periodo_letivo_id: periodo_letivo_id
            })
            setSelectSubGrupo(subgrupos.map(
                option => ({ value: option.id, label: option.descricao })))
            setDadosTabela(subgrupoalunos)
        } catch (error) {
            ativar(400, error.response.data.error)
            console.log(error)
        }
    }

    const selectOnChange = (name, values) => {
        setDados({
            ...dados,
            [name]: values
        })
    }

    const onSubmit = async () => {
        try {
            const { data } = dados.id === undefined ?
                await axiosApi.post(`/alunos/subgrupo-de-alunos`, dados) :
                await axiosApi.put(`/alunos/subgrupo-de-alunos/${dados.id}`, dados)
            const { subgrupoalunos } = data
            setDadosTabela(subgrupoalunos)
            resetForm();
            ativar(201, data.sucesso)
        } catch (error) {
            ativar(400, error.response.data.error)
            console.log(error)
        }
    }

    const confirmarSubmit = () => {
        if (dados.subgrupo_ids.length < dados.quantidade) {
            ativarCofirmacao({ titulo: 'Confirmar', mensagem: 'Subgrupo menor que quantidade.', callback: onSubmit })
        } else {
            onSubmit()
        }
    }

    const alterarOnClick = (id) => {
        const elemento = dadosTabela.find((element) => element.id === id);
        const subgrupos = elemento.subgrupo_ids.split(',').map(el => Number(el))
        setDados({
            ...elemento,
            quantidade: elemento.quantidade,
            subgrupo_ids: subgrupos
        })
    }

    const removerOnClick = async (id) => {
        try {
            const response = await axiosApi.delete(`/alunos/subgrupo-de-alunos/${id}`)
            const elemento = dadosTabela.filter((element) => element.id !== id);
            setDadosTabela(elemento)
            resetForm();
            ativar(201, response.data.sucesso)
        } catch (error) {
            ativar(400, error.response.data.error.error)
            console.log(error)
        }
    }

    useEffect(() => {
        getPeriodoECurso();
    }, []);

    const colunas = React.useMemo(() => montarColunas(campos, acoes, removerOnClick, alterarOnClick), [dadosTabela]);

    const col = { md: 12, sm: 12 }

    return (
        <Fragment>
            <Col className='card'>
                <Row className='h-100 undefined card-body'>
                    <Col xs={12} sm={12} md={5} lg={6} xl={7} className="d-flex mb-3 header-legenda text-header">
                        <h3><strong> SUBGRUPOS </strong></h3>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={6} xl={5} className="header-acoes mb-3">
                        <div id="pesquisar-wrap"></div>
                    </Col>
                    <ModalCursoPeriodo
                        dados={dados}
                        setDados={setDados}
                        isOpen={modalPeriodo}
                        setIsOpen={setModalPeriodo}
                        selectCursos={selectCursos}
                        selectPeriodo={selectPeriodo}
                        getSubgruposCurso={getSubgruposCurso}
                    />
                    <Col md={3} sm={3}>
                        <Row>
                            <InputForm
                                label='Quantidade'
                                type='text'
                                name='quantidade'
                                onChange={selectOnChange}
                                style={{ col }}
                                value={dados.quantidade ?? ''}
                            />
                            <SelectForm
                                maxOptions={Number(dados.quantidade)}
                                isMulti={true}
                                name="subgrupo_ids"
                                label="SubGrupo"
                                options={selectSubGrupo}
                                onChange={selectOnChange}
                                style={{ col }}
                                value={selectSubGrupo.filter(option => dados.subgrupo_ids?.includes(option.value))}
                            />
                            <Col className="align-content-middle">
                                <Button className="mx-3" onClick={resetForm}>Cancelar</Button>
                                <Button className="mx-3" onClick={confirmarSubmit}>Salvar</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='offset-md-2' md={6} sm={6}>
                        <Tabela
                            pesquisarGlobal
                            columns={colunas}
                            data={dadosTabela}
                            buscarValoresPaginacao={buscarValoresPaginacao}
                            async={async}
                        />
                    </Col>
                </Row>
            </Col>
        </Fragment >
    )

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(ativarModalConfirm(obj))
    }
}

export default connect(null, mapDispatchToProps)(SubgrupoAlunos)