import React from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { convertToSlug } from '../Basicos/funcoes';
import OfertaPorPeriodoLetivo from '../Conteudo/OfertaPorPeriodoLetivo';
import CalendarioDeAtividades from '../Conteudo/CalendarioDeAtividades';
import DragNDropSequenciaAula from '../Conteudo/Cards/DragNDropSequenciaAula';

function SemanaPadrao({ ofertasPeriodoLetivo, }) {
    return (
        <Row noGutters={true}>

            <Switch>
                {ofertasPeriodoLetivo.map((subFuncionalidade, indice) => {
                    switch (convertToSlug(subFuncionalidade.descricao)) {
                        case "calendario-de-atividades":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <CalendarioDeAtividades
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"semana-padrao"}
                                />
                                }
                            />
                        case "cadastro-de-ofertas":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <OfertaPorPeriodoLetivo
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"semana-padrao"}
                                />
                                }
                            />;
                        case "sequencia-da-aula":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <Col sm={12} md={12} className="col-conteudo sequencia-aula"><DragNDropSequenciaAula
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"semana-padrao"}
                                /></Col>
                                }
                            />;
                        default:
                            return (
                                null
                            );
                    }
                })}
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    return {
        ofertasPeriodoLetivo: state.subFuncionalidades['semana-padrao'],
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanaPadrao)