import React, { useState } from 'react'
import { Row, Col, Button, Label } from "reactstrap";
import CardHoc from "../../Hoc/cardHoc";
import { transformaDataEmString } from '../../../services/calendarioOferta';
import ModalDetalhesCargaHoraria from '../../Basicos/Modal/DetalhesCargaHoraria';

function CardInfoCalendarioOfertas({ tema, periodoLetivo }) {
    //Trabalho em Andamento
    const [isOpen, setIsOpen] = useState(false);
    const [tipoModal, setTipoModal] = useState();
    return (
        <Col>
            <div
                className="align-content-middle mb-3"
                style={{ margin: "0rem 1rem", borderBottom: "1px solid var(--cor-border-secundario)", padding: ".7rem" }}
            >
                <h6>
                    <strong>Informações do Calendário</strong>
                </h6>
            </div>
            <div style={{ margin: "0rem 1rem" }} className="info-calendario-padrao">
                <fieldset>
                    <legend>Tema:</legend>
                    <p>{tema ? tema.nome : "Não disponível"}</p>
                </fieldset>
                <fieldset>
                    <legend>Data Início:</legend>
                    <p>
                        {
                            periodoLetivo.inicio
                                ? transformaDataEmString(new Date(periodoLetivo.inicio.split('-')), "dd/MM/yy")
                                : "Não disponível"
                        }
                    </p>
                </fieldset>
                <fieldset>
                    <legend>Data Fim:</legend>
                    <p>
                        {
                            periodoLetivo.fim
                                ? transformaDataEmString(new Date(periodoLetivo.fim.split('-')), "dd/MM/yy")
                                : "Não disponível"
                        }
                    </p>
                </fieldset>
            </div>
            <Label>Detalhes Carga Horária:</Label>
            <Row>
                <Col className="align-content-middle">
                    <Button onClick={() => (setIsOpen(true), setTipoModal('Modulos'))} block>Módulos</Button>
                </Col>
                <Col className="align-content-middle">
                    <Button onClick={() => (setIsOpen(true), setTipoModal('Temas'))} block>Temas</Button>
                </Col>
            </Row>
            <ModalDetalhesCargaHoraria
                id={tema.id}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                tipoModal={tipoModal}
                periodoletivo_id={periodoLetivo.id}
            />
        </Col>
    );
}

export default CardHoc(CardInfoCalendarioOfertas);