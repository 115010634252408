import React from 'react'
import Loadable from 'react-loadable';
import { Loader } from '../Basicos';
import {
    ModalConsultaProfessorAnalitico,
    ModalConsultaProfessorSintetico,
    ModalConsultaHorarioSemanaPadrao,
    ModalConsultaHorariosOfertas,
    ModalAlunoGrupo,
    ModalConsultaListaFrequencia,
    ModalAulasCanceladas,
    ModalConsultaRelatorioGrupo,
    ModalConsultaAtividadesPrevistaGrupo,
    ModalConsultaRelatorioRodizioLocal,
    ModalConsultaCargaHoraria,
    ModalConsultaAlteracoesSemanaPadrao,
    ModalConsultaListaRodizioAnalitico
} from '.';
import ModalConsultaGruposRodizio from './ModalConsultaGruposRodizio';


const ModalForm = Loadable({
    loader: () => import("./ModalForm"),
    loading: () => <Loader overlay fixed />
});
const ModalView = Loadable({
    loader: () => import("./ModalView"),
    loading: () => <Loader overlay fixed />
});



const ChaveamentoDeModal = (props) => {
    const { estrutura } = props;

    const tipoModal = estrutura?.modal?.tipo;

    switch (tipoModal) {
        case "modalForm":
            return (
                <ModalForm
                    {...props}
                    size={estrutura.modal.size}
                    data={estrutura}
                    modalClass={estrutura.modal.className}
                    modalBodyClass={estrutura.modal.modalBodyClass}
                />
            );
        case "modalView":
            return (
                <ModalView
                    {...props}
                    size={estrutura.modal.size}
                    data={estrutura}
                    modalClass={estrutura.modal.className}
                    modalBodyClass={estrutura.modal.modalBodyClass}
                />
            );
        case "modalConsultaProfessorAnalitico":
            return <ModalConsultaProfessorAnalitico data={estrutura} {...props} />
        case "modalConsultaProfessorSintetico":
            return <ModalConsultaProfessorSintetico data={estrutura} {...props} />
        case "modalConsultaHorariosOfertas":
            return <ModalConsultaHorariosOfertas data={estrutura} {...props} />;
        case "modalConsultaHorarioSemanaPadrao":
            return <ModalConsultaHorarioSemanaPadrao data={estrutura} {...props} />;
        case "ModalAlunoGrupo":
            return <ModalAlunoGrupo data={estrutura} {...props} />;
        case "modalConsultaListaFrequencia":
            return <ModalConsultaListaFrequencia data={estrutura} {...props} />
        case "modalConsultaRelatorioGrupo":
            return <ModalConsultaRelatorioGrupo data={estrutura} {...props} />
        case "modalAulasCanceladas":
            return <ModalAulasCanceladas data={estrutura} {...props} />
        case "modalConsultaAtividadesPrevistaGrupo":
            return <ModalConsultaAtividadesPrevistaGrupo data={estrutura} {...props} />
        case "modalConsultaRelatorioRodizioLocal":
            return <ModalConsultaRelatorioRodizioLocal data={estrutura} {...props} />
        case "modalConsultaCargaHoraria":
            return <ModalConsultaCargaHoraria data={estrutura} {...props} />
        case "modalConsultaAlteracoesSemanaPadrao":
            return <ModalConsultaAlteracoesSemanaPadrao data={estrutura} {...props} />;
        case "modalConsultaListaRodizioAnalitico":
            return <ModalConsultaListaRodizioAnalitico data={estrutura} {...props} />;
        case "modalConsultaGruposRodizio":
            return <ModalConsultaGruposRodizio data={estrutura} {...props} />;

        default:
            return null;
    }
}

export default ChaveamentoDeModal;
