import React from 'react'
import { Alert } from 'reactstrap'
import { connect } from 'react-redux'
import * as actionsRedux from './../Store/Actions/index'

const AlertCRUD = (props) => {
    return (
        <Alert color={props.cor} isOpen={props.boolAlert} toggle={props.desativar}>
            {
                Array.isArray(props.mensagem) 
                ?   props.mensagem[0]
                :   props.mensagem
            }
        </Alert>
    )
}

const mapStateToProps = (state) => {
    return {
        mensagem: state.alert.mensagem,
        cor: state.alert.cor,
        boolAlert: state.alert.boolAlert
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: () => dispatch(actionsRedux.ativarAlert()),
        desativar: () => dispatch(actionsRedux.desativarAlert())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AlertCRUD)
