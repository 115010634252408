import React, { PureComponent, Fragment } from 'react'
import { SelectForm, Estruturas } from "../index"
import { Col, Button } from "reactstrap"

export default class DependenteForm extends PureComponent {
    constructor(props) {
        super(props)
        this.adicionarCampo = false;
        this.inicializacao = (this.props.subordinados.valoresPadroes) ? true : false
        this.estruturas = (this.props.subordinados.valoresPadroes !== undefined) ? this.props.subordinados.valoresPadroes : [{}] 
    
        this.state = {
            dependente: this.props.select.valorPadrao === undefined ? undefined : this.props.select.valorPadrao.value,
            numeroDeElementos: this.estruturas.length
        }
    }
  
    gerarSubordinados = () => {
        return this.props.select.options.map((elemento, indice) => {
            if (elemento.value === this.state.dependente && this.props.subordinados[elemento.value]) {
                if (this.props.subordinados[elemento.value].adicionar){
                    this.adicionarCampo = this.props.subordinados[elemento.value].adicionar;
                    return this.gerarEstruturaAdicional();
                }else{
                    return  <Estruturas
                                estrutura={this.props.subordinados[elemento.value].estrutura}
                                submit={this.props.submit}
                                onChange={this.onChangeEstrutura}
                                key={indice + "forms"}
                            />
                    }
            } else {
                return null;
            }
        });
    }
    // Unico que usa é o opcaoItemChecklist porém deve sair e ir para o back-end
    preencherEstruturaValoresPadrao = (indiceEstruturas) => {
        if (this.inicializacao) {
            let estruturaNova = this.props.subordinados[this.state.dependente].estrutura.map((elemento) => {
                elemento = Object.assign({}, elemento);
                let valorPadrao = this.estruturas[indiceEstruturas] !== undefined ? this.estruturas[indiceEstruturas][elemento.name] : undefined;
                if (elemento.type === 'select' && valorPadrao !== 'object') {
                    elemento.options.forEach(opcao => {
                        if (opcao.value === valorPadrao)
                            elemento.valorPadrao = opcao
                    })
                } else {
                    elemento.valorPadrao = valorPadrao;
                }
                return elemento;
            })
            this.inicializacao = (this.estruturas.length-1 !== indiceEstruturas) ? true : false
            return estruturaNova;
        } else {
            return this.props.subordinados[this.state.dependente].estrutura
        }
    }
    
    gerarEstruturaAdicional = () => {
        let elementos = [];
        for (let indice = 0; indice < this.state.numeroDeElementos; indice++) {
            elementos.push( <Estruturas estrutura={this.preencherEstruturaValoresPadrao(indice)}
                                    tipo={"forms"}
                                    submit={this.props.submit}
                                    onChange={(formulario, incompleto, formularioIncompleto) => this.onChangeEstrutura(formulario, incompleto, formularioIncompleto, indice)}
                                    key={indice + "forms"}
                            />
            );
        }
        return elementos;
    }

    mudarSubordinados = (name, valor) => {
        this.estruturas = {}
        this.adicionarCampo = false
        this.setState({ dependente: valor, numeroDeElementos: 1 })
        this.props.onChange(this.props.name, { [this.props.select.name]: valor})
    }

    adicionarEstrutura = () => {
        this.setState((prevState) => ({
            numeroDeElementos: prevState.numeroDeElementos + 1
        })); 
    }
    onChangeEstrutura = (formulario, incompleto, formularioIncompleto, indice = undefined) => {
        if (formularioIncompleto) {
            this.props.onChange(this.props.name, {})
        } else if (indice !== undefined) {
            this.estruturas[indice] = { ...formulario }
            this.props.onChange(this.props.name, { estruturas: this.estruturas, [this.props.select.name]: this.state.dependente })
        } else {
            this.props.onChange(this.props.name, { estruturas: { ...formulario }, [this.props.select.name]: this.state.dependente })
        }
    }

    render() {
        return (
            <Fragment>
                <SelectForm {...this.props.select} onChange={this.mudarSubordinados} submit={this.props.submit} invalido={this.props.invalido}/>
                {(this.adicionarCampo)  ?   <Col className="form-col">
                                                <Button onClick={this.adicionarEstrutura} className = "float-right"> 
                                                    Adicionar Item 
                                                </Button>
                                            </Col> : null}

                {this.gerarSubordinados()}
            </Fragment>
        )
    }
}
