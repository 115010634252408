import React, { useState, Fragment, useEffect, useCallback, useRef  } from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Button } from "reactstrap";
import { Estruturas, Loader } from "../../Basicos";
import CardHoc from "../../Hoc/cardHoc";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';

function CardConsulta(props) {
    const requisicao = useRef(false);
    const formulario = useRef(null);
    const [submit, setSubmit] = useState(false);
    const atualizarFiltroRemotamente = useCallback(props.atualizarFiltroRemotamente, []);

    useEffect(() => {
        if(!requisicao.current && props.collapse){
            atualizarFiltroRemotamente();
            requisicao.current = true
        }
    }, [props.collapse, atualizarFiltroRemotamente]);

    
    const consultar = () => { 
        if(!formulario.current.state.incompleto){    
           let params =  Object.assign({},formulario.current.formulario)
           props.gerarConsulta(params);
        }
        setSubmit(true)
    } 
    
    return (
        <Col xs={12} sm={12} >
            {props.estrutura ? (
                <Fragment>
                    <Label xs={12} sm={12}><b>{props.titulo}</b></Label>
                    <Estruturas 
                        estrutura={props.estrutura}
                        tipo={"forms"}
                        ref={formulario}
                        submit={submit}
                    /> 
                    <Button 
                        className="float-right margin-btnConsulta"
                        onClick={consultar}
                        size="sm"
                    > 
                        {props.nomeButton}
                    </Button>
                </Fragment> 
            ) : ( 
                <Loader overlay={props.loader} padding />
            )}
        </Col>
    )
}

CardConsulta.propTypes = {
    titulo: PropTypes.string,
    className: PropTypes.string.isRequired,
    nomeButton: PropTypes.string.isRequired,
    estrutura: PropTypes.array
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const consulta = ownProps.funcionalidade !== 'exportador' ? (params) => dispatch(actionsRedux.ativarModalSubFuncionalidade(ownProps.funcionalidade, ownProps.indice, 'relatorio', null, params)) : (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params)); 
    return {
        gerarConsulta: consulta,
        atualizarFiltroRemotamente: () => dispatch(actionsRedux.buscarFiltrosSubFuncionalidade(ownProps.funcionalidade, ownProps.indice)),
        exportar: (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params)),
   
    }
}

const mapStateToProps = (state, ownProps) => {
    const subFuncionalidade = state.subFuncionalidades[ownProps.funcionalidade][ownProps.indice];
    return {
        tituloHeader:subFuncionalidade.descricao,
        estrutura: subFuncionalidade.estrutura,
    }
}

var consulta = CardHoc(React.memo(CardConsulta))
export default connect(mapStateToProps, mapDispatchToProps)(consulta)
