import React from 'react';
import { Col, Row, Modal, ModalBody, Form, Button, FormGroup } from 'reactstrap';
import { SelectForm, InputForm } from '../Basicos';


export default function ModalCalendario({
    modalAberto, setModalAberto, semestres, curso, handleSemestre,
    agregdorSemestre, setDescricao, descricao, addicionarCalendario,setAgregdorSemestre
}) {
    const semestresOptions = () => (
        semestres
            .filter(semestre => semestre.curso_id === curso)
            .map(semestre => ({ value: semestre.id, label: semestre.descricao }))
    )

    const SemestreValue = () => (

        agregdorSemestre !== undefined && agregdorSemestre.length >= 0
            ?
            agregdorSemestre?.map(agg => (
                semestresOptions().find(elemento => elemento.value === agg)
            ))
            :
            semestresOptions().find(elemento => elemento.value === semestres.id)
    )

    return (
        <Modal isOpen={modalAberto}>
            <ModalBody>
                <Row className="menu-modal-formal">
                    <Col sm={12} className="align-content-middle mb-3 mt-2">
                        <h5><b className="titulo-calendario-modal">Novo Calendário Acadêmico</b></h5>
                    </Col>
                </Row>
                <Form row>

                    <InputForm
                        type="text"
                        name="descricao"
                        label="Descrição"
                        id="descricao"
                        value={descricao}
                        onChange={(index, value) => setDescricao(value)}
                        isRequired={true}
                    />
                    <SelectForm
                        name="semestes"
                        label="Semestre"
                        value={SemestreValue()}
                        options={semestresOptions()}
                        onChange={(input, value) => handleSemestre(value)}
                        isMulti={true}
                        isRequired={true}
                    />
                </Form>
                <Col sm={12} className="align-content-middle mb-3 p-0">
                    <Button
                        onClick={() => setModalAberto(false, setDescricao(null), setAgregdorSemestre({}))}
                        className="float-right btn-transparente"
                    >
                        Cancelar
                    </Button>
                    <Button onClick={() => addicionarCalendario()} className="float-right">Salvar</Button>
                </Col>
            </ModalBody>
        </Modal>
    );
}
