import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';

function ModalConsultaProfessorAnalitico(props) {
    const {estrutura} = props;
    const {professores} = estrutura?.modal;
    
    const columns = React.useMemo(
        () => [{
            Header: "Professores",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Nome',
                    accessor: 'nome',
                },
                {
                    Header: 'Matrícula',
                    accessor: 'matricula',
                },
                {
                    Header: 'CPF',
                    accessor: 'cpf',
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                },
                {
                    Header: 'Telefone',
                    accessor: 'telefone',
                },
                {
                    Header: 'WhatsApp',
                    accessor: 'celular',
                },
                {
                    Header: 'Titulação',
                    accessor: 'titulacao.nome',
                },
                {
                    Header: 'Especialidade',
                    accessor: 'especialidade.nome',
                },
                {
                    Header: 'Áreas de Atuação',
                    accessor: 'areasDeAtuacao',
                },
                {
                    Header: 'Preceptor',
                    accessor: 'temPreceptor',
                }
            ],
        }],
        []
      )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <Tabela
                columns={columns} 
                data={professores} 
                tabelaClassName="tabelaGrupo"
            />
        </ModalConsulta>
    );
}

export default ModalConsultaProfessorAnalitico;
