import React from 'react'
import { Scrollbars } from "react-custom-scrollbars";
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'
import { List } from 'react-virtualized/dist/commonjs/List'
import { CellMeasurer } from 'react-virtualized/dist/commonjs/CellMeasurer'
import ItemGenerico from '../Itens/itemGenerico';

const ListaPrimaria = ({listaPrimaria, onResize, scrollRef, onScroll, cache, rowRenderer, listaRef, onClick, definirOnclick, exportar}) => {
    const handleScroll = ({ target }) => {
        const { scrollTop, scrollLeft } = target;
        const { Grid: grid } = listaRef.current;
        grid.handleScrollEvent({ scrollTop, scrollLeft });
    }
   
    const rowRendererDefault = ({ key, index, parent, style }) => {
        return  <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                    <div key={key} style={style} onClick={onClick}>
                        <ItemGenerico   
                            dados={listaPrimaria.dados[index]}
                            className="border-bottom-lista texto-secundario item-lista-exibicao"
                            campos={listaPrimaria.campos}
                            definirOnclick={definirOnclick}
                            exportar={exportar}
                        />
                    </div>
                </CellMeasurer>
    }

    const onResizeDefault = () => {
        if (listaRef.current) {
            cache.clearAll();
            listaRef.current.forceUpdateGrid();
        }
    }

    return (
        <AutoSizer onResize={onResize ? onResize : onResizeDefault}>
            {({ height, width }) => (
                <Scrollbars
                    ref={scrollRef}
                    style={{ width: width, height: height }}
                    onScroll={onScroll ? onScroll : handleScroll}
                    renderThumbVertical={props => <div {...props} className="marcacao-scroll-vertical" />}
                >
                    <List
                        width={width}
                        height={height}
                        rowCount={listaPrimaria.dados.length}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        rowRenderer={rowRenderer ? rowRenderer : rowRendererDefault}
                        overscanRowCount={5}
                        style={listStyle}
                        ref={listaRef}
                    />
                </Scrollbars>
            )}
        </AutoSizer>
    )
}

const listStyle = {
    overflowY: 'visible',
    overflowX: 'visible',
};

export default ListaPrimaria;