
import React from 'react'
import { Row } from 'reactstrap';
import CampoGenerico from "../Campos/campoGenerico"
import CampoExportar from "../Campos/campoExportar"
import CampoHeader from '../Campos/campoHeader';

const ItemGenerico = ({ dados, campos, definirOnclick, exportar, className, style, onClick, acoes, onClicksHeader }) => {
    return dados && (
        <Row noGutters className={className} style={style} onClick={onClick}>
            <CampoHeader {...acoes} dados={dados} {...onClicksHeader} />
            <CampoExportar dados={dados} definirOnclick={definirOnclick} {...exportar} />
            {campos.map((elemento, indice) => {
                return <CampoGenerico key={indice} dados={dados} definirOnclick={definirOnclick} {...elemento} />
            })}
        </Row>
    )
}

export default ItemGenerico
