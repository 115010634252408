import React, { useState } from 'react'
import { Button} from 'reactstrap'
import { IconInput,  } from '../index'
import { Link } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import './style.css'

const LoginForm = (props) => {
    const [verSenha, setVerSenha] = useState(false);

    const onClick = () => {
        setVerSenha(!verSenha)
    }

    return (
        <div className="div-form-login" >
            <IconInput
                tipo={"usuario"}
                tipoPattern={true}
                placeholder="Usuário"
                onChange={props.onChange}
                invalido={props.invalido[0]}
                defaultValue={props.formulario.usuario}
                submit={props.submit}
            />
            
            {
                verSenha ? <AiOutlineEye onClick={onClick} className='ver-senha'/> : <AiOutlineEyeInvisible onClick={onClick} className='ver-senha'/>
            }
            <IconInput
                tipo={`${verSenha ? 'senhaVisivel' : 'senha'}`}
                onChange={props.onChange}
                invalido={props.invalido[1]}
                submit={props.submit}
                name="senha"
                placeholder="Senha"
            />
            
            <Button className="" onClick={props.submitOnClick}>
                Entrar
            </Button>
            <Link to='/login/recuperar-senha' className='color-verde'>Esqueceu sua senha ?</Link>
        </div>
    )
}

export default LoginForm
